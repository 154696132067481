import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SpeedUpModal } from "./speedUpModal";
import "../styles.sass";
import { FaTimes, FaPlus } from "react-icons/fa";
import { CreateSpeedUp } from "./createSpeedUp";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";

export class SpeedUp extends Component {
  limit = 6;
  endOfResults = false;
  constructor(props) {
    super(props);
    const courseId = this.props.match.params.id;
    this.state = {
      isSpeedUpOpen: false,
      page : 1,
      isCreateSpeedUpOpen: false,
      files: [],
      selectedFileId: null,
      modal: false,
      fileId: null,
      hideBtn: false,
      loading: false,
      disabled: false,
      isPageLoading: false,
      totalItems : 0
    };
    this.toggle = this.toggle.bind(this);
    this.onCreate = this.onCreate.bind(this)
  }

  openSpeedUpModal(id) {
    this.setState({ isSpeedUpOpen: true, selectedFileId: id });
  }
  closeSpeedUpModal = () => {
    this.setState({ isSpeedUpOpen: false });
  };

  openCreateSpeedUpModal = () => {
    this.setState({ isCreateSpeedUpOpen: true });
  };
  closeCreateSpeedUpModal = () => {
    this.setState({ isCreateSpeedUpOpen: false });
  };

  toggle(id) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      fileId: id
    }));
  }

  onCreate(){
    this.setState({page: 1,files : []},()=>{
      this.loadMore({initRequest: true})
    })
  }

  async componentDidMount() {
    await this.loadMore();
  }

  loadMore = async (config) => {
    this.setState({ ...this.state, isPageLoading: true });
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    // this.setState({ loading: true, disabled: true });
    const url =  `${apiBaseUrl}/content/${courseId}/speedups?Page=${this.state.page}&Limit=${this.limit}`
      axios
        .get(url, { headers })
        .then(response => {
          this.setState({ loading: false, disabled: false, isPageLoading: false });
          const newSpeedups = [
            ...this.state.files,
            ...response.data.data.items
          ];
          this.setState({
            files: newSpeedups,
            page : this.state.page + 1,
            totalItems : response.data.data.totalItems
          });
          if (newSpeedups.length == response.data.data.totalItems) {
            this.setState({ hideBtn: true });
          }
        })
        .catch(error => {
          this.setState({ isPageLoading: false });
          this.setState({ loading: false, disabled: false,page : this.state.page + 1 });
        });
  };

  deleteFile(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .delete(`${apiBaseUrl}/content/speedups/${id}`, {
        headers
      })
      .then(response => {
        this.toggle()
       this.onCreate()
      })
      
      .catch(error => {
        console.log(error);
      });
  }

  renderFiles() {
    const files = this.state.files || [];
    return files.map(file => (
      <React.Fragment>
        <div className="col-md-4">
          {/* <button
            type="button"
            className="btn btn-sm d-flex align-items-center justify-content-center remove-btn text-white red-bg"
            onClick={() => this.toggle(file.id)}
          >
            <FaTimes />
          </button> */}

          <div
            className="card card-sm custom-height shadow-sm border-0 clickable"
            onClick={() => this.props.history.push(`/courses/${this.props.match.params.id}/speed-up/${file.id}`)}
            // onClick={() => this.openSpeedUpModal(file.id)}
          >
            <header className="card-thumb d-flex align-items-center justify-content-center">
              {file.type == "Image" ? (
                <img src={file.url} alt="File" className="cover-img mx-auto" />
              ) : file.type == "Pdf" ? (
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/pdf.png"}
                  alt="File"
                  className="contain-img custom-img mx-auto"
                />
              ) : file.type == "Video" ? (
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/video.png"}
                  alt="File"
                  className="contain-img custom-img mx-auto"
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/file-outline.png"
                  }
                  alt="File"
                  className="contain-img custom-img mx-auto"
                />
              )}
            </header>
            <div className="card-body d-flex justify-content-start align-items-center">
              <h6 className="card-title small mb-0 p-0 dark-text">
                {file.title}
              </h6>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  }

  render() {
    return (
      <>
      {this.state.isPageLoading ? (
        <div
        className="silver-bg box-layout w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
        style={{ minHeight: 350 }}
      >
        <Loader type="ball-spin-fade-loader" className="dark-loader" />
      </div>
      ) : (
      <React.Fragment>
        <div className="row no-gutters">
          <div className="col-12 mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="dark-text small mb-0 mt-0">مرفقات الدورة</h6>
              <button
                type="button"
                className="btn light-btn btn-sm d-flex align-items-center justify-content-center pl-3 pr-3"
                onClick={this.openCreateSpeedUpModal}
              >
                <FaPlus className="mr-2" size={10} />
                إضافة
              </button>
            </div>
          </div>
          <div className="col-12">
            {this.state.files == undefined || this.state.files.length == 0 ? (
              <React.Fragment>
                <div
                  className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center"
                  style={{ height: 300 }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/empty-files.png"
                    }
                    height="100"
                    className="contain-img mb-3"
                  />
                  <p className="dark-text mt-0 mb-0">لا يوجد لديك ملفات </p>
                </div>
              </React.Fragment>
            ) : (
              <div className="box-layout shadow-sm d-flex flex-column w-100 rounded pt-4 pl-4 pr-4">
                <div className="row">{this.renderFiles()}</div>
                <div className="row">
                  <div className="col-12">
                    {!(this.state.files.length >= this.state.totalItems) && (
                      <div className="d-flex align-items-center justify-content-center mb-4">
                        <button
                          className="btn dark-btn unset-height unset-line-height br-5 w-25"
                          onClick={this.loadMore}
                          disabled={this.state.disabled}
                        >
                          {this.state.loading == true ? (
                            <Loader type="ball-clip-rotate" />
                          ) : (
                            "تحميل المزيد"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <SpeedUpModal
          isSpeedUpOpen={this.state.isSpeedUpOpen}
          closeSpeedUpModal={this.closeSpeedUpModal}
          id={this.state.selectedFileId}
        />
        <CreateSpeedUp
          isCreateSpeedUpOpen={this.state.isCreateSpeedUpOpen}
          closeCreateSpeedUp={this.closeCreateSpeedUpModal}
          filesArray={this.state.files}
          onCreate={this.onCreate}
        />

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggle}
            className="d-flex align-items-center"
          >
            <h6 className="mb-0 dark-text">حذف ملف</h6>
          </ModalHeader>
          <ModalBody className="d-flex align-items-center">
            <p className="dark-text small mb-0">هل أنت متأكد من حذف الملف؟</p>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              className="light-btn"
              onClick={() => this.deleteFile(this.state.fileId)}
            >
              تأكيد
            </Button>{" "}
            <Button size="sm" className="red-btn" onClick={this.toggle}>
              إغلاق
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
      )}
      </>
    );
  }
}
