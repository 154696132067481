import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../api/helpers";
import { connect } from "react-redux";
import { getProfile } from "../../../actions";
import Loader from "react-loaders";
import CoursesTabs from "./CoursesTabs";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";
import "./styles.css";
export class CoursesComponent extends Component {
  page = 1;
  limit = 6;
  endOfResults = false;
  selectedTab = "1";
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      courses: [],
      hideBtn: false,
      nextPageUrl: "", //old url /courses
      isPageLoading: false,
    };
  }

  async componentDidMount() {
    this.props.getProfile();
    await this.loadMore(this.selectedTab);
  }

  loadMore = async (status) => {
    this.setState({ isPageLoading: true });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true });
    if (!this.endOfResults) {
      axios
        .get(`${apiBaseUrl}/Courses/InstructorCoursesWithStatus?Page=${this.page}&Limit=${this.limit}&IsActive=${this.selectedTab === "1"}`, { headers })
        .then((response) => {
          console.log("later-courses: ", response.data.data);
          this.setState({
            loading: false,
            disabled: false,
            isPageLoading: false,
          });
          const newCourses = [...this.state.courses, ...response.data.data.items];
          this.endOfResults = newCourses < this.limit;
          this.page++;
          const nextUrl = `${apiBaseUrl}/Courses/InstructorCoursesWithStatus?Page=${this.page}&Limit=${this.limit}&IsActive=${status === "1"}`;
          this.setState({
            courses: newCourses,
            nextPageUrl: nextUrl,
          });
          if (newCourses.length == response.data.data.totalItems) {
            this.setState({ hideBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
            disabled: false,
            isPageLoading: false,
          });
        });
    }
  };

  renderCourses() {
    const courses = this.state.courses || [];
    return(
      <>
        {
          this.state.isPageLoading ? (
            <div
              className="silver-bg box-layout w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
              style={{ minHeight: 350 }}
            >
              <Loader
                type="ball-spin-fade-loader"
                className="dark-loader"
              />
            </div>
          ) : (
            <>
            {
              courses == undefined || courses.length == 0 ? (
                <React.Fragment>
                  <div
                    className="silver-bg box-layout w-100 pb-0 p-4 mt-4 d-flex flex-column align-items-center justify-content-center"
                    style={{ height: 300 }}
                  >
                     <img
                      src={process.env.PUBLIC_URL + "/assets/images/course.png"}
                      className="mb-1"
                      height="80"
                    />
                    <p className="dark-text mt-0">
                      لا يوجد لديك دورات متاحة
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <>
                {
                  courses.map(course => 
                  <React.Fragment>
                    <div
                      className="col-lg-4 card-container mb-3"
                      style={{ display: "inline-block" }}
                    >
                      <Link to={`/courses/${course.id}`}>
                        <div className="h-100 card shadow-sm card-sm border-0">
                          <header className="card-thumb border-bottom">
                            <img src={course.bannerUrl} alt="CourseImage" />
                          </header>
                          <div className="card-body">
                            <h6 className="card-title small mb-0 p-0 dark-text">
                            {course.nameAr.length > 130
                            ? `${course.nameAr.slice(0, 125)} ...`
                            : course.nameAr}
                          {/* {course.nameAr} */}
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </React.Fragment> )
                }
                </>
              )
            }
            </>
          )
        }
      </>
    )
  }

  render() {
    let avatarImg;
    if (this.props.initialValues.gender == "Male") {
      avatarImg = process.env.PUBLIC_URL + "/assets/images/male-avatar.png";
    } else if (this.props.initialValues.gender == "Female") {
      avatarImg = process.env.PUBLIC_URL + "/assets/images/female-avatar.png";
    }

    return (
      <React.Fragment>
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="white-bg box-layout w-100 p-4 d-flex align-items-center justify-content-center flex-column">
                  <img src={avatarImg} height="110" className="mb-3" />
                  <h6 className="dark-text mb-1">
                    {this.props.initialValues && this.props.initialValues.name}
                  </h6>
                  <p className="dark-text en-text small mb-0">
                    {this.props.initialValues && this.props.initialValues.email}
                  </p>
                  <p className="dark-text en-text small mb-1" dir="ltr">
                    {this.props.initialValues &&
                      this.props.initialValues.phoneNumber}
                  </p>
                  {/* <Link
                    to="/account/edit"
                    className="light-text text-underline small"
                  >
                    تعديل الملف الشخصي
                  </Link> */}
                </div>
              </div>
              <div className="col-md-8">
                <h3 className="dark-text">قائمة دوراتي</h3>
                <>
                  <React.Fragment>
                    <CoursesTabs
                      rendercourses={(status) =>
                        this.renderCourses(status)
                      }
                      Tab={this.selectedTab}
                      loadMore={(status) => {
                        this.page = 1;
                        this.selectedTab = status;
                        this.endOfResults = false;
                        this.setState({courses: []});
                        this.loadMore(status);
                      }}
                    />
                    {!this.state.hideBtn && (
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="btn dark-btn unset-height unset-line-height br-5 mt-3 w-25"
                          onClick={() => {this.loadMore(this.selectedTab);}}
                          disabled={this.state.disabled}
                        >
                          {this.state.loading == true ? (
                            <Loader type="ball-clip-rotate" />
                          ) : (
                            "تحميل المزيد"
                          )}
                        </button>
                      </div>
                    )} 
                  </React.Fragment>
                </>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.profile,
    entireState: state,
  };
}

CoursesComponent = connect(mapStateToProps, { getProfile })(CoursesComponent);

export const Courses = withRouter(CoursesComponent);
