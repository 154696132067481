import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const CoursesTabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.Tab);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      props.loadMore(tab);
    }
  };
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "1",
            })} ${"clickable"}`}
            onClick={() => {
              toggle("1");
            }}
          >
            <h6 className="dark-text small mb-0 mt-0">سارية</h6>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "2",
            })} ${"clickable"}`}
            onClick={() => {
              toggle("2");
            }}
          >
            <h6 className="dark-text small mb-0 mt-0">منتهية</h6>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {activeTab == 1 ? (
            <div className="row no-gutters">
              {props.rendercourses("Active")}
            </div>
          ) : null}
        </TabPane>
        <TabPane tabId="2">
          {activeTab == 2 ? (
            <div className="row no-gutters">
              {props.rendercourses("Finished")}
            </div>
          ) : null}
        </TabPane>
      </TabContent>
    </>
  );
};

export default CoursesTabs;
