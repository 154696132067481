import { Api } from "../api";

export const GET_QUESTION = "getQuestion";
export const SET_QUESTIONS_LIST_ACTIVE_TAB = "setQuestionsListActiveTab";
export const SET_QUESTIONS_LIST_COURSE_ID = "setQuestionsListcourseId";
export const SET_QUESTION_DETAILS_ID = "setQuestionDetailsId";

export const getQuestion = id => {
  return {
    type: GET_QUESTION,
    payload: Api.questions.getQuestion(id)
  };
};
export const setQuestionsListActiveTab = (tab, courseId) => {
  return {
    type: SET_QUESTIONS_LIST_ACTIVE_TAB,
    payload: {tab, courseId}
  };
};
export const setQuestionsListcourseId = id => {
  return {
    type: SET_QUESTIONS_LIST_COURSE_ID,
    payload: id
  };
};
export const setQuestionDetailsId = (questionId, courseId) => {
  return {
    type: SET_QUESTION_DETAILS_ID,
    payload: {questionId, courseId}
  };
};
