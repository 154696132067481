import React from 'react'
import { usePermissions } from './../../../../hooks/usePermissions';
import { PERMISSION_ENUM } from './../../../../enum/permissions';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";


export const ExamQuestionsDropdown = ({toggleQuestionButton,state,onNewClick = ()=>{},openQuestionsModal})=>{

    const [checkAuth] = usePermissions()
    const authed = checkAuth(PERMISSION_ENUM.QuestionsCreate)

    if(!authed) return null
    
    return(
        <ButtonDropdown
        isOpen={state.questionDropdownOpen}
        toggle={toggleQuestionButton}
      >
        <DropdownToggle
          caret
          className="btn light-btn unset-height"
        >
          إضافة سؤال
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className="dark-text small"
            onClick={openQuestionsModal}
          >
            من بنك الأسئلة
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="dark-text small"
            onClick={onNewClick}
          >
            سؤال جديد
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
}