import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../../api/helpers";
import { getUser } from "../../../../../actions/user.actions";
import axios from "axios";
import { Comment } from "./comment";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";
import { ReactMic } from "react-mic";
import swal from "@sweetalert/with-react";
import { SendingRequest } from "../../sharedComponents/sendingRequest";
import SendingModal from "../../sharedComponents/SendingModal";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import CustomTooltip from "../custom-tooltip/custom-tooltip";

var moment = require("moment-hijri");
moment().format("iYYYY/iM/iD");

export class CommentsListComponent extends Component {
  page = 1;
  limit = 10;
  endOfResults = false;
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      comment: "",
      file: "",
      fileError: "",
      isRecording: false,
      isPaused: false,
      commentType: "",
      disabled: false,
      disableSubmit: false,
      soundFile: "",
      videoFile: "",
      isSending: false,
      message: "",
      playedId: -1,
      hideBtn: false,
      nextPageUrl: `${apiBaseUrl}/AskQuestionComments?askQuestionId=${this.props.match.params.questionId}&page=${this.page}&limit=${this.limit}`,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onStop = this.onStop.bind(this);
    this.openDiscussion = this.openDiscussion.bind(this);
    this.setPlayedId = this.setPlayedId.bind(this);
  }

  openDiscussion() {
    this.props.openDiscussion()
  }

  validateImage = (target) => {
    this.setState({ fileError: "" });

    // Start Image Validation
    const notAllowedImage = [
      "عفواً امتداد الصورة يجب أن يكون أحد الأنواع png, jpg, jpeg, svg",
      "مساحة الصورة يجب ألا تتجاوز 1 ميجا بايت",
    ];
    const endFile = target.value.slice(target.value.lastIndexOf(".") + 1);
    const isImage =
      endFile === "png" ||
      endFile === "jpeg" ||
      endFile === "svg" ||
      endFile === "jpg";

    if (!isImage) {
      this.setState({ file: "", fileError: notAllowedImage[0] });
      target.value = "";
      return;
    }

    let size = parseFloat(target.files[0].size / (1024 * 1024)).toFixed(2);
    if (size > 1) {
      this.setState({ file: "", fileError: notAllowedImage[1] });
      target.value = "";
      return;
    }

    const validatedFile = target.files[0];
    return validatedFile;

    // End Image Validation
  };

  handleFileChange = (event) => {
    let validatedFile = this.validateImage(event.target);
    if (!validatedFile) return;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("FileType", "Image");
    data.append("File", event.target.files[0]);
    data.append("Type", "Comments");
    this.setState({ disableSubmit: true, isSending: true, message: "جاري التحميل",soundFile: "", videoFile: "",});
    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          file: response.data.data.url,
          commentType: "Image",
          isSending: false,
          message: "",
          disableSubmit: false,
        });
        // if (this.state.file) {
        //   const askQuestionId = this.props.match.params.questionId;
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`
        //   };
        //   let data = {
        //     type: "Image",
        //     value: this.state.file
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments?askQuestionId=${askQuestionId}`,
        //       data,
        //       {
        //         headers
        //       }
        //     )
        //     .then(response => {
        //       const comment = response.data.data;
        //       this.setState(prevState => {
        //         return {
        //           comments: [comment, ...prevState.comments]
        //         };
        //       });
        //       this.commentInput.value = "";
        //       this.setState({ file: "", disabled: false, isSending: false });
        //     })
        //     .catch(error => {
        //       this.setState({ disabled: false, isSending: false });
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({ isSending: false, disableSubmit: false, message: "" });
        console.log(error);
      });
  };

  loadMore = async () => {
    const askQuestionId = this.props.match.params.questionId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true });
    if (!this.endOfResults) {
      axios
        .get(this.state.nextPageUrl, { headers })
        .then((response) => {
          this.setState({ loading: false, disabled: false });
          const newComments = [
            ...this.state.comments,
            ...response.data.data.data,
          ];
          this.endOfResults = response.data.data.itemCount < this.limit;
          this.page++;
          const nextUrl = `${apiBaseUrl}/AskQuestionComments?askQuestionId=${askQuestionId}&page=${this.page}&limit=${this.limit}`;
          this.setState({
            comments: newComments,
            nextPageUrl: nextUrl,
          });
          if (newComments.length == response.data.data.itemCount) {
            this.setState({ hideBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false });
        });
    }
  };

  async componentDidMount() {
    this.props.getUser();
    await this.loadMore();
  }

  setPlayedId(id) {
    this.setState({playedId: id})    
  }

  handleVideosChange = (event) => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    let data = new FormData();
    data.append("FileType", "Video");
    data.append("File", event.target.files[0]);
    data.append("Type", "Comments");
    this.setState({ disabled: true, isSending: true, message: "جاري التحميل", soundFile: "", file: "",});
    this.commentInput.value = ""
    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          videoFile: response.data.data.url,
          commentType: "Video",
          disabled: false, 
          isSending: false,
          message: "",
        });
        // if (this.state.videoFile) {
        //   const askQuestionId = this.props.match.params.questionId;
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`,
        //   };
        //   let data = {
        //     type: "Video",
        //     videoUrl: this.state.videoFile,
        //   };
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments?askQuestionId=${askQuestionId}`,
        //       data,
        //       {
        //         headers,
        //       }
        //     )
        //     .then((response) => {
        //       const comment = response.data.data;
        //       this.setState((prevState) => {
        //         return {
        //           comments: [comment, ...prevState.comments],
        //         };
        //       });
        //       this.openDiscussion()
        //       this.setState({
        //         videoFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       swal("تم اضافة التعليق بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch((error) => {
        //       this.setState({
        //         videoFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       // swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        //       swal("عفواً", "لم يتم إضافة التعليق برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       });   
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({
          videoFile: "",
          disabled: false,
          isSending: false,
          message: "",
        });
        // swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        swal("عفواً", `حدث خطأ أثناء تحميل الفديو بكود ${error.message}`, "error");
        console.log(error);
      });
  };

  startOrPauseRecording = () => {
    const { isPaused, isRecording } = this.state;

    if (isPaused) {
      this.setState({ isPaused: false });
    } else if (isRecording) {
      this.setState({ isPaused: true });
    } else {
      this.setState({ isRecording: true });
    }
  };

  stopRecording = () => {
    this.setState({ isRecording: false });
  };

  onStop(blobObject) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("FileType", "Voice");
    data.append("File", blobObject.blob);
    data.append("Type", "Comments");

    this.setState({ isSending: true, soundFile: "", videoFile: "", file: "", disabled: true, message: "جاري التحميل", });
    this.commentInput.value = ""

    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          soundFile: response.data.data.url,
          commentType: "Voice",
          disabled: false,
          isSending: false, 
          message: "",
        });
        // if (this.state.soundFile) {
        //   const askQuestionId = this.props.match.params.questionId;
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`,
        //   };
        //   let data = {
        //     type: "Voice",
        //     voiceUrl: this.state.soundFile,
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments?askQuestionId=${askQuestionId}`,
        //       data,
        //       {
        //         headers,
        //       }
        //     )
        //     .then((response) => {
        //       const comment = response.data.data;
        //       this.setState((prevState) => {
        //         return {
        //           comments: [comment, ...prevState.comments],
        //         };
        //       });
        //       this.openDiscussion()
        //       this.setState({
        //         soundFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       swal("تم اضافة التعليق بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch((error) => {
        //       this.setState({
        //         soundFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       // swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        //       swal("عفواً", "لم يتم إضافة التعليق برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       });
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({
          soundFile: "",
          disabled: false,
          isSending: false,
        });
        swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        console.log(error);
      });
  }

  renderComments = () => {
    return (
      this.state.comments &&
      this.state.comments.map(
        (comment) =>
          comment && (
            <Comment
              comment={comment}
              key={comment}
              setPlayedId={this.setPlayedId}
              playedId={this.state.playedId}
              user={this.props.user}
              openDiscussion={this.openDiscussion}
              onDelete={() => this.confirmDeleteComment(comment.id)}
              questionIsPrivate={this.props.questionIsPrivate}
            />
          )
      )
    );
  };

  confirmDeleteComment(id) {
    swal({
      // title: "تأكيد الحذف !",
      text: "هل تريد حذف هذا التعليق بشكل نهائي؟",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
      dangerMode: true,
    }).then((value) => {
      switch (value) {
        case "ok":
          this.deleteComment(id);
          break;

        case "cancel":
          swal("لم يتم حذف التعليق", {
            icon: "info",
            button: "متابعة",
          });
          break;

        default:
          swal("لم يتم حذف التعليق", {
            icon: "info",
            button: "متابعة",
          });
      }
    });
  }

  deleteComment(id) {
    swal({
      content: <SendingRequest message="جاري الحذف" />,
      button: {
        visible: false,
        closeModal: false,
      },
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${apiBaseUrl}/AskQuestionComments/${id}`, {
        headers,
      })
      .then((response) => {
        let oldComments = this.state.comments.slice();
        const commentIndex = oldComments.findIndex((c) => c.id === id);
        oldComments.splice(commentIndex, 1);
        this.setState({
          comments: oldComments,
        });
        swal("تم حذف التعليق بنجاح !", {
          icon: "success",
          button: "متابعة",
        });
      })
      .catch((error) => {
        swal("عفواً", "لم يتم حذف التعليق برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value, fileError: "", soundFile: "", videoFile: "",});
    if (!this.state.file && this.commentInput.value === "") {
      this.setState({ disableSubmit: true });
    } else {
      this.setState({ disableSubmit: false });
    }
  };

  handleSubmit(event, isPrivate) {
    event.preventDefault();
    const askQuestionId = this.props.match.params.questionId;

    let data;
    if (this.state.file && this.commentInput.value !== "") {
      data = {
        type: "Mixed",
        value: this.state.comment,
        imageUrl: this.state.file,
        isPrivate,
      };
    } else if (this.state.file) {
      data = {
        type: "Image",
        imageUrl: this.state.file,
        isPrivate
      };
    } else if (this.commentInput.value !== "") {
      data = {
        type: "Text",
        value: this.state.comment,
        isPrivate
      };
    } else if(this.state.videoFile){
      data = {
        type: "Video",
        videoUrl: this.state.videoFile,
        isPrivate
      };
    } else if(this.state.soundFile){
      data = {
        type: "Voice",
        voiceUrl: this.state.soundFile,
        isPrivate
      };
    }

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ disabled: true, disableSubmit: true, isSending: true, message: "جاري الإرسال" });
    axios
      .post(
        `${apiBaseUrl}/AskQuestionComments?askQuestionId=${askQuestionId}`,
        data,
        {
          headers,
        }
      )
      .then((response) => {
        const comment = response.data.data;
        this.setState((prevState) => {
          return {
            comments: [...prevState.comments, comment],
          };
        });
        this.openDiscussion()
        this.commentInput.value = "";
        this.setState({ file: "", videoFile: "", soundFile: "", disabled: false, disableSubmit: false, isSending: false, message: "", });
        swal("تم اضافة التعليق بنجاح ", {
          button: "متابعة",
          icon: "success",
        });
      })
      .catch((error) => {
        this.setState({ file: "", videoFile: "", soundFile: "", disabled: false, disableSubmit: false, isSending: false, message: "", });
        // swal("عفواً", " لم يتم إضافة التعليق برجاء عاود المحاولة أو تواصل مع خدمة العملاء", "error");
        swal("عفواً", `حدث خطأ أثناء الإرسال بكود ${error.message}` , "error");
        console.log(error);
      });
  }

  render() {
    const { isRecording, isPaused } = this.state;
    const style = { pointerEvents: this.state.disableSubmit ? "none" : "auto", width: "110px", padding: "0 3px 0 0",};
    return (
      <>
        <div className="col-12">
          <div className="silver-bg p-4 TheSansArabMedium-font text-break">
            <h6 className="dark-text mb-3">لديك تعليق؟</h6>
            <form>
              <div className="position-relative">
                <textarea
                  id="commentInput"
                  type="text"
                  name="comment"
                  onChange={this.handleChange}
                  placeholder="اذا كنت تعرف جواب السؤال اترك تعليقا"
                  rows="6"
                  className="form-control small dark-text shadow-sm mb-3"
                  ref={(ref) => (this.commentInput = ref)}
                />
                <div className="textarea-icon d-flex align-items-center">
                  {isRecording ? (
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/blue-mute.png"
                      }
                      alt="Record"
                      height="28"
                      className="contain-img"
                      disabled={!isRecording}
                      onClick={this.stopRecording}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/dark-mic.png"}
                      alt="Record"
                      height="28"
                      className="contain-img"
                      onClick={this.startOrPauseRecording}
                    />
                  )}
                  <ReactMic
                    record={isRecording}
                    pause={isPaused}
                    onStop={this.onStop}
                    mimeType="audio/mp3"
                  />

                  <label htmlFor="uploadImage" className="mb-0 mr-1">
                    <input
                      className="d-none"
                      id="uploadImage"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/svg"
                      onChange={this.handleFileChange}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/picture.png"}
                      height="28"
                      width="28"
                      className="contain-img clickable"
                      alt="attachPictureIcon"
                    />
                  </label>

                  <label htmlFor="uploadVideo" className="mb-0">
                    <input
                      className="d-none"
                      id="uploadVideo"
                      type="file"
                      accept="video/*"
                      onChange={this.handleVideosChange}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/attach-video.png"
                      }
                      height="28"
                      width="28"
                      className="contain-img clickable"
                      alt="attach-videoIcon"
                    />
                  </label>
                </div>
              </div>
              <div className="my-2">
              
              {this.state.file ? (
                <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                  <div className="top-left" onClick={()=>this.setState({file: ""})}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                      width="25"
                      className="contain-img clickable"
                      alt="remove-icon"
                    />
                  </div>
                  <div className="">
                    <img
                      src={this.state.file}
                      className="contain-img clickable w-100"
                      height="130px"
                      alt="comment"
                      id="picture-icon"
                    />
                  </div>
                </div>
              ): null}

              {this.state.videoFile ? (
                <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                  <div className="top-left" onClick={()=>this.setState({videoFile: ""})}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                      width="25"
                      className="contain-img clickable"
                      alt="remove-icon"
                    />
                  </div>
                  {/* <div className="">
                    <video
                      height="200"
                      className="video-container video-container-overlay w-100 max-width-300px"
                      autoPlay=""
                      controls
                    >
                      <source
                        type="video/mp4"
                        data-reactid=".0.1.0.0.0"
                        src={this.state.videoFile}
                      />
                    </video>
                  </div> */}

                  <div className='player-wrapper'>
                    <ReactPlayer 
                      className='react-player'
                      controls
                      playIcon	
                      width="100%"
                      height="100%"
                      url={this.state.videoFile} 
                    />
                  </div>
                </div>
              ): null}

              {this.state.soundFile ? (
                <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                  <div className="top-left" onClick={()=>this.setState({soundFile: ""})}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                      width="25"
                      className="contain-img clickable"
                      alt="remove-icon"
                    />
                  </div>
                  <div className="">
                  <ReactAudioPlayer src={this.state.soundFile} controls className="w-100"
                  />
                  </div>
                </div>
              ): null}

              {this.state.fileError ? (
                <>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/not-verified.png"
                    }
                    height="20"
                    width="20"
                    className="contain-img clickable mr-1"
                    alt="error"
                  />
                  <small className="ar-text">{this.state.fileError}</small>
                </>
              ) : null}

                {/* {this.state.isSending ? (
                  <div
                    className="silver-bg w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
                    style={{ minHeight: 100 }}
                  >
                    <Loader
                      type="ball-spin-fade-loader"
                      className="dark-loader"
                    />
                  </div>
                ) : this.state.file ? (
                  <img
                    src={this.state.file}
                    className="contain-img clickable w-100"
                    height="130px"
                    alt="comment"
                    id="picture-icon"
                  />
                ) : this.state.fileError ? (
                  <>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/not-verified.png"
                      }
                      height="20"
                      width="20"
                      className="contain-img clickable mr-1"
                      alt="error"
                    />
                    <small className="ar-text">{this.state.fileError}</small>
                  </>
                ) : null} */}
              </div>
              <div className="float-right">
                <button
                  className="btn light-outline-btn btn-sm unset-height mr-2 d-inline-flex justify-content-between"
                  disabled={this.state.disabled || this.state.disableSubmit || (!this.state.file && !this.state.comment && !this.state.videoFile && !this.state.soundFile)}
                  style={style}
                  type="submit"
                  onClick={(e)=>this.handleSubmit(e, true)}
                >
                  <span className="flex-fill" style={{padding: "5px"}}>ارسال</span>
                  <div className="icon-container">
                    {/* <img src={process.env.PUBLIC_URL + "/assets/images/dark-lock.png"} width={20}/> */}
                    <CustomTooltip isPrivate isComment />
                  </div>
                </button>
                {
                  !this.props.questionIsPrivate?(
                  <button
                  className="btn light-outline-btn btn-sm unset-height d-inline-flex justify-content-between"
                    disabled={this.state.disabled || this.state.disableSubmit || (!this.state.file && !this.state.comment && !this.state.videoFile && !this.state.soundFile)}
                    style={style}
                    type="submit"
                    onClick={(e)=>this.handleSubmit(e, false)}
                  >
                    <span style={{padding: "5px"}}>ارسال ونشر</span>
                    <div className="icon-container">
                      {/* <img src={process.env.PUBLIC_URL + "/assets/images/users.png"} width={20}/> */}
                      <CustomTooltip isComment />
                    </div>
                  </button>
                  ): null
                }
              </div>
              <div className="clearfix" />
            </form>
            <div className="d-flex justify-content-between align-items-center responsive-col mt-4">
              <h6 className="dark-text small mb-0 mt-0">التعليقات</h6>

              <div className="d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/comments.png"}
                  height="15"
                  width="20"
                  className="contain-img mr-1"
                  alt="commentsIcon"
                />
                <h6 className="mid-text en-text mb-0">
                  {this.state.comments.length}
                </h6>
              </div>
            </div>
            {this.renderComments()}
            {!this.state.hideBtn && (
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="btn dark-btn unset-height unset-line-height br-5 mt-3 w-25"
                  onClick={this.loadMore}
                  disabled={this.state.disabled}
                >
                  {this.state.loading == true ? (
                    <Loader type="ball-beat" className="dark-loader" />
                  ) : (
                    "تحميل المزيد"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        <SendingModal isOpen={this.state.isSending} message={this.state.message} />

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.CommentsList && state.form.CommentsList.values,
    user: state.user,
  };
}

CommentsListComponent = reduxForm({
  form: "CommentsList",
})(CommentsListComponent);

CommentsListComponent = connect(mapStateToProps, { getUser })(
  CommentsListComponent
);

export const CommentsList = withRouter(CommentsListComponent);
