import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../styles.sass";
import { QuestionModal } from "./create-question";
import { QuestionsModal } from "./questions-modal";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { apiBaseUrl } from "../../../../api/helpers";
import axios from "axios";
import swal from "@sweetalert/with-react";
import { EditQuestionComponent } from "./edit-question";
import { getExamStatus, isDisabledStatus } from "./status";
import { ExamQuestionsDropdown } from './exam-questions-dropdown';
import { ExamQuestionsActions } from './quesitons-actions';
import AuthedContent from '../../../../HOC/AuthedContent'
import { PERMISSION_ENUM } from "../../../../enum/permissions";
import { errorsMapper } from "../../../../utils/error/error";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";

export class ExamDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuestionOpen: false,
      isQuestionsOpen: false,
      modal: false,
      questionDropdownOpen: false,
      examDropdownOpen: false,
      examDetails: [],
      questions: [],
      status: "",
      questionId: null,
      editQuestion: false,
      selectedQuestionId: null,
      isPageLoading: false,
    };
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.validateMsg = this.validateMsg.bind(this);
    this.toggleExamButton = this.toggleExamButton.bind(this);
    this.toggleQuestionButton = this.toggleQuestionButton.bind(this);
    this.alert = this.alert.bind(this);
  }

  validateMsg(){
    const rejectNote = this.state.examDetails?.rejectNote
    if(rejectNote) throw new Error(rejectNote)
  }

  toggleExamButton() {
    try {
      this.validateMsg()
      this.setState({
        examDropdownOpen: !this.state.examDropdownOpen,
      });
    } catch (error) {
      this.alert(error.message)
    }
  }

  select(event) {
    const examId = this.props.match.params.examId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = {
      status: event.target.value,
    };
    axios
      .put(`${apiBaseUrl}/Exams/${examId}/Status`, data, { headers })
      .then((response) => {
        this.setState((prevState) => ({
          examDropdownOpen: false,
          examDetails: {
            ...prevState.examDetails,
            status: response.data.data.status,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        switch (error.response.data && error.response.data.error) {
          case "AccessDeniedError":
            swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
              button: "متابعة",
            });
            break;
          default:
            console.log("other error");
        }
      });
  }

  toggleQuestionButton() {
    this.setState({
      questionDropdownOpen: !this.state.questionDropdownOpen,
    });
  }

  openQuestionModal = () => {
    this.setState({ isQuestionOpen: true });
  };
  closeQuestionModal = () => {
    this.setState({ isQuestionOpen: false });
  };

  openQuestionsModal = () => {
    this.setState({ isQuestionsOpen: true });
  };
  closeQuestionsModal = () => {
    this.setState({ isQuestionsOpen: false });
  };

  openEditModal = (id) => {
    this.setState({ editQuestion: true, selectedQuestionId: id });
  };
  closeEditModal = () => {
    this.setState({ editQuestion: false });
  };

  toggle(id) {
    this.setState(() => ({
      modal: !this.state.modal,
      questionId: id,
    }));
  }

  alert(error){
    swal("عفواً", error, "error", {
      button: "متابعة",
    });
  }

  findExam(exams = [],examId) {
    return exams.find(exam => exam.id == examId)
  }

  componentDidMount = () => {
    const examId = this.props.match.params.examId;
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/Exams`, { headers , params:{courseId,type:'Exam'} })
      .then((response) => {
        this.setState({ examDetails: this.findExam(response.data.data,examId) });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({isPageLoading: true,})
    axios
        .get(`${apiBaseUrl}/Exams/${examId}/Questions`, { headers })
        .then((response) => {
          let max = 1;
          if( response.data.data.length !== 0){
            max = Math.max.apply(Math, response.data.data.map((o)=>  o.order +1))
          }
          this.setState({ 
            questions: response.data.data,maxOrder:max,     
            isPageLoading: false,
          });
        })
        .catch((err)=>{
          console.log(err);
          this.setState({isPageLoading: false,})
        })

  };

  deleteQuestion(id,cb = () => {}) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(
        `${apiBaseUrl}/exams/${this.state.examDetails.id}/Questions/${id}`,
        {
          headers,
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
        let oldQuestions = this.state.questions.slice();
        const questionIndex = oldQuestions.findIndex((q) => q.id === id);
        oldQuestions.splice(questionIndex, 1);
        this.setState({
          questions: oldQuestions,
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMsg = errorsMapper(error.response.data.error)
            swal("عفواً", errorMsg, "error", {
              button: "متابعة",
            });
      });
  }

  navigateToQuestion(question){
    const courseId = this.props.match.params.id;
    const examId = this.props.match.params.examId;

    this.props.history.push(
      `/courses/${courseId}/exams/update/${examId}/${question.id}`
    )
  }

  checkUpdateAbility(question,onSuccess = ()=>{}){
    const examId = this.props.match.params.examId;
      const {id : questionId} = question
      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${apiBaseUrl}/Questions/${questionId}/${examId}`, { headers })
        .then((response) => {
          onSuccess()
        }).catch((error) => {
          switch (error.response.data && error.response.data.error) {
            case "AccessDeniedError":
              swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
                button: "متابعة",
              });
              break;
            default:break;
          }
        })
    }

  onUpdate(question){
      this.checkUpdateAbility(question,()=>this.navigateToQuestion(question))
  }

  renderQuestions() {
    const courseId = this.props.match.params.id;
    const examId = this.props.match.params.examId;
    const questions = this.state.questions || [];
    return questions.map((question) => (
      <React.Fragment>
        <div className="question-item">
          <div className="row p-4 pb-2">
            <div className="col-12 d-flex align-items-center mb-2 justify-content-between">
              <h6 className="light-text mt-0 mb-0">
                سؤال <span className="en-text">{question.id}#</span>
              </h6>
              <ExamQuestionsActions
                onUpdate={() => this.onUpdate(question)}
                onDelete={() => this.toggle(question.id)}
              />
            </div>

            <div className="col-12">
              <div className="box-layout box-border shadow-sm p-2 ">
                <h6
                  className="dark-text mb-0 encoded-text img-auto-for-children"
                  dangerouslySetInnerHTML={{
                    __html: question.encodedStem,
                  }}
                ></h6>
              </div>
            </div>
          </div>
          <div className="row p-4">
            <div className="col-md-7">
              {Object.keys(question.choices).map(function(key) {
                return (
                  <div
                    className={`box-layout  d-flex align-items-center pr-2 pl-2 mb-2 ${
                      key == question.correctChoice ? "green-border" : null
                    }`}
                  >
                    <label
                      className={`small dark-silver-text light-font-text d-flex align-items-center mb-0 ${
                        key == question.correctChoice ? "green-text" : null
                      }`}
                    >
                      <h6
                        className="dark-text mb-0 encoded-text img-auto-for-children"
                        dangerouslySetInnerHTML={{
                          __html: question.choices[key],
                        }}
                      ></h6>
                    </label>
                  </div>
                );
              })}
            </div>
            {question.imageUrl && (
              <div className="col-5 d-flex align-items-center">
                <img src={question.imageUrl} width="100%" />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="mb-0" />
          </div>
        </div>
      </React.Fragment>
    ));
  }

  render() {
    const courseId = this.props.match.params.id;
    const examId = this.props.match.params.examId;
    return (
      <React.Fragment>
        <div className="row no-gutters">
          <div className="col-12">
            <div className="box-layout shadow-sm h-100">
              <div className="row p-4">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="mid-text mb-0">
                      {this.state.examDetails && this.state.examDetails.title}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center">
                    <AuthedContent permissionType={['QuestionsCreate','QuestionsEdits' ]}>
                      <ButtonDropdown
                        isOpen={this.state.examDropdownOpen}
                        toggle={this.toggleExamButton}
                        >
                        <DropdownToggle
                          caretw
                          // disabled={isDisabledStatus(this.state.examDetails.status)}
                          className="btn light-btn unset-height mr-2"
                          >
                          {getExamStatus(this.state.examDetails && this.state.examDetails.status)}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className="dark-text small"
                            onClick={this.select}
                            value="Active"
                            >
                            تم النشر
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            className="dark-text small"
                            onClick={this.select}
                            value="Draft"
                            >
                            مسودة
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>

                      <ExamQuestionsDropdown
                        toggleQuestionButton={this.toggleQuestionButton}
                        onNewClick={() =>
                          this.props.history.push(
                            `/courses/${courseId}/exams/create/${examId}?order=${this.state.maxOrder}`
                            )
                          }
                          state={this.state}
                          openQuestionsModal={this.openQuestionsModal}
                          />
                      </AuthedContent>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr className="mb-0 mt-0" />
                </div>
              </div>
              {this.state.isPageLoading ? (
                <div
                  className="silver-bg box-layout w-100 pb-0 p-3 mt-1 d-flex justify-content-center align-items-center"
                  style={{ minHeight: 350 }}
                >
                  <Loader type="ball-spin-fade-loader" className="dark-loader" />
                </div>
              ):this.state.questions == undefined ||
                this.state.questions.length == 0 ? (
                  <React.Fragment>
                    <div
                      className="d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center"
                      style={{ height: 300 }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/empty-questions.png"
                        }
                        height="70"
                        className="contain-img mb-3"
                      />
                      <p className="dark-silver-text mt-0 mb-0">
                        لا يوجد أسئلة متاحة
                      </p>
                    </div>
                  </React.Fragment>
              ) : (
                <React.Fragment>{this.renderQuestions()}</React.Fragment>
              )}
            </div>
          </div>
        </div>
        <QuestionModal
          isQuestionOpen={this.state.isQuestionOpen}
          closeQuestion={this.closeQuestionModal}
          examId={this.props.match.params.examId}
        />
        <QuestionsModal
          isQuestionsOpen={this.state.isQuestionsOpen}
          closeQuestions={this.closeQuestionsModal}
          examId={this.props.match.params.examId}
        />
        <EditQuestionComponent
          isEditQuestionOpen={this.state.editQuestion}
          closeQuestion={this.closeEditModal}
          examId={this.props.match.params.examId}
          questionId={this.state.selectedQuestionId}
        />

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggle}
            className="d-flex align-items-center"
          >
            <h6 className="mb-0 dark-text">حذف سؤال</h6>
          </ModalHeader>
          <ModalBody className="d-flex align-items-center">
            <p className="dark-text small mb-0">هل أنت متأكد من حذف السؤال؟</p>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" className="red-btn" onClick={this.toggle}>
              إلغاء
            </Button>
            <Button
              size="sm"
              className="light-btn"
              onClick={() => this.deleteQuestion(this.state.questionId)}
            >
              تأكيد
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
