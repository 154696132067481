import React, { Component } from "react";
import "./styles.sass";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DiscussionComponent } from "./create-discussion";
import { Link } from "react-router-dom";
import { apiBaseUrl } from "../../../../api/helpers";
import axios from "axios";
import { EditDiscussion } from "./edit-discussion";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";

export class DiscussionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDiscussionOpen: false,
      discussions: [],
      selectedDiscussionId: null,
      modal: false,
      discussionId: null,
      isEditDiscussionOpen: false,
      isPageLoading: false
    };

    this.toggle = this.toggle.bind(this);
  }

  openDiscussionModal = () => {
    this.setState({ isDiscussionOpen: true });
  };
  closeDiscussionModal = () => {
    this.setState({ isDiscussionOpen: false });
  };
  openEditDiscussionModal = id => {
    this.setState({ isEditDiscussionOpen: true, discussionId: id });
  };
  closeEditDiscussionModal = () => {
    this.setState({ isEditDiscussionOpen: false });
  };

  toggle(id) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      discussionId: id
    }));
  }

  componentDidMount() {
    this.setState({ isPageLoading: true });
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${apiBaseUrl}/discussions?courseId=${courseId}`, { headers })
      .then(response => {
        this.setState({ discussions: response.data.data, isPageLoading: false  });
      })
      .catch(error => {
        this.setState({ isPageLoading: false });
        console.log(error);
      });
  }

  deleteDiscussion(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .delete(`${apiBaseUrl}/discussions/${id}`, {
        headers
      })
      .then(response => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        let oldDiscussions = this.state.discussions.slice();
        const discussionIndex = oldDiscussions.findIndex(d => d.id === id);
        oldDiscussions.splice(discussionIndex, 1);
        this.setState({
          discussions: oldDiscussions
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderDiscussions() {
    const discussions = this.state.discussions;
    const courseId = this.props.match.params.id;

    if (discussions) {
      return discussions.map(discussion => {
        const startsAt = new Date(discussion.startsAt);
        const startDate = startsAt.toLocaleString();
        const endsAt = new Date(discussion.endsAt);
        const endDate = endsAt.toLocaleString();
        return (
          <div className="item-view d-flex align-items-center">
            <div className="media w-90 d-flex align-items-center justify-content-between">
              <Link
                to={`/courses/${courseId}/discussions/details/${discussion.id}`}
              >
                <div className="media-body">
                  <h6 className="dark-text">{discussion.title}</h6>
                  <div className="d-flex align-items-center">
                    <h6 className="dark-silver-text smaller mb-0 mr-3">
                      من:{" "}
                      <span className="en-text" dir="ltr">
                        {startDate}
                      </span>
                    </h6>
                    <h6 className="dark-silver-text smaller mb-0">
                      إلى:{" "}
                      <span className="en-text" dir="ltr">
                        {endDate}
                      </span>
                    </h6>
                  </div>
                </div>
              </Link>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/edit.png"}
                  height="22"
                  width="22"
                  className="contain-img clickable"
                  onClick={() => this.openEditDiscussionModal(discussion.id)}
                />
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                  height="22"
                  width="22"
                  className="contain-img ml-1 mr-2 clickable"
                  onClick={() => this.toggle(discussion.id)}
                />
              </div>
            </div>
            <div className="vertical-separator custom-height" />
            <div className="w-10 d-flex flex-column align-items-center justify-content-center">
              {discussion.active == true ? (
                <span className="badge light-bg text-white">مفتوح</span>
              ) : (
                <span className="badge red-bg text-white">مغلق</span>
              )}
            </div>
          </div>
        );
      });
    }
  }

  render() {
    return (
      <>
      {this.state.isPageLoading ? (
        <div
        className="silver-bg box-layout w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
        style={{ minHeight: 350 }}
      >
        <Loader type="ball-spin-fade-loader" className="dark-loader" />
      </div>
      ) : (
      <React.Fragment>
        <div className="row mb-4 no-gutters">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center responsive-col">
              <h6 className="dark-text small mb-0 mt-0">المناقشات</h6>
              <button
                type="button"
                className="btn light-btn pr-4 pl-4"
                onClick={this.openDiscussionModal}
              >
                نقاش جديد
              </button>
              <DiscussionComponent
                isDiscussionOpen={this.state.isDiscussionOpen}
                closeDiscussion={this.closeDiscussionModal}
                courseId={this.props.match.params.id}
              />
              <EditDiscussion
                isEditDiscussionOpen={this.state.isEditDiscussionOpen}
                closeEditDiscussion={this.closeEditDiscussionModal}
                discussionId={this.state.discussionId}
              />
            </div>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-12">
            {this.state.discussions == undefined ||
            this.state.discussions.length == 0 ? (
              <React.Fragment>
                <div
                  className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center"
                  style={{ height: 300 }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/empty-discussions.png"
                    }
                    height="90"
                    className="contain-img mb-3"
                  />
                  <p className="dark-text mt-0 mb-0">لا يوجد مناقشات متاحة </p>
                </div>
              </React.Fragment>
            ) : (
              <div className="box-layout shadow-sm">
                {this.renderDiscussions()}
              </div>
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggle}
            className="d-flex align-items-center"
          >
            <h6 className="mb-0 dark-text">حذف مناقشة</h6>
          </ModalHeader>
          <ModalBody className="d-flex align-items-center">
            <p className="dark-text small mb-0">
              هل أنت متأكد من حذف المناقشة؟
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              className="light-btn"
              onClick={() => this.deleteDiscussion(this.state.discussionId)}
            >
              تأكيد
            </Button>{" "}
            <Button size="sm" className="red-btn" onClick={this.toggle}>
              إغلاق
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
      )}
      </>
    );
  }
}
