import { GET_QUESTION, SET_QUESTIONS_LIST_ACTIVE_TAB, SET_QUESTIONS_LIST_COURSE_ID, SET_QUESTION_DETAILS_ID } from "../actions/questions.actions";

export const questionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUESTION:
      return action.payload;

    default:
      return state;
  }
};

export const askQuestionsReducer = (state = {activeTab: "1", courseId: "", questionDetailsId: ""}, action) => {
  switch (action.type) {
    case SET_QUESTIONS_LIST_ACTIVE_TAB:
      return {...state, activeTab: action.payload.tab, courseId: action.payload.courseId, questionDetailsId: "" };
    case SET_QUESTIONS_LIST_COURSE_ID:
      return {...state, activeTab: action.payload === state.courseId? state.activeTab : "1", questionDetailsId: action.payload === state.courseId? state.questionDetailsId : "", };
    case SET_QUESTION_DETAILS_ID:
      return {...state, questionDetailsId: action.payload.questionId, courseId: action.payload.courseId };

    default:
      return state;
  }
};
