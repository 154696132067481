import React, { useEffect, useState } from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import KJUR from "jsrsasign";

const ZoomMeeting = ({
  meetingNumber,
  passWord,
  text,
  accountKey,
  accountSecret,
  parent,
  userName,
  lectureStart
}) => {

  const [width, setWidth] = useState();
  useEffect(() => {
    const rederDiv = document.getElementById("meetingSDKElement");
    console.log(rederDiv.offsetWidth);
    setWidth(rederDiv.offsetWidth);
  }, []);
  const client = ZoomMtgEmbedded.createClient();

  var sdkKey = accountKey;
  var role = 1;
  var userEmail = "";
  var authEndpoint = '';
  var registrantToken = '';
  var zakToken = '';

  function getSignature(e) {
    e.preventDefault();

    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;

    const oHeader = { alg: "HS256", typ: "JWT" };

    const oPayload = {
      sdkKey,
      iat,
      exp,
      mn: meetingNumber,
      role
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const signature = KJUR.jws.JWS.sign(
      "HS256",
      sHeader,
      sPayload,
      accountSecret
    );
    startMeeting(signature);
  }


  const startMeeting = (signature) => {
    let meetingSDKElement = document.getElementById("meetingSDKElement");

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        video: {
          isResizable: true,
          viewSizes: {
            default: {
              width: width,
              height: 450,
            },
            ribbon: {
              width: 300,
              height: 700,
            },
          },
        },
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
      },
    });

    client.join({
      sdkKey: sdkKey,
      signature: signature,
      meetingNumber: meetingNumber,
      password: passWord,
      userName: userName,
      userEmail: userEmail,
      
    });
  };

  return (
    <button
      className={`btn light-btn ${lectureStart ? "light-btn-flash" : ""}`}
      onClick={getSignature}
      style={parent === "LiveLectures" ? {} : { width: "45%" }}
    >
      {text}
    </button>
  );
};

export default ZoomMeeting;
