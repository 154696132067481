import React, { Component } from "react";
import Loader from "react-loaders";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { Field, reduxForm } from "redux-form";
import { RadioField } from "../../../shared/inputs/RadioFeild";
import CounterInput from "react-counter-input";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Input,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import swal from "sweetalert";
import Editor from "./Editor";
import { selectField } from "../../../shared/inputs/selectField";
import moment from "moment-hijri";
import {errorsMapper} from '../../../../utils/error/error'

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...inputProps}
    {...props}
  />
);

const validate = (values) => {
  const errors = {};
  if (!values.level) {
    errors.level = "يجب تعبئة هذه الخانة";
  }
  if (!values.explanation) {
    errors.explanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.solutionExplanation) {
    errors.solutionExplanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.first) {
    errors.first = "يجب تعبئة هذه الخانة";
  }
  if (!values.second) {
    errors.second = "يجب تعبئة هذه الخانة";
  }
  if (!values.third) {
    errors.third = "يجب تعبئة هذه الخانة";
  }
  if (!values.forth) {
    errors.forth = "يجب تعبئة هذه الخانة";
  }
  if (!values.correctChoice) {
    errors.correctChoice = "يجب تعبئة هذه الخانة";
  }
  return errors;
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export class CreateExam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      activeInnerTab: "4",
      activeHintTab: "6",
      loading : false,
      file: null,
      subjects : [],
      subSubjects:[],
      imageUrl: null,
      solutionFile: null,
      solutionVideo: null,
      hintFile: null,
      hintVideo: null,
      parentTopics: [],
      count: 30,
      orderQuestion: 1,
      topicShow:true,
      tags: [],
      suggestions: [],
      topics: [],
      data: {},
      selectedChildtopics: [],
      selectedSubject:"",
      selectedSubSubjects:[],
      parentTopicId: "",
      editorState: {
        stem: "",
        first: "",
        second: "",
        third: "",
        forth: "",
        explanation: "",
        solutionExplanation: "",
        correctChoice: "",
        level: "Easy",
      },
      hijriYear:[],
      cf_HijriYear: '',
  };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onSolutionInputChange = this.onSolutionInputChange.bind(this);
    this.onHintInputChange = this.onHintInputChange.bind(this);
    this.setLoading = this.setLoading.bind(this)
  }


  getHijriYear = () =>{
    const hijri=[];
    const currentYear = String(new Date().getUTCFullYear());
    const currentYearHijri = Number(moment(currentYear, 'YYYY').endOf('iYear').format('iYYYY'));
    for (let i=1434; i <= currentYearHijri; i +=1){
      hijri.push(i);
    }
    this.setState({hijriYear: hijri})
  };
  handleChangeYear = e =>{
    this.setState({
        cf_HijriYear : e.target.value
      });
  };

  async getSubjects(){

    const url = `${apiBaseUrl}/Content/MineSubjects`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const subjects = response.data.data;
        //extra bracket added to handle single object case 
        this.setState({
          ...this.state,
          subjects
        });
      });
  }


  handleTagsChange(tag,type,index = null){
    let newSelectedChildtopics = this.state.selectedChildtopics

    switch (type){
      case 'ADD':
        newSelectedChildtopics = [...newSelectedChildtopics,tag]
      break;
      case 'DELETE':
        newSelectedChildtopics = newSelectedChildtopics.filter(_tag => _tag.id !== tag.id)
      break
      default:
        break;
    }
    this.setState({...this.state, 
      selectedChildtopics : newSelectedChildtopics})
  }


  componentDidMount() {
    axios.post("https://www.wiris.net/demo/plugins/app/configurationjs");

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${apiBaseUrl}/subjects/parent_lookup`, { headers })
      .then((response) => {
        this.setState({
          ...this.state,
          parentTopics: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

      this.getSubjects();
    this.getHijriYear();
  }

  getTopicsList = (id) => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/subjects/${id}/ChildrenTopics`, { headers })
      .then((response) => {
        const arrayObj = response.data.data;
        const suggestionsArr = arrayObj.map((item) => {
          return {
            id: item.id,
            text: item.name,
          };
        });
        this.setState({
          ...this.state,
          suggestions: suggestionsArr,
        });
      });
  };

  onSolutionInputChange(file) {
    this.setState({
      solutionFile: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          solutionVideo: response.data.data.url,
          imageLoader: false,
        });
      })
      .catch((error) => {
        this.setState({ solutionFile: null, imageLoader: false });
      });
  }

  onHintInputChange(file) {
    this.setState({
      hintFile: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          hintVideo: response.data.data.url,
          imageLoader: false,
        });
      })
      .catch((error) => {
        this.setState({ hintFile: null, imageLoader: false });
      });
  }

  onFileInputChange(file) {
    this.setState({
      file: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({ imageUrl: response.data.data.url, imageLoader: false });
      })
      .catch((error) => {
        this.setState({ file: null, imageLoader: false });
      });
  }

  toggleHint(tab) {
    if (this.state.activeHintTab !== tab) {
      this.setState({
        activeHintTab: tab,
      });
    }
  }

  toggleInner(tab) {
    if (this.state.activeInnerTab !== tab) {
      this.setState({
        activeInnerTab: tab,
      });
    }
  }

  handleDelete(index) {
    this.setState({
      ...this.state,
      selectedChildtopics: [...this.state.selectedChildtopics.filter((_tag,_index)=> _index !== index)],
    });
  }

  handleAddition(tag) {
    this.setState({
      ...this.state,
      selectedChildtopics: [...this.state.selectedChildtopics, tag],
    });

    // this.setState((state) => ({ tags: [...state.tags, tag] }));
    // this.setState((state) => ({ topics: [...state.topics, tag.id] }));
  }

  setLoading(loading){
    this.setState({...this.state, loading})
  }

  myFormHandler = (values) => {
    const courseId = this.props.match.params.id;
    const temp = localStorage.getItem("editor");
    let examId = this.props.match.params.examId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setLoading(true)
    let data = {
      questionTopics: this.state.selectedSubSubjects?.length?this.state.selectedSubSubjects?.slice():null,
      duration: this.state.count,
      order: this.state.orderQuestion,
      parentTopicId: this.state.parentTopicId,
      cf_HijriYear:this.state.cf_HijriYear,
      level: this.state.level,
      explanationType: this.state.hintFile
        ? "Video"
        : this.state.editorState.explanation
        ? "Text"
        : null,

      explanationValue: this.state.hintFile
        ? this.state.hintVideo
        : this.state.editorState.explanation
        ? this.state.editorState.explanation
        : null,

      solutionExplanationType: this.state.solutionFile
        ? "Video"
        : this.state.editorState.solutionExplanation
        ? "Text"
        : null,

      solutionExplanationValue: this.state.solutionFile
        ? this.state.solutionVideo
        : this.state.editorState.solutionExplanation
        ? this.state.editorState.solutionExplanation
        : null,
      stem: this.state.editorState.stem,
      imageUrl: this.state.imageUrl,
      choiceA: this.state.editorState.first,
      choiceB: this.state.editorState.second,
      choiceC: this.state.editorState.third,
      choiceD: this.state.editorState.forth,
      correctChoice: this.state.correctChoice,
      subjectId:this.state.selectedSubject,
    };
    axios
      .post(`${apiBaseUrl}/questions`, data, { headers })
      .then((response) => {
        let questionData = {
          questionId: response.data.data.id,
        };
        axios
          .post(`${apiBaseUrl}/Exams/${examId}/Questions`, questionData, {
            headers,
          })
          .then((response) => {
            this.props.history.push(
              `/courses/${courseId}/exams/details/${examId}`
            );
            this.setLoading(false)
          })
          .catch((error) => {
            switch (error.response.data && error.response.data.error) {
              case "ExamIsLockedError":
                swal("عفواً", "لا يمكن إضافة أسئلة لهذا الامتحان", "error", {
                  button: "متابعة",
                });
                break;
              case "QuestionAlreadyAddedError":
                swal("عفواً", "السـؤال مضاف سابقاً", "error", {
                  button: "متابعة",
                });
                break;
              case "ClientError":
                swal("عفواً", "حصل خطأ ما", "error", {
                  button: "متابعة",
                });
                break;
              case "AccessDeniedError":
                swal("عفواً", "لا يوجد لديك صلاحية لإضافة سؤال", "error", {
                  button: "متابعة",
                });
                break;
              case "NotFoundError":
                swal("عفواً", "لم يتم العثور على السؤال/الامتحان", "error", {
                  button: "متابعة",
                });
                break;
              default:
                console.log("other error");
            }
          });
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message
        const mappedError =  errorsMapper(errorMsg)
        swal("عفواً",mappedError, "error", {
          button: "متابعة",
        });
      }).finally(()=>{
        this.setLoading(false)
      });
  };

  async fetchChildTopics(){
    
    const url = `${apiBaseUrl}/Content/ChildrenTopics/${this.state.parentTopicId}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const childTopics = response.data.data;
        this.setState({
          ...this.state,
          childTopics
        });
      }).catch(error=>console.log({error}));
  }

  rerenderTopics(){
    this.setState({topicShow:false},()=>{
      this.setState({topicShow:true})
    })
  }

  handleTopicChange = ({ currentTarget: input }) => {
    const parentTopicId = input.value;
    //remember to clear selected tags on main topic change
    this.setState({...this.state, parentTopicId},()=> this.fetchChildTopics());
  };

  handleSubSubjectChange = ({ currentTarget: input }) => {
    const subSubjectId = input.value;
    //remember to clear selected tags on main topic change
    this.setState({...this.state, selectedSubSubjects:[...this.state.selectedSubSubjects,subSubjectId]});
  };

  handleEditorDataChange = (code, value) => {
    this.setState({
      editorState: {
        ...this.state.editorState,
        [code]: value,
      },
    });
  };

  handleOneTopic(topics=[]){
    const [firstTopic] = topics
    this.setState({...this.state, parentTopicId:firstTopic.id,childTopics : [] },()=> this.fetchChildTopics());
  }

  async fetchTopics(){
    const url = `${apiBaseUrl}/Content/Topics/${this.state.selectedSubject}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.rerenderTopics()

    axios
      .get(url, { headers })
      .then((response) => {
        const topics = response.data.data;
        this.setState({
          ...this.state,
          topics
        });
      }).catch(error=>console.log({error}));
    
  }

  handleSubjectChange({currentTarget : {value}}){
    this.setState({...this.state, selectedSubject : value,parentTopicId : null,selectedChildtopics : [] },()=> this.fetchTopics());
    
  }


  formatNames(array = [],nameProp = 'name'){
    return array.map(elem => ({...elem,text : elem?.name}))
  }
  componentDidUpdate(prevProps,prevState){
    const prevSelectedSubject =prevState.selectedSubject;
    const currentSelectedsubject =this.state.selectedSubject;
    const url = `${apiBaseUrl}/Questions/GetSubjectTopics?subjectId=${currentSelectedsubject}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    if(prevSelectedSubject!==currentSelectedsubject){
      axios({
        method:'get',
        url,
        headers,
      }).then(res=>this.setState({subSubjects:res.data?.data})).catch(error=>this.setState({subSubjects:[]}));
    }
  }
  render() {
    const { tags, suggestions } = this.state;
    const { handleSubmit } = this.props;
    const order = Number(this.props.location.search.replace('?order=',''));
     return (
      <React.Fragment>
        <div className="container">
          <form onSubmit={handleSubmit(this.myFormHandler)}>
            <div className="question-item">
              <div className="row pb-2">
                <div className="col-12 d-flex align-items-center mb-2 justify-content-between">
                  <h6 className="light-text mt-0 mb-0">اضافة سؤال</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <h6 className="dark-text">ترتيب السؤال</h6>
              <div className="d-flex align-items-center flex-row counter-input mt-3">
                {/* <CounterInput
                    min={1}
                    onCountChange={(order) => this.setState({ orderQuestion: order })}
                    inputStyle={{
                      fontFamily: "MontserratMedium",
                    }}
                    count={order}
                /> */}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                <h6 className="dark-text">السؤال</h6>

                <Editor
                  code={"stem"}
                  editorData={this.state.editorState.stem}
                  onChange={this.handleEditorDataChange}
                />
              </div>
              <div className="col-6 mt-3">
                <h6 className="dark-text">الإجابة الأولى</h6>
                <Editor
                  code={"first"}
                  editorData={this.state.editorState.first}
                  onChange={this.handleEditorDataChange}
                />
              </div>
              <div className="col-6 mt-3">
                <h6 className="dark-text">الإجابة الثانية</h6>

                <Editor
                  code={"second"}
                  editorData={this.state.editorState.second}
                  onChange={this.handleEditorDataChange}
                />
              </div>
              <div className="col-6 mt-3">
                <h6 className="dark-text">الإجابة الثالثة</h6>
                <Editor
                  code={"third"}
                  editorData={this.state.editorState.third}
                  onChange={this.handleEditorDataChange}
                />
              </div>
              <div className="col-6 mt-3">
                <h6 className="dark-text">الإجابة الرابعة</h6>
                <Editor
                  code={"forth"}
                  editorData={this.state.editorState.forth}
                  onChange={this.handleEditorDataChange}
                />
              </div>
              <div className="col-md-12 mt-3">
                <h6 className="dark-text">الخيار الصحيح</h6>

                <Field
                  component={RadioField}
                  onClick={(value) =>
                    this.setState({...this.state,
                      correctChoice: value,
                    })
                  }
                  value={this.state.correctChoice}
                  name="correctChoice"
                  options={[
                    { title: "الإجابة الأولى", value: "A" },
                    { title: "الإجابة الثانية", value: "B" },
                    { title: "الإجابة الثالثة", value: "C" },
                    { title: "الإجابة الرابعة", value: "D" },
                  ]}
                />
              </div>
              <div className="col-md-12 mt-3">
                <h6 className="dark-text">صورة توضيحية</h6>
                <label htmlFor="uploadImage" className="clickable w-100">
                  <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mb-3">
                    {this.state.file ? (
                      <React.Fragment>
                        {!this.state.imageLoader && (
                          <img
                            src={
                              this.state.file ||
                              process.env.PUBLIC_URL +
                                "/assets/images/upload.png"
                            }
                            className="contain-img"
                            width="100%"
                          />
                        )}
                        {this.state.imageLoader && (
                          <Loader type="ball-clip-rotate" />
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/upload.png"
                          }
                          height="30"
                          width="30"
                          className="contain-img clickable"
                        />
                        <p className="dark-silver-text small mb-0">أرفق صورة</p>
                      </React.Fragment>
                    )}
                  </div>
                </label>
                <Field
                  component={FileInput}
                  name="imageUrl"
                  className="d-none"
                  id="uploadImage"
                  onChange={this.onFileInputChange}
                />
              </div>
              <div className="col-md-12  mb-3">
                <label className="dark-text small mb-0 mt-0">السنة هجري</label>
                <select
                    value={this.state.cf_HijriYear}
                    onChange={this.handleChangeYear}
                    className="form-select w-100 p-2 small dark-text border border-light-2"
                    aria-label="Default select example"
                >
                  <option value=''>اختر السنة</option>

                  {this.state.hijriYear.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <h6 className="dark-text">الموضوع</h6>
                <select
                  className="form-control"
                  name="subject"
                  onChange={(e) => {
                    this.handleSubjectChange(e);
                  }}
                  value={this.state.selectedSubject}
                >
                  <option value="" disabled selected>
                    الرجاء الاختيار
                  </option>
                  {this.state?.subjects?.length && this.state?.subjects.map((subject,index) => (
                    <option key={subject.id} value={subject.id}  >
                      {subject.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6">
                <h6 className="dark-text">المفهوم الأساسي</h6>
               {this.state.topicShow && <select
                  className="form-control"
                  disabled={!this.state.selectedSubject}
                  value={this.state.parentTopicId}
                  onChange={(e) => {
                    this.handleTopicChange(e);
                  }}
                >
                  <option selected disabled  value="">
                    الرجاء الاختيار
                  </option>
                  {this.state?.topics?.map((topics) => (
                    <option key={topics.id} value={topics.id}>
                      {topics.name}
                    </option>
                  ))}
                </select>}
              </div>

              {/* <div className="col-md-6">
                <h6 className="dark-text">المفاهيم الفرعية</h6>
                <ReactTags
                disabled={!this.state?.childTopics?.length}
                  tags={this.state.selectedChildtopics}
                  suggestions={this.formatNames(this.state.childTopics)}
                  handleDelete={this.handleDelete}
                  handleAddition={this.handleAddition}
                  delimiters={delimiters}
                  inputFieldPosition="top"
                  placeholder="اختر المفهوم.."
                />
              </div> */}
              <div className="col-md-6">
                <h6 className="dark-text">المفاهيم الفرعية</h6>
                <select
                  className="form-control"
                  name="subject"
                  onChange={(e) => {
                    this.handleSubSubjectChange(e);
                  }}
                  value={this.state.selectedSubject}
                >
                  <option value="" disabled selected>
                    الرجاء الاختيار
                  </option>
                  {this.state?.subSubjects?.length && this.state?.subSubjects.map((subSubject,index) => (
                    <option key={subSubject.id} value={subSubject.id}  >
                      {subSubject.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <h6 className="dark-text">درجة الصعوبة</h6>

                <Field
                  component={RadioField}
                  name="level"
                  options={[
                    { title: "سهل", value: "Easy" },
                    { title: "متوسط", value: "Medium" },
                    { title: "صعب", value: "Hard" },
                  ]}
                  onClick={(value) =>
                    this.setState({
                      ...this.state,
                      level: value,
                    })
                  }
                  value={this.state.level}
                />
              </div>
              <div className="col-md-6 mt-3">
                <h6 className="dark-text">الوقت</h6>

                <div className="d-flex align-items-center flex-row counter-input mt-3">
                  {/* <CounterInput
                    min={30}
                    onCountChange={(count) => this.setState({...this.state, count: count })}
                    inputStyle={{
                      fontFamily: "MontserratMedium",
                    }}
                    count={30}
                  /> */}
                  <h6 className="dark-text small mb-0 ml-3">
                    ثانية لحل السؤال
                  </h6>
                </div>
              </div>
              <div className="col-12 mt-3">
                <h6 className="dark-text">مساعدة</h6>
                <TabPane tabId="3">
                  <Nav tabs className="answer-tabs">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeHintTab === "6",
                        })}
                        onClick={() => {
                          this.toggleHint("6");
                        }}
                      >
                        كتابة طريقة المساعدة للسؤال
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeHintTab === "7",
                        })}
                        onClick={() => {
                          this.toggleHint("7");
                        }}
                      >
                        إضافة المساعدة بفيديو
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeHintTab}>
                    <TabPane tabId="6">
                      <div
                        className="box-layout dashed-border
                       align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                      >
                        <Editor
                          code={"explanation"}
                          editorData={this.state.editorState.explanation}
                          onChange={this.handleEditorDataChange}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      <Col sm="12">
                        <label htmlFor="hintVideo" className="clickable w-100">
                          <div
                            className="d-flex box-layout dashed-border
                             align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                          >
                            {this.state.hintFile ? (
                              <React.Fragment>
                                {!this.state.imageLoader && (
                                  <video
                                    src={
                                      this.state.hintFile ||
                                      process.env.PUBLIC_URL +
                                        "/assets/images/upload.png"
                                    }
                                    className="contain-img"
                                    width="100%"
                                    controls
                                  />
                                )}
                                {this.state.imageLoader && (
                                  <Loader type="ball-clip-rotate" />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/upload.png"
                                  }
                                  height="30"
                                  width="30"
                                  className="contain-img clickable"
                                />
                                <p className="dark-silver-text small mb-0">
                                  اضف طريقة المساعدة عبر فيديو
                                </p>
                              </React.Fragment>
                            )}
                          </div>
                        </label>

                        <Field
                          component={FileInput}
                          name="hintVideo"
                          className="d-none"
                          id="hintVideo"
                          onChange={this.onHintInputChange}
                        />
                      </Col>
                    </TabPane>
                  </TabContent>
                </TabPane>
              </div>
              <div className="col-12 mt-3">
                <h6 className="dark-text">طريقة الحل</h6>
                <TabPane tabId="4">
                  <Nav tabs className="answer-tabs">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeInnerTab === "4",
                        })}
                        onClick={() => {
                          this.toggleInner("4");
                        }}
                      >
                        كتابة طريقة حل السؤال
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeInnerTab === "5",
                        })}
                        onClick={() => {
                          this.toggleInner("5");
                        }}
                      >
                        إضافة الحل بفيديو
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeInnerTab}>
                    <TabPane tabId="4">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className=" box-layout dashed-border 
                          align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                          >
                            <Editor
                              code={"solutionExplanation"}
                              editorData={
                                this.state.editorState.solutionExplanation
                              }
                              onChange={this.handleEditorDataChange}
                            />
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <Col sm="12">
                        <label
                          htmlFor="solutionVideo"
                          className="clickable w-100"
                        >
                          <div
                            className="d-flex box-layout dashed-border
                             align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                          >
                            {this.state.solutionFile ? (
                              <React.Fragment>
                                {!this.state.imageLoader && (
                                  <video
                                    src={
                                      this.state.solutionFile ||
                                      process.env.PUBLIC_URL +
                                        "/assets/images/upload.png"
                                    }
                                    className="contain-img"
                                    width="100%"
                                    controls
                                  />
                                )}
                                {this.state.imageLoader && (
                                  <Loader type="ball-clip-rotate" />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/upload.png"
                                  }
                                  height="30"
                                  width="30"
                                  className="contain-img clickable"
                                />
                                <p className="dark-silver-text small mb-0">
                                  اضف طريقة الحل عبر فيديو
                                </p>
                              </React.Fragment>
                            )}
                          </div>
                        </label>
                        <Field
                          component={FileInput}
                          name="solutionVideo"
                          className="d-none"
                          id="solutionVideo"
                          onChange={this.onSolutionInputChange}
                        />
                      </Col>
                    </TabPane>
                  </TabContent>
                </TabPane>
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <button disabled={this.state.loading} className="btn light-btn w-100">
                    {
                      this.state.loading ?
                       "يتم الحفظ..."
                       : "حفظ"
                    }
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    formValues: state.form.Question && state.form.Question.values,
  };
}

CreateExam = reduxForm({
  form: "Question",
  validate,
})(CreateExam);

CreateExam = connect(mapStateToProps)(CreateExam);

export const Question = withRouter(CreateExam);
