import React, { Component } from "react";
import "../styles.sass";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";
import ZoomMeeting from "./ZoomMeeting";
import { connect } from "react-redux";

var moment = require("moment-hijri");
moment().format("iYYYY/iM/iD");

class LiveLectures extends Component {
  constructor(props) {
    super(props);
    this.state = { details: [],
      isPageLoading: false
     };
  }

  componentDidMount() {
    this.setState({ isPageLoading: true });
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${apiBaseUrl}/content/${courseId}/content`, { headers })
      .then(response => {
        this.setState({ details: response.data.data, isPageLoading: false });
      })
      .catch(error => {
        this.setState({ isPageLoading: false });
        console.log(error);
      });
  }

  renderLiveLectures() {
    const LiveLectures = this.state.details.lectures;
     const sortedLiveLectures = LiveLectures.sort((a, b) =>
     new Date(a.scheduledAt).getTime() > new Date(b.scheduledAt).getTime()? 1 : -1
    );
    if (sortedLiveLectures) {
      return sortedLiveLectures.map((LiveLecture, index) => (
        <React.Fragment key={index}>
          <div className="row no-gutters">
            <div className="col-12">
              <div className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 mb-4">
                {this.renderLectur(LiveLecture)}
              </div>
            </div>
          </div>
        </React.Fragment>
      ));
    }
  }

  renderLectur(lecture) {
    if (lecture) {
        
        const scheduledAt = new Date(lecture.scheduledAt);
        var time = scheduledAt.toLocaleTimeString().replace("AM", "صباحاً").replace("PM", "مساء");
        var day = scheduledAt.getDate();
        var month = scheduledAt.getMonth() + 1;
        var year = scheduledAt.getFullYear();
        // var scheduledDate = year + "-" + month + "-" + day;
        var scheduledDate = year + "," + month + "," + day;
        var hijriDate = moment(scheduledAt).format("iYYYY/iM/iD");
        
        const nowTime = new Date();
        const endShowingtButton = nowTime.getDate() ===  scheduledAt.getDate()
        const startShowingButton = 
          (scheduledAt.getTime() - nowTime.getTime()) < 15*60*1000

          const meetingNumber=lecture.meetingId
          const passWord=lecture.meetingPassword
          const accountKey=lecture.zoomAccountKey
          const accountSecret=lecture.zoomAccountSecret
          const text="ابدأ المحاضرة"
          const userName= this.props.user ? this.props.user.name : "user"

          const lectureStart = 
            scheduledAt.getTime() - nowTime.getTime()<=0
        return (
          <div className="col-lg-6 col-xl-4">
            <div className="card card-sm card-md shadow-sm border-0 position-relative">
              <header className="card-thumb light-border-bottom" >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/liveIcon.png"}
                  alt="Course_image"
                  style={{objectFit: "contain", height: "100px"}}
                />
              </header>
              {/* {
                (startShowingButton && endShowingtButton)? (
                  <button
                    onClick={()=>window.open(lecture.broadcastUrl, "_blank")}
                    className={`btn light-btn ${scheduledAt.getTime() - nowTime.getTime()<=0? "light-btn-flash":""} `}
                    style={{top: "2px", left: 0}}
                  >
                      ابدأ المحاضرة
                  </button>
                ) : null
              } */}
               {
                (startShowingButton && endShowingtButton)? (
                  <ZoomMeeting
                    meetingNumber={meetingNumber}
                    passWord={passWord}
                    accountKey={accountKey}
                    accountSecret={accountSecret}
                    text={text}
                    userName={userName}
                    lectureStart={lectureStart}
                    parent="LiveLectures"
                  />
                ) : null
              }
              <div className="card-body">
                <div className="d-flex justify-content-center flex-column">
                  <h6 className="card-title small mid-text pb-2">
                    {lecture.nameAr}
                  </h6>
                  <ul className="list-inline mb-0 d-flex align-items-center">
                    <li className="list-inline-item light-font-text smaller dark-text d-inline-flex align-items-center mr-4">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/calendar.png"
                        }
                        height="12"
                        width="12"
                        className="mr-1"
                        alt=""
                      />
                      <div className="d-flex flex-column">
                        <span className="en-text mr-1">{hijriDate}</span>
                        <span className="en-text mr-1">{scheduledDate}</span>
                      </div>
                    </li>
                    <li className="list-inline-item light-font-text smaller dark-text en-text d-inline-flex align-items-center">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/quarters.png"
                        }
                        height="12"
                        width="12"
                        className="mr-1"
                        alt=""
                      />
                      <span className="en-text">{time}</span>
                    </li>
                  </ul>
                </div>
              </div>
             
            </div>
              {/* Zoom Meeting SDK Component View Rendered Here  */}
           <div id="meetingSDKElement">
            </div>
          </div>
        );
    }
  }

  render() {
    return (
      <>
      {this.state.isPageLoading ? (
        <div
        className="silver-bg box-layout w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
        style={{ minHeight: 350 }}
      >
        <Loader type="ball-spin-fade-loader" className="dark-loader" />
      </div>
      ) : (
      <React.Fragment>
        {this.state.details.lectures == undefined ||
        this.state.details.lectures.length == 0 ? (
          <React.Fragment>
            <div
              className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center"
              style={{ height: 300 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/empty-videos.png"}
                height="90"
                className="contain-img mb-3"
                alt=""
              />
              <p className="dark-text mt-0 mb-0">لا يوجد محاضرات مباشرة متاحة</p>
            </div>
          </React.Fragment>
        ) : (
          this.renderLiveLectures()
        )}
      </React.Fragment>
      )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user

  }
}

LiveLectures = connect(mapStateToProps)(LiveLectures)
export default LiveLectures


