import React, { Component } from "react";
import { apiBaseUrl } from "../../api/helpers";
import { Helmet } from "react-helmet";
import swal from "@sweetalert/with-react";
import Loader from "react-loaders";
import { Api } from "../../api";
import axios from "axios";
import { Link } from "react-router-dom";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.sass";

export class CategoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      categoryGroups: []
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    axios
      .get(`${apiBaseUrl}/categories/${params.slug}`)
      .then(response => {
        this.setState({ details: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get(
        `${apiBaseUrl}/CategoryGroups?categoryId=${this.props.location.state.catId}`
      )
      .then(response => {
        this.setState({ categoryGroups: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }

  categoryGroupRedirection(CategoryGroup) {
    const {
      match: { params }
    } = this.props;
    let token = localStorage.getItem("token");
    if (token) {
      this.props.history.push(
        `/categories/details/${params.slug}/quick-questions/${CategoryGroup}`
      );
    } else {
      swal(
        "عفواً",
        "يجب عليك تسجيل الدخول/تسجيل حساب حتى تتمكن من القيام بهذه الخطوة",
        "error",
        {
          button: "متابعة"
        }
      );
    }
  }

  renderCategoryGroups() {
    return this.state.categoryGroups.map(group => (
      <React.Fragment>
        <div className="col-md-2">
          <div
            className="shadow-box d-flex flex-column align-items-center justify-content-center clickable"
            key={group.id}
            onClick={() => this.categoryGroupRedirection(group.id)}
          >
            <h6 className="dark-text mb-1">{group.name}</h6>
            <p className="dark-text smaller mb-0 text-break text-center">
              {group.description}
            </p>
          </div>
        </div>
      </React.Fragment>
    ));
  }

  render() {
    let token = localStorage.getItem("token");
    const {
      match: { params }
    } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${this.state.details.nameAr} | منصّة همّة التعليمية`}</title>
          <meta name="description" content={this.state.details.descriptionAr} />
        </Helmet>
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="half-circle-border d-flex justify-content-center align-items-center mx-auto">
                  <img
                    src={this.state.details && this.state.details.icon}
                    height="50"
                    width="50"
                    className="mt-3 contain-img"
                    alt={this.state.details.nameAr}
                  />
                </div>
                <h5 className="dark-text mt-3">{this.state.details.nameAr}</h5>
                <p className="dark-text mt-2 small w-40 mx-auto">
                  {this.state.details.descriptionAr}
                </p>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-12 text-center">
                <h3 className="dark-text">المجموعات</h3>
                <p className="dark-silver-text">
                  بنقدملكم مجموعة من الأسئلة السريعة
                </p>
              </div>
            </div>
            <div className="row pt-3">{this.renderCategoryGroups()}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
