import React from "react";
import Loader from "react-loaders";

export default function LoadMore({
  loading,
  onClick = () => {},
  hasReachedEnd,
}) {
  return (
    <div
      className="d-flex align-items-center justify-content-center w-25 btn dark-btn unset-height mx-auto"
      onClick={onClick}
    >
      {loading ? <Loader type="ball-clip-rotate" /> : "تحميل المزيد"}
    </div>
  );
}
