import React, { Component } from "react";
import { Login } from "../login/login";
import { NavLink, Route } from "react-router-dom";
import "./styles.sass";
import swal from "@sweetalert/with-react";
import { loginWithTwitter } from "./firebase";

export class Auth extends Component {
  twitterLogin = async () => {
    loginWithTwitter()
      .then(token => {
        localStorage.setItem("token", token);
        window.location = "/";
      })
      .catch(error => {
        swal("عفواً", "هذا المستخدم غير موجود", "error", {
          button: "متابعة"
        });
        this.props.history.push("/auth/register");
      });
  };
  render() {
    return (
      <div className="container pt-5 pb-5">
        <div
          className="row align-items-center justify-content-center"
          style={{ minHeight: 550 }}
        >
          <div className="col-md-6 col-12 order-md-1 order-2">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/login-artwork.png"}
              height="350"
              className="artwork-img"
            />
          </div>
          <div className="col-md-6 col-12 order-md-2 order-1">
            <ul className="list-inline underlined-tabs mb-4 text-center">
              <li className="list-inline-item small">
                <NavLink
                  href=""
                  className="light-text"
                  activeClassName="active"
                  to="/auth/login"
                >
                  تسجيل دخول
                </NavLink>
              </li>
            </ul>

            <Route
              exact
              path="/auth/login"
              render={props => <Login {...props} />}
            />
          </div>
        </div>
      </div>
    );
  }
}
