import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import swal from "@sweetalert/with-react";
import { MdLockOutline } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { inputField } from "../shared/inputs/inputField";
import { phoneField } from "../shared/inputs/phoneField";
import { withRouter } from "react-router-dom";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import { loginAction, loginFailed } from "../../actions/login.actions";
import CryptoJS from "react-native-crypto-js";

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "يجب تعبئة هذه الخانة";
  }
  if (!values.email) {
    errors.email = "يجب تعبئة هذه الخانة";
  }
  return errors;
};

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
      password: "",
      loading: false,
      isChecked: false,
    };
    this.togglePasswordShow = this.togglePasswordShow.bind(this);
    this.onChangeCheckbox=this.onChangeCheckbox.bind(this);
  }
  componentDidMount() {
    const checked= localStorage.getItem('checkbox');
    if (checked ) {
        this.setState({
            isChecked: true,
        })
        let bytes  = CryptoJS.AES.decrypt(localStorage.getItem('account'), 'secret key 123');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        {this.myFormHandler(decryptedData)}
       
    }
}
  togglePasswordShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  myFormHandler = values => {
    const request = this.props.loginAction({
      email: values.email,
      password: values.password
    });
    this.setState({ loading: true });

    request
      .then(action => {
        this.setState({ loading: false });
        this.props.history.push("/");
        //save data
        if (this.state.isChecked) {
          let storedobj=  JSON.stringify(values);
          let ciphertext = CryptoJS.AES.encrypt(storedobj, 'secret key 123').toString();
          localStorage.setItem('account',ciphertext);
          localStorage.setItem('checkbox',this.state.isChecked);  
           }
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.loginFailed(error);

        switch (error?.response?.data && error?.response?.data?.error) {
          case "Unauthorized":
            swal("عفواً", "يرجى التحقق من البيانات المدخلة", "error", {
              button: "متابعة"
            });
            break;

          default:
            swal("عفواً", "حدث خطأ ما", "error", {
              button: "متابعة"
            });
        }
      });
  };
  onChangeCheckbox (e){
    this.setState({
      isChecked : e.target.checked
    })
  }
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form className="centered" onSubmit={handleSubmit(this.myFormHandler)}>
        <Field
          name="email"
          type="email"
          component={inputField}
          className="form-control border-left-0 pl-0 ltr-input"
          placeholder="البريد الإلكتروني"
        >
          <FaRegEnvelope />
        </Field>

        <div className="position-relative">
          <Field
            name="password"
            type={this.state.hidden ? "text" : "password"}
            component={inputField}
            className="form-control border-left-0 pl-0 ltr-input pw-input"
            placeholder="كلمة المرور"
          >
            <MdLockOutline />
          </Field>
          {this.state.hidden ? (
            <img
              src={process.env.PUBLIC_URL + "/assets/images/closed-eye.png"}
              width="100%"
              width="20"
              className="position-absolute left-input-icon"
              onClick={this.togglePasswordShow}
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/assets/images/eye.png"}
              width="100%"
              width="20"
              className="position-absolute left-input-icon custom-top"
              onClick={this.togglePasswordShow}
            />
          )}
        </div>
        <div>
          <input type="checkbox" className="mx-2" checked={this.state.isChecked} name="lsRememberMe" onChange={this.onChangeCheckbox} />
          <label>تذكرني</label>
        </div>
        <button
          type="submit"
          className="btn dark-outline-btn w-100 justify-content-center d-flex align-items-center"
          disabled={submitting}
        >
          {this.state.loading == true ? (
            <Loader type="ball-clip-rotate" />
          ) : (
            "تسجيل الدخول"
          )}
        </button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.Login && state.form.Login.values,
    phoneNumberConfirmed: state.auth.phoneNumberConfirmed,
    authenticated: state.auth.authenticated
  };
}

LoginComponent = reduxForm({
  form: "Login",
  validate
})(LoginComponent);

LoginComponent = connect(
  mapStateToProps,
  { loginAction, loginFailed }
)(LoginComponent);

export const Login = withRouter(LoginComponent);
