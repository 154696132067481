import React from 'react'
import { usePermissions } from './../../../../hooks/usePermissions';
import { PERMISSION_ENUM } from './../../../../enum/permissions';
export const ExamDelete = ({onClick = ()=>{}})=>{

    const [checkAuth] = usePermissions()
    const authed = checkAuth(PERMISSION_ENUM.ExamsDelete)

    if(!authed) return null
    
    return(
        <img
        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
        height="22"
        width="22"
        className="contain-img ml-1 mr-2 clickable"
        onClick={onClick}
      />
    )
}