import React from 'react'
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";


export const SendingRequest = ({message})=> {
    return(
      <div
        className=" w-100 p-3 d-flex justify-content-center align-items-center
        "
      >
        <p className="mt-10 dark-text mr-1">{message}</p>
        <Loader
          type="ball-beat"
          className="dark-loader"
        />
      </div>
    )
  }