import React, { Component } from "react";
import "react-sweet-progress/lib/style.css";
import { CommentsList } from "./comments/comments-list";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../api/helpers";
import { setQuestionDetailsId } from "../../../../actions/index";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import swal from "sweetalert";
import CustomTooltip from "./custom-tooltip/custom-tooltip";
import { connect } from "react-redux";
import SendingModal from "../sharedComponents/SendingModal";

class AskQuestionDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.finishDiscussion = this.finishDiscussion.bind(this)
    this.openDiscussion = this.openDiscussion.bind(this)
    this.setQuestionPrivacy = this.setQuestionPrivacy.bind(this)
    this.confirmSettingQuestionPrivacy = this.confirmSettingQuestionPrivacy.bind(this)

  }
  state = {
    details: [],
    showEditQuestionForm: false,
    questionValue: "",
    questionImage: "",
    questionType: "",
    disabledSubmit: false,
    sendingFinishRequest: false,
    askQuestionStatus: "",
    isPrivate: true,
    discussionCompleteBy: "",
    isDiscussionCompleteByStudent: true,
    discussionCompleteDate: "",
    nextId: "",
    prevId: "",
    isPageLoading: false,
    isSending: false,
    message: "",
  };

  getQuestionDetails() {
    this.props.setQuestionDetailsId(this.props.match.params.questionId, this.props.match.params.id)
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    // const url = `${apiBaseUrl}/AskQuestions/${this.props.match.params.questionId}`
    const url = `${apiBaseUrl}/AskQuestions/GetAskQuestionPagination/${this.props.match.params.questionId}`
    this.setState({isPageLoading: true})
    axios
      .get(url, {
        headers,
      })
      .then((response) => {
        this.setState({
          details: response.data.data,
          questionValue: (response.data.data.type === "Both" || response.data.data.type === "Text") && response.data.data.content,
          questionImage: response.data.data.type === "Both" && response.data.data.imageUrl,
          questionType: response.data.data.type,
          askQuestionStatus: response.data.data.askQuestionStatus,
          isPrivate: response.data.data.isPrivate,
          nextId: response.data.data.next,
          prevId: response.data.data.previous,
          isPageLoading: false,
          isDiscussionCompleteByStudent: response.data.data.isDiscussionCompleteByStudent,
          discussionCompleteDate: response.data.data.discussionCompleteDate,
        });
      })
      .catch((error) => {
        this.setState({isPageLoading: false,})
        console.log(error);
      });
  }

  componentDidMount() {
    this.getQuestionDetails()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.questionId !== this.props.match.params.questionId){
      this.getQuestionDetails()
    }
  }

  toggleEditQuestionForm() {
    this.setState({
      showEditQuestionForm: !this.state.showEditQuestionForm,
      questionValue: this.state.details && this.state.details.content,
      questionImage: this.state.details && this.state.details.imageUrl,
      questionType: this.state.details && this.state.details.type,
    });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value,});
    if(this.state.questionType === "Text"){
      this.setState({ disabledSubmit: value ? false : true });
    }
  };

  handleEditQuestionSubmit = (event) => {
    event.preventDefault();
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    this.setState({isSending: true, message: "جاري تعديل السؤال",})
    let data = null

    
    if(this.state.questionType === "Both"){
      if(this.state.questionValue){
        data = {
          type: "Both",
          content: this.state.questionValue,
          imageurl: this.state.questionImage,
          n: 1
        };
      }
      else{
        data = {
          type: "Image",
          content: this.state.questionImage,
        };
      }
    }
    else{
      data = {
        type: "Text",
        content: this.state.questionValue,
      };
    }

    axios
    .put(`${apiBaseUrl}/AskQuestions?id=${this.state.details.id}`, data, {
      headers,
    })
    .then((response) => {
      this.setState({
        details: response.data.data,
        questionValue: (response.data.data.type === "Both" || response.data.data.type === "Text") && response.data.data.content,
        questionImage: response.data.data.type === "Both" && response.data.data.imageUrl,
        questionType: response.data.data.type,
        isSending: false,
        message: "",
      });
      swal("تم تعديل السؤال بنجاح !", {
        icon: "success",
        button: "متابعة",
        timer: 3000
      });

      this.toggleEditQuestionForm()
    })
    .catch((error) => {
      this.setState({isSending: false, message: "",})
      this.toggleEditQuestionForm()
      console.log(error);
      swal("لم يتم تعديل السؤال !", {
        icon: "error",
        button: "متابعة",
        timer: 4000
      });
    });

  }

  onDelete(id) {
    let courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({isSending: true, message: "جاري حذف السؤال",})
    axios
      .delete(`${apiBaseUrl}/AskQuestions?id=${id}`, {
        headers,
      })
      .then(() => {
        this.setState({isSending: false, message: "",})
        swal("تم حذف السؤال بنجاح !", {
          icon: "success",
          button: "متابعة",
          timer: 3000
        });  
        this.props.history.push(`/courses/${courseId}/askQuestions`);
      })
      .catch((error) => {
        swal("لم حذف تعديل السؤال !", {
          icon: "error",
          button: "متابعة",
          timer: 3000
        });
        console.log(error);
      });
  }

  finishDiscussion(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({sendingFinishRequest: true})
    axios
    .post(`${apiBaseUrl}/AskQuestions/QuesstionDiscussionComplete?id=${id}`,
    {data: ""}, 
    {headers})
    .then(response=>{
      axios
      .get(`${apiBaseUrl}/AskQuestions/GetAskQuestionPagination/${this.props.match.params.questionId}`, {
        headers,
      })
      .then(response=>{
        this.setState({
          askQuestionStatus: response.data.data.askQuestionStatus,
          isDiscussionCompleteByStudent: response.data.data.isDiscussionCompleteByStudent,
          discussionCompleteDate: response.data.data.discussionCompleteDate,
          sendingFinishRequest: false, 
          // askQuestionStatus: response.data = true? "Finished" : this.state.askQuestionStatus
        })

      })
      .catch(error=>{
        this.setState({sendingFinishRequest: false})
        // swal(" رجاء عاود المحاولة","أو تواصل مع خدمة العملاء لحدوث خطأ في كود","error")
        console.log(error);
      })
    })
    .catch(error=>{
      this.setState({sendingFinishRequest: false})
      // swal(" رجاء عاود المحاولة","أو تواصل مع خدمة العملاء لحدوث خطأ في كود","error")
      console.log(error);
    })
  }

  openDiscussion() {
    this.setState({askQuestionStatus: "UnderDiscussion"})
  }

  confirmSettingQuestionPrivacy(id, status) {
    if(this.state.isPrivate === status) return null;

    swal({
      title: status? "هل أنت متأكد من إلغاء نشر السؤال؟" : "هل أنت متأكد من نشر السؤال؟",
      text: status? "سيتم حجب السؤال بتعليقاته لباقي طلاب الدورة" : "سيتم ظهور السؤال بتعليقاته لطلاب الدورة",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
    })
    .then((value) => {
      switch (value) {
        case "ok":
          this.setQuestionPrivacy(id)
          break;
        default:
          return;
      }
    });
  }

  setQuestionPrivacy(id) {
    this.setState({isSending: true, message: "جاري تغيير خصوصية السؤال",})
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
    .post(`${apiBaseUrl}/AskQuestions/UpdateQuesstionisPrivate?id=${id}`,
    {data: ""}, 
    {headers})
    .then(response=>{
      this.setState({
        isPrivate: !this.state.isPrivate, 
        isSending: false, 
        message:"",
      })
      swal("تم تغيير الخصوصية بنجاح!", {
        buttons: false,
        icon: "info",
        timer: 2000,
      });
    })
    .catch(error=>{
      this.setState({
        isSending: false, 
        message:"",
      })
      swal("عفواً", "لم يتم تغيير الخصوصية برجاء إعادة المحاولة ", "error", {
        button: "متابعة",
      });
      console.log(error);
    })

  }

  render() {
    const style = {pointerEvents: this.state.disabledSubmit? "none" : "auto"}

    let discussionCompleteDate;
    let discussionCompleteTime ;

    if(this.state.discussionCompleteDate){
      const d = new Date();

      const dateMillisecondsWithTimezoneOffset = 
      new Date(this.state.discussionCompleteDate).getTime() - 
      (d.getTimezoneOffset()*60*1000)
  
      const dateWithTimezoneOffset = d.setTime(dateMillisecondsWithTimezoneOffset);

      discussionCompleteDate = 
        new Date(dateWithTimezoneOffset).getDate() + "-" +
        (Number(new Date(dateWithTimezoneOffset).getMonth()) +1) + "-" +
        new Date(dateWithTimezoneOffset).getFullYear()

        let hours = new Date(dateWithTimezoneOffset).getHours()
        const am_or_pm = hours>=12? "مساءً":"صباحاً"
        hours = 
          hours>12? (hours-12>9? hours-12 : "0" + (hours-12)) : 
          (hours>9? hours:"0"+(hours))
        let minutes = new Date(dateWithTimezoneOffset).getMinutes()
        minutes = minutes>9? minutes : "0"+minutes
        discussionCompleteTime =  
          `${hours}:${minutes} ${am_or_pm}`
    }
    
      
    return (
      <React.Fragment>
        <section className="pb-5">
          <div className="row pt-4 pb-3 pl-3 pr-3">
            <div className="col-12 text-center d-flex justify-content-between align-items-center">
              {this.state.nextId? (
                <div>
                  <Link
                    className="btn light-btn"
                    to={`/courses/${this.props.match.params.id}/askQuestions/details/${this.state.nextId}`}
                    >
                    السابق
                  </Link>
                </div>
              ):(<div />)}

              {this.state.prevId ? (
                <div>
                  <Link
                    className="btn light-btn"
                    to={`/courses/${this.props.match.params.id}/askQuestions/details/${this.state.prevId}`}
                  >
                  التالي
                  </Link>
                </div> ) : (<div />)
              }

            </div>
          </div>
          {
            this.state.isPageLoading ? (
              <div
                className="silver-bg box-layout w-100 pb-0 p-3 mt-1 d-flex justify-content-center align-items-center"
                style={{ minHeight: 350 }}
              >
                <Loader type="ball-spin-fade-loader" className="dark-loader" />
              </div>
             ):(
              <div>
                <React.Fragment>
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <h6 className="dark-text TheSansArabBold-font">
                          اسم الطالب : {this.state.details.studentName}
                        </h6>
                      </div>
                      <div className="box-layout box-border shadow-sm p-3">
                        <div className=" align-items-center justify-content-between mb-3">
                          <div className="d-flex align-items-center justify-content-end">
                            {
                              <UncontrolledDropdown>
                                <DropdownToggle>
                                  <div>
                                    <i className="fas fa-chevron-down font-13 align-self-center light-text"></i>
                                    <CustomTooltip isPrivate={this.state.isPrivate} isImgClickable />
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <div 
                                      onClick={()=>this.confirmSettingQuestionPrivacy(this.state.details.id, true)}
                                      className="d-flex align-items-center"
                                    >
                                      <div>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL + "/assets/images/dark-lock.png"
                                          }
                                          width="20"
                                          alt=""
                                          className="contain-img clickable mr-2"
                                        />
                                        <span className="light-text">خاص</span>
                                      </div>
                                      {
                                        this.state.isPrivate ? (
                                        <div className="active-dote ml-2" />
                                        ) : null
                                      }
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem>
                                    <div 
                                      onClick={()=>this.confirmSettingQuestionPrivacy(this.state.details.id, false)}
                                      className="d-flex align-items-center"
                                    >
                                      <div>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL + "/assets/images/users.png"
                                          }
                                          width="20"
                                          alt=""
                                          className="contain-img clickable mr-2"
                                        />
                                        <span className="light-text">عام</span>
                                      </div>
                                      {
                                        !this.state.isPrivate ? (
                                        <div className="active-dote ml-2" />
                                        ) : null
                                      }
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            }

                            {this.state.questionValue && (
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/assets/images/edit.png"
                                }
                                alt="Edit-Icon"
                                width="20"
                                className="contain-img mr-2 clickable"
                                onClick={() => this.toggleEditQuestionForm()}
                              />
                            )}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/remove.png"
                              }
                              width="20"
                              alt="Remove-Icon"
                              className="contain-img clickable"
                              onClick={() => this.onDelete(this.state.details.id)}
                            />
                          </div>
                        </div>
                        {this.state.showEditQuestionForm ? (
                          <div style={{ minHeight: 140,  }}>
                            {this.state.details.type === "Both" && 
                              <img
                                src={this.state.details.imageUrl}
                                height="400"
                                className="contain-img w-100"
                                alt="Question"
                              />
                            }
                            <form onSubmit={this.handleEditQuestionSubmit} style={{marginTop: "5px",}} >
                              <textarea
                                type="text"
                                name="questionValue"
                                onChange={this.handleChange}
                                value={this.state.questionValue}
                                rows="7"
                                className="form-control small dark-text shadow-sm mb-3 question-text-edit"
                              />
                              <div className="d-flex justify-content-end">
                                <button
                                type="submit"
                                className="btn light-outline-btn btn-sm unset-height pl-5 pr-5"
                                disabled={this.state.disabledSubmit}
                                style={style}
                              >
                                ارسال
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <>
                            {this.state.details.type === "Both" ?(
                              <div>
                                <img
                                src={this.state.details.imageUrl}
                                height="400"
                                className="contain-img w-100"
                                alt="Question"
                                />
                                <p className="ar-text question-text text-break" style={{borderTop: "2px solid #dbdbdb", marginTop: "5px", }}>
                                  {this.state.details.content}
                                </p>
                              </div>
                            ) : this.state.details.type === "Image" ? (
                              <img
                                src={this.state.details.imageUrl? this.state.details.imageUrl : this.state.details.content}
                                height="400"
                                className="contain-img w-100"
                                alt="Question"
                              />
                            ) : (
                              <p className="ar-text question-text text-break">
                                {this.state.details.content}
                              </p>
                            )}
                          </>
                        )}
                      </div>

                      {this.state.askQuestionStatus === "Finished" ? (
                          <div className="dashed-border mid-bg box-layout box-border py-1 px-2 mt-2">
                            <div className="d-flex align-items-center mb-2">
                              <div className="mr-1 d-flex justify-content-center align-items-center">
                                <img
                                  src={process.env.PUBLIC_URL + "/assets/images/blue-check.png"}
                                  alt="Hint"
                                  style={{width: "30px", height: "30px"}}
                                />
                              </div>
                              <p className="light-silver-text text-not-break mb-0">
                              تم اكتمال النقاش علي هذا السؤال
                              </p>
                            </div>
                            {
                              this.state.discussionCompleteDate? (
                                <p className="small silver-text m-0" style={{fontFamily: "MontserratMedium" }}>
                                  [بواسطة {this.state.isDiscussionCompleteByStudent? "الطالب" : "الأستاذ"}/  
                                  <span className="off-white-text d-inline-block mr-2 ml-1">
                                    {this.state.isDiscussionCompleteByStudent?this.state.details?.studentName : this.props.user?.name}
                                  </span>
                                    بتاريخ  
                                    <span className="off-white-text d-inline-block mr-2 ml-1">
                                    {discussionCompleteDate} 
                                    </span>
                                    وتمام الساعة  
                                    <span className="off-white-text d-inline-block ml-1">
                                    {discussionCompleteTime}
                                    </span>
                                  ]
                                </p>
                              ) : null
                            }
                          </div>
                        ) : (this.state.askQuestionStatus === "underDiscussion" || this.state.askQuestionStatus === "NotAnswered") ? (
                          <div className="d-flex flex-row-reverse box-layout border-0 box-border width-85-per mt-3">
                            <div className="width-130">
                            {
                                this.state.sendingFinishRequest?
                                (
                                <div class="finish-btn headShake big-height light-sub-bgcolor py-1 px-2 br-5">
                                  <Loader type="ball-clip-rotate" />
                                </div>
                              ):(
                                <button
                                  className="finish-btn headShake big-height light-sub-bgcolor py-2 px-2 unset-height unset-line-height br-5"
                                  onClick={()=>this.finishDiscussion(this.state.details.id)}
                                >
                                اكتمل النقاش
                                </button>
                              )
                              }
                            {/* <button
                              className="btn dark-btn unset-height unset-line-height"
                              onClick={()=>this.finishDiscussion(this.state.details.id)}
                            >
                              اكتمل النقاش
                            </button> */}
                            </div>
                          </div>
                        ) : null}
                    </div>
                  </div>
                </React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <CommentsList 
                    userId={this.state.details.studentId} 
                    openDiscussion={this.openDiscussion}
                    questionIsPrivate={this.state.isPrivate}
                  />
                </div>
              </div>
             )
          }
        </section>
        <SendingModal isOpen={this.state.isSending} message={this.state.message} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

AskQuestionDetailsComponent = connect( mapStateToProps , {setQuestionDetailsId})(AskQuestionDetailsComponent)
export const AskQuestionDetails = withRouter(AskQuestionDetailsComponent);
