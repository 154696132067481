import React from "react";
import { usePermissions } from "./../../../../hooks/usePermissions";
import { PERMISSION_ENUM } from "./../../../../enum/permissions";
export const ExamAddBtn = ({ onClick = () => {}, title }) => {
  const [checkAuth] = usePermissions();
  const authed = checkAuth(PERMISSION_ENUM.ExamsCreate);

  if (!authed) return null;

  return (
    <button type="button" className="btn light-btn pr-4 pl-4" onClick={onClick}>
      {title}
    </button>
  );
};
