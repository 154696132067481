import React, { Component } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../../../shared/inputs/inputField";
import { selectField } from "../../../shared/inputs/selectField";
import { textareaField } from "../../../shared/inputs/textareaField";
import { withRouter } from "react-router-dom";
import { CustomInput } from "reactstrap";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import moment from "moment-hijri";
import LoadMore from "../../../../shared/LoadMore";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

let token = localStorage.getItem("token");
let headers = {
  Authorization: `Bearer ${token}`,
};
export class TestBankComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      subjectDetails: [],
      tags: [],
      suggestions: [],
      loading:false,
      total:0,
      Page:1,
      Limit:8,
      requestOptions: {
        page: 1,
        pageCount: 1,
        itemCount: 0,
        limit: 12,
      },
      searchFilter:{},
      hijriYear:[],
      topics:[],
      childrenTopics:[],
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.getTopics = this.getTopics.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.loadMore = this.loadMore.bind(this)
    this.getQuestions = this.getQuestions.bind(this)
  }

  loadMore(){
    this.getQuestions()
  }

  resetPaginationData(cb = ()=>{}){
    this.setState({Page: 1,questions:[]},cb)
  }

  getTopics(subject){
    const url = `${apiBaseUrl}/Content/Topics/${subject}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    return axios.get(url,{headers})
  }
  async handleChangeSubject(e) {
    const subjectId =  e.target.value;
    const res = await this.getTopics(subjectId)
    if(subjectId!==0) {
      this.setState({
        childrenTopics:[],
        searchFilter:{
          ...this.state.searchFilter,
          childrenTopicId:0,
          topics:0,
          subjectId: subjectId
        }} , ()=>  this.searchFilterFunction());
      this.setState({ topics: res.data.data});
    }};

  handleChangetopic = e => {
    const topicId =  e.target.value;
    if(topicId!==0) {
      this.setState({
        searchFilter:{
          ...this.state.searchFilter,
          topics: topicId
        }} , ()=>  this.searchFilterFunction());

      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`
      };
      axios
          .get(`${apiBaseUrl}/Content/ChildrenTopics/${topicId}`, {headers})
          .then(res => {
            this.setState({childrenTopics: res.data.data});
          })
          .catch(error => {
            console.log(error);
          });
    }
  };
  handleChangeChildrenTopic = e =>{
    const childrenTopicId = e.target.value;
    if(childrenTopicId!==0) {

      this.setState({
        searchFilter: {
          ...this.state.searchFilter,
          childernTopics: childrenTopicId
        }
      }, () => this.searchFilterFunction());
    }  };

  getHijriYear = () =>{
    const hijri=[];
    const currentYear = String(new Date().getUTCFullYear());
    const currentYearHijri = Number(moment(currentYear, 'YYYY').endOf('iYear').format('iYYYY'));
    for (let i=1434; i <= currentYearHijri; i +=1){
      hijri.push(i);
    }
    this.setState({hijriYear: hijri})
  };

  handleChangeYear = e =>{
    this.setState({
      searchFilter:{
        ...this.state.searchFilter,
        cf_HijriYear : e.target.value
      }},()=>  this.searchFilterFunction());
  };

  handleChangeTextOrId = e =>{
    this.setState({
      searchFilter:{
        ...this.state.searchFilter,
        textOrId : e.target.value
      }},()=>  this.searchFilterFunction());
  };

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags.data, tag] }));
    const { limit, page } = this.state.requestOptions;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    let url = new URL(`${apiBaseUrl}/questions`);
    url.searchParams.append("topics", `${tag.id}`);

    url.searchParams.append("Page", page);
    url.searchParams.append("Limit", limit);
    let requestUrl = url.toString();

    axios
      .get(requestUrl, { headers })
      .then((response) => {
        let questions = response.data.data.data;
        this.setState({
          questions,
          requestOptions: {
            page: response.data.page,
            pageCount: response.data.pageCount,
            itemCount: response.data.itemCount,
            limit: response.data.limit,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  searchFilterFunction = () => {
    this.resetPaginationData(()=>{

      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      const {searchFilter} = this.state;
      Object.keys(searchFilter).map((row)=>{
        if(searchFilter[row]==0) delete searchFilter[row]
      });
    let params = new URLSearchParams(searchFilter).toString();
    const {Page,Limit} = this.state
    this.loadMore()
    return
    axios
    .get(`${apiBaseUrl}/Questions?${params}`, { headers,params:{Page,Limit} })
    .then((response) => {
      this.setState({ questions: response.data.data.data,Page:this.state.Page+1 });
    })
    .catch((error) => {
      console.log(error);
    });
  })
  };
  
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/questions`, { headers })
      .then((response) => {
        let questions = response.data.data.data;
        this.setState({
          questions,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getQuestions(){
    this.setState({loading:true})
    const {Page,Limit} = this.state
    let params = new URLSearchParams(this.state.searchFilter).toString();
    axios
    .get(`${apiBaseUrl}/questions?${params}`, { headers,params:{Page,Limit} })
    .then((response) => {
      this.setState({ questions: [...this.state.questions,...response.data.data.data],Page: this.state.Page+1,total:response.data.data.itemCount,loading:false });
      this.arrayholder = response.data.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
  }

  componentDidMount = () => {
    this.getQuestions()
    axios
      .get(`${apiBaseUrl}/Content/MineSubjects`, { headers })
      .then((response) => {
        this.setState({
          subjectDetails: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.getHijriYear();
  };
  getSubject = () =>{
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
        .get(`${apiBaseUrl}/Content/MineSubjects`, { headers })
        .then(response => {
          this.setState({ subjectDetails: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
  };
  renderQuestions() {
    const questions = this.state.questions || [];
    return questions.map((question) => (
      <React.Fragment>
        <div className="item">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <h6 className="light-text mt-0 mb-0">
                سؤال <span className="en-text">{question.id}#</span>
              </h6>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <div className="box-layout box-border shadow-sm p-2">
                <h6
                  className="dark-text mb-0 encoded-text img-auto-for-children"
                  dangerouslySetInnerHTML={{
                    __html: question.encodedStem,
                  }}
                ></h6>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-7">
              {Object.keys(question.choices).map(function(key) {
                const value = question.encodedChoices[key];
                return (
                  <div
                    className={`box-layout d-flex align-items-center pr-2 pl-2 mb-2 ${
                      key == question.correctChoice ? "green-border" : null
                    }`}
                  >
                    <h6
                      dangerouslySetInnerHTML={{ __html: value }}
                      className={`small dark-silver-text light-font-text d-flex align-items-center mb-0 img-auto-for-children ${
                        key == question.correctChoice ? "green-text" : null
                      }`}
                    ></h6>
                  </div>
                );
              })}
            </div>

            {question.imageUrl && (
              <div className="col-5 d-flex align-items-center">
                <img src={question.imageUrl} width="100%" />
              </div>
            )}
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  }

  render() {
    const { tags, suggestions,searchFilter, hijriYear, subjectDetails,topics, childrenTopics } = this.state;
    return (
      <React.Fragment>
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row pt-4 pb-3">
              <div className="col-12">
                <h5 className="dark-text sideline">
                  <span>بنك أسئلة {this.state.subjectDetails.name}</span>
                </h5>
              </div>
            </div>
            <form>
              <div className="row mt-4">
                <div className="col-md-12">
                  <Field
                    name="title"
                    type="text"
                    component={inputField}
                    className="form-control border-left-0 pl-0"
                    placeholder="ابحث عن طريق نص أو رمز السؤال"
                    onChange={this.handleChangeTextOrId}
                    value={searchFilter.textOrId}
                  />
                </div>
              </div>
              <div className="row">
                {/*<div className="col-md-12">*/}
                {/*  <ReactTags*/}
                {/*    tags={[]}*/}
                {/*    suggestions={[]}*/}
                {/*    // handleDelete={this.handleDelete}*/}
                {/*    // handleAddition={this.handleAddition}*/}
                {/*    // delimiters={delimiters}*/}
                {/*    // inputFieldPosition="top"*/}
                {/*    // placeholder="اختر التصنيف.."*/}
                {/*    // allowDragDrop={false}*/}
                {/*    // allowDeleteFromEmptyInput={false}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">السنة هجري</label>
                  <select
                      value={searchFilter.cf_HijriYear}
                      onChange={this.handleChangeYear}
                      className="form-select w-100 p-2 small dark-text border border-light-2"
                      aria-label="Default select example"
                  >
                    <option value={0}>اختر السنة</option>

                    {hijriYear.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">الموضوع</label>

                  <select value={searchFilter.subjectId}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangeSubject}
                          name='subjectId'
                  >
                    <option value={0}>اختر الموضوع</option>

                    {
                      subjectDetails?.length  && subjectDetails?.map( (row) =>
                          <option value={row.id}>{row.name}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">المفاهيم الاساسية</label>

                  <select value={searchFilter.topics}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangetopic}
                          name='topicId'
                  >
                    <option value={0}>اختر مفهوم</option>
                    {
                      topics && topics.map( (row) =>
                          <option value={row.id}>{row.name}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">المفاهيم الفرعية</label>

                  <select value={searchFilter.childernTopics}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangeChildrenTopic}
                          name='childrenTopicId'
                  >
                    <option value={0}>اختر المفهوم</option>

                    {
                      childrenTopics && childrenTopics.map( (row) =>
                          <option value={row.id}>{row.name}</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <hr />
              <div className="row mt-4">
                 <div className="col-md-12">{this.renderQuestions()}</div>
                 {this.state.questions.length !== this.state.total &&  <LoadMore loading={this.state.loading} onClick={this.loadMore} />}
              </div>
            </form>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.TestBank && state.form.TestBank.values,
  };
}

TestBankComponent = reduxForm({
  form: "TestBank",
})(TestBankComponent);

TestBankComponent = connect(mapStateToProps)(TestBankComponent);

export const TestBank = withRouter(TestBankComponent);
