import React, { Component } from "react";
import { Sidebar } from "../shared/sidebar/sidebar";
import { Schedule } from "./schedule";
import { SpeedUp } from "./speed-up/speed-up";
import { SpeedUpDetails } from "./speed-up/SpeedUpDetails";
import { DiscussionsList } from "./discussions/discussions-list";
import { DiscussionDetails } from "./discussions/discussion-details";
import { apiBaseUrl } from "../../../api/helpers";
import { Link, Route } from "react-router-dom";
import { ExamsList } from "./exams/exams-list";
import { ExamDetails } from "./exams/exam-details";
import { RecordedLectures } from "./lectures/recorded-lectures";
import { LectureDetails } from "./lectures/recorded-videos";
import { AskQuestionsList } from "./ask-questions/ask-questions-list";
import { AskQuestionDetails } from "./ask-questions/question-details";
import { CreateExam } from "./exams/add-question";
import { UpdateExam } from "./exams/update-question";
import UsersChatComponent from "../../chat/chat";
import firebase from "../../../firebase";
import axios from "axios";
import TrainingList from "./traingings";
import { firebaseErrorAlert } from "../../../firebase-error-alert";
import LiveLectures from "./lectures/live-lectures";
export class CourseDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: [],
      channelsRef: firebase.database().ref("channels"),
    };
  }

  componentDidMount() {
    this.getCourseDetails();
  }

  createChannel = () => {
    const { chatChannels } = this.state.details;
    const { channelsRef } = this.state;
    chatChannels.filter(c=>c.forceInternalChat == true).map((channel) => {
      const chatChannelSid = channel.chatChannelSid;
      const key = chatChannelSid;
      const newChannel = {
        id: key,
      };
      channelsRef
        .child(key)
        .update(newChannel)
        .then(response=>{})
        .catch(error=>{
          // swal("حدث خطأ في الدردشة الداخلية", "برجاء التواصل مع خدمة العملاء", "error")
          // console.log("firebase_Error: ", error);
          firebaseErrorAlert(error)
        })
    })
  };

  getCourseDetails() {
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/courses/${courseId}`, { headers })
      .then((response) => {
        this.setState({ details: response.data.data }, () => {
          this.createChannel();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="container mt-5 pb-5">
          <div className="row">
          <div className="col-12 mb-4 mb-md-5 dark-text h4 ml-2 font-weight-bold">
          {this.state.details?.nameAr? (
            <div className="d-flex justify-content-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/course.png"}
                height={40}
                width={40}
                alt=""
              />
              <p className="mb-0 mx-2">
                <span>
                  {this.state.details?.nameAr}
                </span>
                {/* <span className="sub-color mx-2">/</span> */}
              </p>
            </div>
          ):null}
            {
              this.state.details?.categoryName? (
              <div className="d-flex justify-content-center align-items-center mt-2">
                <span className="main-color-light font-size-14 TheSansArabMedium-font">
                  المنصة
                </span>
                <img 
                  src={process.env.PUBLIC_URL + "/assets/images/down-arrow.png"}
                  alt=""
                  width={15}
                  height={30} 
                  className="mr-2 ml-1"
                  style={{transform: "rotate(90deg)"}}
                  />
                <span
                  className="cat-btn TheSansArabMedium-font"
                >
                  {this.state.details?.categoryName}
                </span>
                </div>
              ):null
            }
          </div>
            <div className="col-md-3 col-12">
              <Sidebar subscriberGuideVideoURL={this.state.details?.subscriberGuideVideoURL} />
            </div>
            <div className="col-md-9 col-12">
              <div className="row no-gutters" />
              <Route path="/courses/:id/schedule" component={Schedule} />

              <Route exact path="/courses/:id/speed-up/:id" component={SpeedUpDetails} />
              <Route exact path="/courses/:id/speed-up" component={SpeedUp} />
              <Route
                path="/courses/:id/discussions"
                exact
                component={DiscussionsList}
              />

              <Route
                path="/courses/:id/live-lecture/:lectureId"
                component={UsersChatComponent}
              />
              <Route
                path="/courses/:id/discussions/details/:discussionId"
                component={DiscussionDetails}
                chatChannelSid={this.state.details.chatChannelSid}
              />

              <Route
                path="/courses/:id/recorded-lectures"
                component={RecordedLectures}
              />
              <Route
                path="/courses/:id/Live-lectures"
                component={LiveLectures}
              />
              <Route
                path="/courses/:id/lecture/:lectureId"
                component={LectureDetails}
              />
              <Route  path="/courses/:id/training/list" component={TrainingList}/>
              <Route path="/courses/:id/exams/list" component={ExamsList} />
              <Route
                path="/courses/:id/exams/details/:examId"
                component={ExamDetails}
              />
              <Route
                path="/courses/:id/exams/create/:examId"
                component={CreateExam}
              />
              <Route
                path="/courses/:id/exams/update/:examId/:questionId"
                component={UpdateExam}
              />

              <Route
                exact
                path="/courses/:id/askQuestions"
                component={AskQuestionsList}
              />

              <Route
                path="/courses/:id/askQuestions/details/:questionId"
                exact
                component={AskQuestionDetails}
              />
              {this.state.details.chatChannels &&
                <Route
                  path="/courses/:id/chat"
                  render={(props) => (
                    <UsersChatComponent
                      chatChannels={this.state.details && this.state.details.chatChannels}
                      forceInternalChat={this.state.details && this.state.details.forceInternalChat}
                      externalChannelUrl={this.state.details && this.state.details.chatChannels && this.state.details.chatChannels.length > 0 && this.state.details.chatChannels[0].externalChannelUrl }
                      {...props}
                    />
                  )}
                />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
