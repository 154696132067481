import React, { Component } from "react";
import "./App.sass";

import { Header } from "./components/shared/header/header";
import { Footer } from "./components/shared/footer/footer";
import {
  BrowserRouter,
  Route,
  withRouter,
  Switch,
  Redirect,
} from "react-router-dom";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { Home } from "./components/home/home";
import { hemmaReducer } from "./reducers";
import ReduxPromise from "redux-promise";
import ScrollToTop from "./components/shared/scroll-to-top/ScrollToTop";
import NotFound from "./components/shared/not-found/not-found";
import { Auth } from "./components/auth/auth";
import { Verification } from "./components/verification/verification";
import { forgotPassword } from "./components/phone-reset/forgot-password/forgot-password";
import { VerifyId } from "./components/phone-reset/verify-id/verify";
import { resetPassword } from "./components/phone-reset/reset-password/reset-password";
import { EditAccount } from "./components/account/settings/edit-account";
import { AccountReset } from "./components/account/settings/reset-password";
import { Courses } from "./components/account/courses/courses";
import { CourseDetails } from "./components/account/courses/course-details";
import { TestBank } from "./components/account/courses/exams/test-bank";
import { Categories } from "./components/categories/list";
import { CategoryDetails } from "./components/categories/details";
import { QuickQuestions } from "./components/categories/quick-questions/quick-questions";
import { QuestionSummary } from "./components/categories/quick-questions/question-summary";
import requireAuth from "./components/shared/authentication/require-auth";
import { Healthy } from "./components/shared/healthy";
import { PermissionInterceptor } from "./interceptors/permissions";

const store = createStore(hemmaReducer, {}, applyMiddleware(ReduxPromise));

class AppBackground extends Component {
  locationHashChanged() {
    // if (window.location.pathname.includes("courses")) {
    // }
  }
  componentDidMount() {
    window.onhashchange = this.locationHashChanged;
  }
  render() {
    const path = this.props.location.pathname;
    let img = null;
    let imgSize = null;
    let imgPosition = null;

    if (path === "/") {
      img = "home-bg.png";
      imgSize = "100%";
      imgPosition = "center top";
    } else if (path.startsWith("/account")) {
      img = "pages-bg.png";
      imgSize = "100%";
      imgPosition = "center top";
    } else if (
      path.startsWith("/auth") ||
      path.startsWith("/verify") ||
      path.startsWith("/forgot-password") ||
      path.startsWith("/reset-password")
    ) {
      img = "auth-bg.png";
      imgSize = "100%";
      imgPosition = "center bottom";
    }

    if (!img) return <div>{this.props.children}</div>;

    return (
      <div
        className="wrapper-bg"
        style={{
          backgroundImage: `url(/assets/images/${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: imgSize,
          backgroundPosition: imgPosition,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

AppBackground = withRouter(AppBackground);

export default class AppComponent extends Component {
  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <div>
            <BrowserRouter>
              <ScrollToTop>
                <PermissionInterceptor />
                <AppBackground>
                  <Header />
                  <Switch>
                    {/* Start Healty check from FE/BE side  */}
                    <Route path="/health">
                      <h3>App is Healthy</h3>
                    </Route>
                    <Route exact path="/ready" component={Healthy} />
                    {/* End Healty check from FE/BE side  */}

                    <Route path="/" exact component={Home} />
                    <Route path="/auth" component={Auth} />
                    <Route
                      path="/verify"
                      exact
                      component={requireAuth(Verification)}
                    />
                    <Route path="/verify/identity" component={VerifyId} />
                    <Route path="/forgot-password" component={forgotPassword} />
                    <Route path="/reset-password" component={resetPassword} />
                    <Route path="/categories" exact component={Categories} />
                    <Route
                      path="/categories/details/:slug"
                      exact
                      component={CategoryDetails}
                    />
                    <Route
                      path="/categories/details/:slug/quick-questions/:categoryGroupId"
                      exact
                      component={QuickQuestions}
                    />
                    <Route
                      path="/categories/details/:slug/quick-questions/summary/:questionId"
                      component={QuestionSummary}
                    />
                    <Route
                      path="/account/edit"
                      component={requireAuth(EditAccount)}
                    />
                    <Route
                      path="/account/reset-password"
                      component={requireAuth(AccountReset)}
                    />
                    <Route
                      path="/courses"
                      exact
                      component={requireAuth(Courses)}
                    />
                    <Redirect
                      exact
                      from="/courses/:id"
                      to="/courses/:id/schedule"
                    />
                    <Route
                      path="/courses/:id"
                      component={requireAuth(CourseDetails)}
                    />
                    <Route path="/test-bank" component={TestBank} />

                    <Route path="/not-found" component={NotFound} />
                    <Redirect from="/" exact to="/home" />
                    <Redirect to="/not-found" />
                  </Switch>
                  <Footer />
                </AppBackground>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </React.Fragment>
      </Provider>
    );
  }
}
