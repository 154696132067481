import React, { Component } from "react";
import { apiBaseUrl } from "../../../../../api/helpers";
import axios from "axios";
import { Reply } from "./reply";
import { ReactMic } from "react-mic";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";
import swal from "@sweetalert/with-react";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import { SendingRequest } from "../../sharedComponents/sendingRequest";
import SendingModal from "../../sharedComponents/SendingModal";
import CustomTooltip from "../custom-tooltip/custom-tooltip";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

var moment = require("moment");
moment().format("");

export class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullReplies: false,
      showAddReplyForm: false,
      showEditCommentForm: false,
      replies: [],
      replyCount: 0,
      reply: "",
      file: "",
      fileError: "",
      replyType: "",
      commentValue: props.comment.value,
      commentImage: props.comment.imageUrl,
      commentType: props.comment.type,
      blobObject: null,
      isRecording: false,
      isPaused: false,
      soundFile: "",
      videoFile: "",
      disabled: false,
      isSending: false,
      disableSubmit: true,
      isPrivate: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleEditCommentSubmit = this.handleEditCommentSubmit.bind(this);
    this.onStop = this.onStop.bind(this);
    this.removeFileImage = this.removeFileImage.bind(this);
    this.openDiscussion = this.openDiscussion.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.confirmSettingCommentPrivacy = this.confirmSettingCommentPrivacy.bind(this);
  }

  handlePlay(id) {
    this.props.setPlayedId(id)
  }
  
  openDiscussion() {
    this.props.openDiscussion()
  }

  componentDidMount() {
    this.setState({ 
      replyCount: this.props.comment.replyCount, 
      isPrivate: this.props?.comment?.isPrivate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.rap){
      if(prevProps.playedId !== this.props.playedId){
        if(this.props.playedId !== this.props.comment.id){
          this.rap.audioEl.current.pause()
        }
      }
    } 

    if(prevProps.questionIsPrivate !== this.props.questionIsPrivate){
     this.setState({isPrivate: this.props.questionIsPrivate})
    }

  }

  startOrPauseRecording = () => {
    const { isPaused, isRecording } = this.state;

    if (isPaused) {
      this.setState({ isPaused: false });
    } else if (isRecording) {
      this.setState({ isPaused: true });
    } else {
      this.setState({ isRecording: true });
    }
  };

  stopRecording = () => {
    this.setState({ isRecording: false });
  };

  onStop(blobObject) {
    this.setState({ isSending: true });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("FileType", "Voice");
    data.append("File", blobObject.blob);
    data.append("Type", "Replies");
    this.setState({ disableSubmit: true, isSending: true, message: "جاري التحميل", file: "", videoFile: "", });
    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          soundFile: response.data.data.url,
          commentType: "Voice",
          disabled: false,
          disableSubmit: false, 
          isSending: false, 
          message: "",
        });
        // if (this.state.soundFile) {
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`,
        //   };
        //   let data = {
        //     type: "Voice",
        //     voiceUrl: this.state.soundFile,
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers,
        //       }
        //     )
        //     .then((response) => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm,
        //         replyCount: this.state.replyCount + 1,
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState((prevState) => {
        //         return {
        //           replies: prevState.replies.concat(reply),
        //         };
        //       });
        //       this.openDiscussion()
        //       this.setState({
        //         soundFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       swal("تم اضافة الرد بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch((error) => {
        //       this.setState({ disabled: false, isSending: false });
        //       swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       });              
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({ 
          disabled: false, 
          isSending: false,
          disableSubmit: false, 
          message: "", 
        });
        swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        console.log(error);
      });
  }

  toggleEditCommentForm() {
    this.setState({
      showEditCommentForm: !this.state.showEditCommentForm,
      commentValue: this.props.comment.value,
      commentImage: this.props.comment.imageUrl,
      commentType: this.props.comment.type,
    });
  }

  validateImage = (target) => {
    this.setState({ fileError: "" });

    // Start Image Validation
    const notAllowedImage = [
      "عفواً امتداد الصورة يجب أن يكون أحد الأنواع png, jpg, jpeg, svg",
      "مساحة الصورة يجب ألا تتجاوز 1 ميجا بايت",
    ];
    const endFile = target.value.slice(target.value.lastIndexOf(".") + 1);
    const isImage =
      endFile === "png" ||
      endFile === "jpeg" ||
      endFile === "svg" ||
      endFile === "jpg";

    if (!isImage) {
      this.setState({ file: "", fileError: notAllowedImage[0] });
      target.value = "";
      return;
    }

    let size = parseFloat(target.files[0].size / (1024 * 1024)).toFixed(2);
    if (size > 1) {
      this.setState({ file: "", fileError: notAllowedImage[1] });
      target.value = "";
      return;
    }

    const validatedFile = target.files[0];
    return validatedFile;

    // End Image Validation
  };

  handleFileChange = (event) => {
    let validatedFile = this.validateImage(event.target);
    if (!validatedFile) return;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("FileType", "Image");
    data.append("File", event.target.files[0]);
    data.append("Type", "Replies");
    this.setState({ disableSubmit: true, isSending: true, message: "جاري التحميل",soundFile: "", videoFile: "", });
    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          file: response.data.data.url,
          commentType: "Image",
          isSending: false,
          disableSubmit: false,
          message: "",
        });

        // if (this.state.file) {
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`,
        //   };
        //   let data = {
        //     type: "Image",
        //     value: this.state.file,
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers,
        //       }
        //     )
        //     .then((response) => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm,
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState((prevState) => {
        //         return {
        //           replies: prevState.replies.concat(reply),
        //         };
        //       });
        //       this.setState({ file: "", disabled: false, isSending: false });
        //     })
        //     .catch((error) => {
        //       this.setState({ disabled: false, isSending: false });
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({ isSending: false, disableSubmit: false,  message: "", });
        console.log(error);
      });
  };

  handleVideoChange = (event) => {
    this.setState({ isSending: true });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("FileType", "Video");
    data.append("File", event.target.files[0]);
    data.append("Type", "Replies");
    this.setState({ disableSubmit: true, isSending: true, message: "جاري التحميل", soundFile: "", file: "", });
    axios
      .post(`${apiBaseUrl}/AskQuestionComments/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.setState({
          videoFile: response.data.data.url,
          commentType: "Video",
          disableSubmit: false, 
          isSending: false, 
          message: "",
        });
        // if (this.state.videoFile) {
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`,
        //   };
        //   let data = {
        //     type: "Video",
        //     videoUrl: this.state.videoFile,
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/AskQuestionComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers,
        //       }
        //     )
        //     .then((response) => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm,
        //         replyCount: this.state.replyCount + 1,
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState((prevState) => {
        //         return {
        //           replies: prevState.replies.concat(reply),
        //         };
        //       });
        //       this.openDiscussion()
        //       this.setState({
        //         videoFile: "",
        //         disabled: false,
        //         isSending: false,
        //       });
        //       swal("تم اضافة الرد بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch((error) => {
        //       this.setState({ disabled: false, isSending: false });
        //       // swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        //       swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       });    
        //       console.log(error);
        //     });
        // }
      })
      .catch((error) => {
        this.setState({ 
          disabled: false, 
          isSending: false, 
          disableSubmit: false, 
          message: "", 
        });
        swal("عفوا حدث خطأ ما", "برجاء عاود المحاولة", "error");
        console.log(error);
      });
  };

  getReplies = () => {
    const latest = this.props.comment.latestReply;
    const all = this.state.replies;

    if (this.state.showFullReplies) {
      return all;
    }

    return latest ? [latest] : [];
  };

  confirmDeleteReply(id) {
    swal({
      // title: "تأكيد الحذف !",
      text: "هل تريد حذف هذا الرد على التعليق بشكل نهائي؟",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
      dangerMode: true,
    }).then((value) => {
      switch (value) {
        case "ok":
          this.deleteReply(id);
          break;

        case "cancel":
          swal("لم يتم حذف الرد", {
            icon: "info",
            button: "متابعة",
          });
          break;

        default:
          swal("لم يتم حذف الرد", {
            icon: "info",
            button: "متابعة",
          });
      }
    });
  }

  deleteReply(id) {
    swal({
      content: <SendingRequest message="جاري الحذف" />,
      button: {
        visible: false,
        closeModal: false,
      },
    });
    const comment = this.props.comment;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${apiBaseUrl}/AskQuestionComments/Replies/${id}`, {
        headers,
      })
      .then((response) => {
        let oldReplies = this.state.replies;
        const replyIndex = oldReplies.findIndex((r) => r.id === id);
        oldReplies.splice(replyIndex, 1);

        // Check if the delete reply is the latest one
        if (comment.latestReply && comment.latestReply.id === id) {
          comment.latestReply = null;
        }

        this.setState({ replies: oldReplies, replyCount: this.state.replyCount - 1 });
        swal("تم حذف الرد بنجاح !", {
          icon: "success",
          button: "متابعة",
        });
      })
      .catch((error) => {
        swal("عفواً", "لم يتم حذف الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }

  removeFileImage() {
    this.setState({ file: "" });
  }

  toggleFullReplies() {
    const showFullReplies = this.state.showFullReplies;

    if (!showFullReplies) {
      const commentId = this.props.comment.id;
      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(`${apiBaseUrl}/AskQuestionComments/${commentId}/Replies`, {
          headers,
        })
        .then((response) => {
          this.setState({ replies: response.data.data, showFullReplies: true });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ showFullReplies: false });
    }
  }

  toggleRepliesForm() {
    this.setState({ showAddReplyForm: !this.state.showAddReplyForm });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value, fileError: "" }, () => {
      if (!this.state.file && this.state.reply.trim() === "") {
        this.setState({ disableSubmit: true });
      } else {
        this.setState({ disableSubmit: false });
      }
    });
  };

  handleEditCommentSubmit(event) {
    event.preventDefault();
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    this.setState({ disabled: true, disableSubmit: true, isSending: true, message: "جاري تعديل التعليق" });

    let data;
    if (
      this.state.commentType === "Mixed" &&
      this.state.commentValue.trim() === ""
    ) {
      data = {
        type: "Image",
        imageUrl: this.state.commentImage,
      };
    } else if (this.state.commentType === "Mixed") {
      data = {
        type: "Mixed",
        value: this.state.commentValue,
        imageUrl: this.state.commentImage,
      };
    } else if (
      this.state.commentType === "Text" &&
      this.state.commentValue.trim() === ""
    ) {
      return;
    } else if (this.state.commentType === "Text") {
      data = {
        type: this.state.commentType,
        value: this.state.commentValue,
      };
    }

    axios
      .put(`${apiBaseUrl}/AskQuestionComments/${this.props.comment.id}`, data, {
        headers,
      })
      .then((response) => {
        const comment = response.data.data;
        this.props.comment.value = comment.value;
        this.props.comment.type = comment.type;
        this.setState({
          showEditCommentForm: false,
          commentValue: comment.value,
          commentType: comment.type,
          commentImage: comment.imageUrl,
          disableSubmit: false, 
          isSending: false, 
          message: "",
        });
        swal("تم تعديل التعليق بنجاح ", {
          button: "متابعة",
          icon: "success",
        });
      })
      .catch((error) => {
        this.setState({  
          disableSubmit: false, 
          isSending: false, 
          message: "",
        })
        swal("عفواً", "لم يتم تعديل التعليق برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }

  handleSubmit(event, isPrivate) {
    event.preventDefault();
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data;
    if (this.state.file && this.state.reply.trim() !== "") {
      data = {
        type: "Mixed",
        value: this.state.reply,
        imageUrl: this.state.file,
        isPrivate,
      };
    } else if (this.state.file) {
      data = {
        type: "Image",
        imageUrl: this.state.file,
        isPrivate
      };
    } else if (this.state.reply.trim() !== "") {
      data = {
        type: "Text",
        value: this.state.reply,
        isPrivate,
      };
    } else if(this.state.videoFile){
      data = {
        type: "Video",
        videoUrl: this.state.videoFile,
        isPrivate,
      };
    } else if(this.state.soundFile){
      data = {
        type: "Voice",
        voiceUrl: this.state.soundFile,
        isPrivate,
      };
    }
    this.setState({ disabled: true, disableSubmit: true, isSending: true, message: "جاري الإرسال" });
    axios
      .post(
        `${apiBaseUrl}/AskQuestionComments/${this.props.comment.id}/Replies`,
        data,
        {
          headers,
        }
      )
      .then((response) => {
        this.setState({
          showAddReplyForm: !this.state.showAddReplyForm,
          reply: "",
          disabled: false,
          replyCount: this.state.replyCount + 1,
          file: "",
          videoFile: "",
          soundFile: "",
          isSending: false, 
          message: "",
          disableSubmit: false
        });
        const reply = response.data.data;
        this.props.comment.latestReply = reply;
        this.setState((prevState) => {
          return {
            replies: prevState.replies.concat(reply),
          };
        });
        this.openDiscussion()
        swal("تم اضافة الرد بنجاح ", {
          button: "متابعة",
          icon: "success",
        });
      })
      .catch((error) => {
        this.setState({ 
          disabled: false, 
          disableSubmit: false, 
          file: "",
          videoFile: "",
          soundFile: "",
          isSending: false, 
          message: "",
        });
        swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }

  renderReplies() {
    const replies = this.getReplies();

    return replies && replies.map((reply) => (
      reply && <Reply
          reply={reply}
          key={reply}
          user={this.props.user}
          onDelete={() => this.confirmDeleteReply(reply.id)}
          setPlayedId={this.props.setPlayedId}
          playedId={this.props.playedId}
          commentIsPrivate={this.state.isPrivate}
        />
      )
    );
  }

  confirmSettingCommentPrivacy(id, status) {
    if(this.state.isPrivate === status) return null;

    swal({
      title: status? "هل أنت متأكد من إلغاء نشر التعليق؟" : "هل أنت متأكد من نشر التعليق؟",
      text: status? "سيتم حجب التعليق والردود التي عليه لباقي طلاب الدورة" : "سيتم ظهور التعليق والردود التي عليه لطلاب الدورة",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
    })
    .then((value) => {
      switch (value) {
        case "ok":
          this.setCommentPrivacy(id)
          break;
        default:
          return;
      }
    });
  }

  setCommentPrivacy(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
    .post(`${apiBaseUrl}/AskQuestionComments/UpdateCommentIsPrivate?id=${id}`,
    {data: ""}, 
    {headers})
    .then(response=>{
      this.setState({isPrivate: !this.state.isPrivate})
      console.log("comment new-Privacy: ", !this.state.isPrivate);
      swal("تم تغيير الخصوصية بنجاح!", {
        buttons: false,
        icon: "info",
        timer: 1000,
      });
    })
    .catch(error=>{
      swal("عفواً", "لم يتم تغيير الخصوصية برجاء إعادة المحاولة ", "error", {
        button: "متابعة",
      });
      console.log(error);
    })

  }

  render() {
    const { isRecording, isPaused } = this.state;
    const comment = this.props.comment;
    const questionIsPrivate = this.props.questionIsPrivate

    const style = {
      pointerEvents:
        this.state.commentType === "Text" &&
        this.state.commentValue.trim() === ""
          ? "none"
          : "auto",
    };
    const disableReplyBtnStyle = {
      pointerEvents: this.state.disableSubmit ? "none" : "auto", width: "110px", padding: "0 3px 0 0",
    };

    // Comment date
    const createdAt = new Date(comment?.createdAt);
    let miladiDate = moment(createdAt).format("YYYY/M/D HH:mm");

    return (
      <React.Fragment>
        <div className="box-layout shadow-sm bg-white mt-3">
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/blue-user.png"}
                  width="25"
                  className="contain-img mr-2"
                  alt="userIcon"
                />
                <div>
                  <h6 className="light-text small mb-1 mr-3">
                    {comment.user && comment.user?.name}
                  </h6>
                  <h6 className="dark-silver-text smaller en-text mb-0">
                    {miladiDate}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
              {
                questionIsPrivate? (
                  <CustomTooltip isPrivate={this.state.isPrivate} isComment />
                ) : (
                  <UncontrolledDropdown>
                    <DropdownToggle>
                      <div>
                        <i className="fas fa-chevron-down font-13 align-self-center light-text"></i>
                        <CustomTooltip isPrivate={this.state.isPrivate} isImgClickable isComment />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <div 
                          onClick={()=>this.confirmSettingCommentPrivacy(comment?.id, true)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/dark-lock.png"
                              }
                              width="20"
                              alt=""
                              className="contain-img clickable mr-2"
                            />
                            <span className="light-text">خاص</span>
                          </div>
                          {
                            this.state.isPrivate ? (
                            <div className="active-dote ml-2" />
                            ) : null
                          }
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <div 
                          onClick={()=>this.confirmSettingCommentPrivacy(comment?.id, false)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/users.png"
                              }
                              width="20"
                              alt=""
                              className="contain-img clickable mr-2"
                            />
                            <span className="light-text">عام</span>
                          </div>
                          {
                            !this.state.isPrivate ? (
                            <div className="active-dote ml-2" />
                            ) : null
                          }
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
              )}
                {comment.correct && (
                  <h6 className="light-text smaller mb-0 mr-3">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/check-mark.png"
                      }
                      width="12"
                      className="contain-img mr-1"
                      alt="check-mark"
                    />
                    إجابة معتمدة{" "}
                  </h6>
                )}
                {(comment.type === "Text" || comment.type === "Mixed") && (
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/edit.png"}
                    width="20"
                    alt="EditIcon"
                    className="contain-img mr-2 clickable"
                    onClick={() => this.toggleEditCommentForm()}
                  />
                )}
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                  width="20"
                  className="contain-img clickable"
                  alt="RemoveIcon"
                  onClick={() => this.props.onDelete()}
                />
              </div>
            </div>

            {this.state.showEditCommentForm ? (
              <div>
                {comment.type === "Mixed" && (
                  <img
                    src={comment.imageUrl}
                    height="200"
                    width="400"
                    className="contain-img"
                    alt="CommentImage"
                  />
                )}
                <form
                  onSubmit={this.handleEditCommentSubmit}
                  style={{ minHeight: 140, marginTop: "5px" }}
                >
                  <textarea
                    type="text"
                    name="commentValue"
                    onChange={this.handleChange}
                    value={this.state.commentValue}
                    rows="4"
                    className="form-control small dark-text shadow-sm mb-3"
                    disabled={this.state.isSending || this.state.isRecording}
                  />
                  <button
                    type="submit"
                    className="btn light-outline-btn btn-sm unset-height pl-5 pr-5 float-right"
                    disabled={
                      this.state.commentType === "Text" &&
                      this.state.commentValue.trim() === ""
                    }
                    style={style}
                  >
                    ارسال
                  </button>
                </form>
              </div>
            ) : (
              <div className="w-100 mb-2">
                {comment.type === "Mixed" ? (
                  <div>
                    <p className="dark-text smaller mb-2">
                    {comment.value}
                    </p>
                    <img
                      src={comment.imageUrl}
                      height="200"
                      className="contain-img w-100 max-width-300px"
                      alt="CommentImage"
                    />
                  </div>
                ) : comment.type === "Text" ? (
                  <p className="dark-text smaller mb-2">
                    {comment.value}
                  </p>
                ) : comment.type === "Voice" ? (
                  <ReactAudioPlayer 
                    onPlay={()=>this.handlePlay(comment.id)}  
                    ref={(element) => { this.rap = element }} 
                    src={comment.voiceUrl ? comment.voiceUrl : comment.value} 
                    controls 
                    className="w-100"
                    type="audio/mpeg"
                  />
                ) : comment.type === "Image" ? (
                  <img
                    src={comment.imageUrl ? comment.imageUrl : comment.value}
                    height="200"
                    className="contain-img mb-2 w-100 max-width-300px"
                    alt="CommentImage"
                  />
                ) : (
                  // <video
                  //   height="200"
                  //   className="video-container video-container-overlay w-100 max-width-300px"
                  //   autoPlay=""
                  //   controls
                  // >
                  //   <source
                  //     type="video/mp4"
                  //     data-reactid=".0.1.0.0.0"
                  //     src={comment.videoUrl ? comment.videoUrl : comment.value}
                  //   />
                  // </video>

                  <div className='player-wrapper'>
                    <ReactPlayer 
                      className='react-player'
                      controls
                      playIcon	
                      width="100%"
                      height="100%"
                      url={comment.videoUrl?comment.videoUrl:comment.value} 
                    />
                  </div>
                )}
              </div>
            )}

            <div className="d-flex justify-content-end align-items-center">
              <h6
                className="badge dark-bg text-white smaller mb-0 mr-3 clickable"
                onClick={() => this.toggleFullReplies()}
              >
                <span className="en-text">{this.state.replyCount}</span> ردود
              </h6>

              <h6
                className="light-text smaller d-flex align-items-center mb-0 clickable"
                onClick={() => this.toggleRepliesForm()}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/reply.png"}
                  width="15"
                  className="contain-img mr-2"
                  alt="replyIcon"
                />
                أضف رد على التعليق
              </h6>
            </div>
          </div>

          {this.renderReplies()}

          {this.state.showAddReplyForm && (
            <div className="silver-bg py-3 px-3">
              <form>
                <div className="position-relative">
                  <textarea
                    id="commentInput"
                    type="text"
                    name="reply"
                    onChange={this.handleChange}
                    placeholder="اكتب رد على التعليق"
                    rows="6"
                    className="form-control small dark-text shadow-sm mb-3"
                  />
                  <div className="textarea-icon d-flex align-items-center">
                    {isRecording ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/blue-mute.png"
                        }
                        alt="Record"
                        height="28"
                        className="contain-img"
                        disabled={!isRecording}
                        onClick={this.stopRecording}
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/dark-mic.png"
                        }
                        alt="Record"
                        height="28"
                        className="contain-img"
                        onClick={this.startOrPauseRecording}
                      />
                    )}
                    <ReactMic
                      record={isRecording}
                      pause={isPaused}
                      onStop={this.onStop}
                      mimeType="audio/mp3"
                    />
                    <label htmlFor="uploadReplyImage" className="mb-0 mr-1">
                      <input
                        className="d-none"
                        id="uploadReplyImage"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/svg"
                        onChange={this.handleFileChange}
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/picture.png"
                        }
                        height="28"
                        width="28"
                        className="contain-img clickable"
                        alt="pictureIcon"
                      />
                    </label>
                    <label htmlFor="uploadReplyVideo" className="mb-0">
                      <input
                        className="d-none"
                        id="uploadReplyVideo"
                        type="file"
                        accept="video/*"
                        onChange={this.handleVideoChange}
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/attach-video.png"
                        }
                        height="28"
                        width="28"
                        className="contain-img clickable"
                        alt="attach-video-icon"
                      />
                    </label>
                  </div>
                </div>
                
                {this.state.file ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({file: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    <div className="">
                      <img
                        src={this.state.file}
                        className="contain-img clickable w-100"
                        height="130px"
                        alt="comment"
                        id="picture-icon"
                      />
                    </div>
                  </div>
                ): null}

                {this.state.videoFile ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({videoFile: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    {/* <div className="">
                      <video
                        height="200"
                        className="video-container video-container-overlay w-100 max-width-300px"
                        autoPlay=""
                        controls
                      >
                        <source
                          type="video/mp4"
                          data-reactid=".0.1.0.0.0"
                          src={this.state.videoFile}
                        />
                      </video>
                    </div> */}

                    <div className='player-wrapper'>
                      <ReactPlayer 
                        className='react-player'
                        controls
                        playIcon	
                        width="100%"
                        height="100%"
                        url={this.state.videoFile} 
                      />
                    </div>
                  </div>
                ): null}

                {this.state.soundFile ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({soundFile: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    <div className="">
                    <ReactAudioPlayer src={this.state.soundFile} controls className="w-100"
                    />
                    </div>
                  </div>
                ): null}

                {/* {this.state.isSending ? (
                  <div
                    className="silver-bg w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
                    style={{ minHeight: 100 }}
                  >
                    <Loader
                      type="ball-spin-fade-loader"
                      className="dark-loader"
                    />
                  </div>
                ) : this.state.file ? (
                  <div
                    className=" position-relative w-50 m-auto p-1 border"
                    style={{ maxWidth: "200px", height: "130px" }}
                  >
                    <img
                      src={this.state.file}
                      className="contain-img clickable w-100 h-100 "
                      height="130px"
                      alt="comment"
                      id="picture-icon"
                    />
                    <span
                      className="position-absolute clickable"
                      style={{ top: "-15px", left: "-10px", fontSize: "22px" }}
                      onClick={this.removeFileImage}
                    >
                      &times;
                    </span>
                  </div>
                ) : this.state.fileError ? (
                  <>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/not-verified.png"
                      }
                      height="20"
                      width="20"
                      className="contain-img clickable mr-1"
                      alt="error"
                    />
                    <small className="ar-text">{this.state.fileError}</small>
                  </>
                ) : null} */}

                {this.state.fileError ? (
                  <>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/not-verified.png"
                      }
                      height="20"
                      width="20"
                      className="contain-img clickable mr-1"
                      alt="error"
                    />
                    <small className="ar-text">{this.state.fileError}</small>
                  </>
                ) : null}

                <div className="float-right">
                  <button
                    type="submit"
                    className="btn light-outline-btn btn-sm unset-height mr-2 d-inline-flex justify-content-between"
                    style={disableReplyBtnStyle}
                    disabled={this.state.disabled || this.state.disableSubmit || (!this.state.file && !this.state.reply && !this.state.videoFile && !this.state.soundFile)}
                    onClick={(e)=>this.handleSubmit(e, true)}
                  >
                    <span className="flex-fill" style={{padding: "5px"}}>ارسال</span>
                    <div className="icon-container">
                      {/* <img src={process.env.PUBLIC_URL + "/assets/images/dark-lock.png"} width={20}/> */}
                      <CustomTooltip isPrivate isReply />
                    </div>
                  </button>
                  {
                    !this.props.questionIsPrivate? (
                    <button
                      type="submit"
                      className="btn light-outline-btn btn-sm unset-height d-inline-flex justify-content-between"
                      style={disableReplyBtnStyle}
                      disabled={this.state.disabled || this.state.disableSubmit || (!this.state.file && !this.state.reply && !this.state.videoFile && !this.state.soundFile)}
                      onClick={(e)=>this.handleSubmit(e, false)}
                    >
                      <span style={{padding: "5px"}}>ارسال ونشر</span>
                      <div className="icon-container">
                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/users.png"} width={20}/> */}
                        <CustomTooltip isReply />
                      </div>
                    </button>
                    ) : null
                  }
                </div>
              </form>
              <div className="clearfix" />
            </div>
          )}
        </div>

        <SendingModal isOpen={this.state.isSending} message={this.state.message} />
        {/* <SendingModal isOpen={true} message={this.state.message} /> */}
      </React.Fragment>
    );
  }
}
