import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../../../shared/inputs/inputField";
import { selectField } from "../../../shared/inputs/selectField";
import { textareaField } from "../../../shared/inputs/textareaField";
import { withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../api/helpers";
import axios from "axios";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import swal from "@sweetalert/with-react";

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = "يجب تعبئة هذه الخانة";
  }
  if (!values.description) {
    errors.description = "يجب تعبئة هذه الخانة";
  }
  return errors;
};

export class ExamComponent extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const { newText, type, onCreate = () => {} } = props;
    this.myFormHandler = this.myFormHandler.bind(this);
    this.updateState = this.updateState.bind(this);
    this.requestClassification = this.requestClassification.bind(this);
    this.requestData = this.requestData.bind(this);
    this.state = {
      newText: newText,
      loading: false,
      disabled: false,
      onCreate: onCreate,
      type: type,
      classifications: [],
    };
    // this.handleDelete = this.handleDelete.bind(this);
    // this.handleAddition = this.handleAddition.bind(this);
    this.getTopics = this.getTopics.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    const id = match.params.id;
    this.courseId = id;
  }

  getLectures(chapterId) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/Content/LecturesByChapterId/${chapterId}`, { headers })
      .then((response) => {
        this.setState({ lectures: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getChpaters(courseId) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/Content/Chapters/${courseId}`, { headers })
      .then((response) => {
        this.setState({ chapters: response.data.data });
        console.log({ bb: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChapterChange = (e) => {
    const chapterId = e.target.value;
    if (chapterId !== 0) {
      this.setState({ chapterId });
      this.getLectures(chapterId);
    }
  };

  onLectureChange = (e) => {
    const lectureId = e.target.value;
    if (lectureId !== 0) {
      this.setState({ lectureId });
    }
  };

  requestData(url, cb = () => {}, config = {}, method = "GET") {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios({ url, method, headers, ...config }).then(({ data }) => {
      cb(data);
    });
  }
  updateState(data) {
    this.setState({ ...this.state, ...data });
  }

  requestClassification() {
    const url = `${apiBaseUrl}/exams/GetExamClassfication`;
    this.requestData(url, (data) => {
      const options = data?.data || [];
      this.setState({ ...this.state, classifications: options });
    });
  }

  getSections = () => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/Content/Sections/${this.courseId}`, { headers })
      .then((response) => {
        this.setState({ sections: response.data.data });
        console.log({ aa: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSubject = () => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/Content/Subjects`, { headers })
      .then((response) => {
        this.setState({ subjectDetails: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getTopics(subject) {
    const url = `${apiBaseUrl}/Content/Topics/${subject}`;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios.get(url, { headers });
  }

  async handleChangeSubject(e) {
    const sectionId = e.target.value;
    this.setState({ sectionId });
    await this.getChpaters(sectionId);
  }

  handleChangeChildrenTopic = (e) => {
    const childrenTopicId = e.target.value;
    this.setState({ childrenTopicId });
  };

  myFormHandler = (values) => {
    const courseId = this.props.courseId;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = {
      courseId: courseId,
      title: values.title,
      description: values.description,
      examClassificationId: +values.classification,
      type: this.state.type,
      sectionId: this.state.sectionId,
      chapterId: this.state.chapterId,
      lectureId: this.state.lectureId,
      order: 0,
    };
    axios
      .post(`${apiBaseUrl}/Exams/ExamWithLocation`, data, {
        headers,
        params: { type: this.state.type },
      })
      .then((response) => {
        this.updateState({ loading: false, disabled: false });
        this.props.closeExam();
        this.state.onCreate();
        // window.location.reload(false);
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        switch (error.response.data && error.response.data.error) {
          case "AccessDeniedError":
            swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
              button: "متابعة",
            });
            break;
          default:
            console.log("other error");
        }
      });
  };

  componentDidMount() {
    this.requestClassification();
    this.getSubject();
    this.getSections();
  }

  render() {
    const customStyles = {
      content: {
        top: "70px",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translateX(-50%)",
        width: window.innerWidth < 768? "80%" : "30%",
        height: "auto",
        borderWidth: 0,
        padding: 20,
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 2,
      },
    };
    const { isExamOpen, closeExam, handleSubmit, submitting } = this.props;
    const { classifications } = this.state;
    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isExamOpen}
          onRequestClose={closeExam}
          closeExam={closeExam}
        >
          <div className="container h-100 pt-3 pb-3 mx-auto">
            <div className="row">
              <div className="col-12">
                <h6 className="light-text mb-4 mt-0">
                  إنشاء {this.state.newText} جديد
                </h6>
              </div>
            </div>

            <form onSubmit={handleSubmit(this.myFormHandler)}>
              <div className="row">
                <div className="col-12">
                  <Field
                    name="title"
                    type="text"
                    component={inputField}
                    className="form-control border-left-0 pl-0"
                    placeholder={`اسم ال${this.state.newText}`}
                  />
                </div>

                <div className="col-12">
                  <Field
                    name="description"
                    type="text"
                    component={textareaField}
                    className="form-control"
                    placeholder="الوصف"
                  />
                </div>

                <div className="col-md-6">
                  <Field
                    name="classification"
                    component={selectField}
                    className="form-control"
                    placeholder="التصنيف"
                  >
                    <option value="" disabled>
                      اختر التصنيف العلمي
                    </option>
                    {classifications?.map((option) => (
                      <option value={option.id}>{option.name}</option>
                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <select
                      value={this.state.sectionId}
                      className="form-control"
                      aria-label="Default select example"
                      onChange={this.handleChangeSubject}
                      name="sectionId"
                    >
                      <option value={0} selected disabled>
                        اختر الجزء
                      </option>
                      {this.state.sections?.length &&
                        this.state.sections?.map((row) => (
                          <option value={row.id}>{row.nameAr}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <select
                      placeholder=""
                      value={this.state.topicId}
                      className="form-control"
                      onChange={this.onChapterChange}
                      name="selectedChapter"
                    >
                      <option value={0} selected disabled>
                        اختر الفصل
                      </option>
                      {this.state.chapters?.length &&
                        this.state.chapters?.map((row) => (
                          <option value={row.id}>{row.nameAr}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <select
                      value={this.state.onLectureChange}
                      className="form-control"
                      aria-label="Default select example"
                      onChange={this.handleChangeChildrenTopic}
                      name="onLectureChange"
                    >
                      <option value={0} selected disabled>
                        اختر المحاضرة
                      </option>
                      {this.state.lectures &&
                        this.state.lectures.map((row) => (
                          <option value={row.id}>{row.nameAr}</option>
                        ))}
                    </select>
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <Field
                    name="classification"
                    component={selectField}
                    className="form-control"
                    placeholder="المفاهيم الاساسية"
                    onChange={this.handleChangeSubject}
                  >
                    <option value={0}>اختر مفهوم</option>
                    {this.state.childrenTopics?.length &&
                      this.state.childrenTopics?.map((row) => (
                        <option value={row.id}>{row.name}</option>
                      ))}
                  </Field>
                </div> */}

                <div className="col-12 text-center">
                  <button
                    className="btn light-outline-btn mt-3 w-25"
                    disabled={this.state.disabled}
                  >
                    {this.state.loading == true ? (
                      <Loader type="ball-clip-rotate" />
                    ) : (
                      "إنشاء"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.Exam && state.form.Exam.values,
  };
}

ExamComponent = reduxForm({
  form: "Exam",
  validate,
})(ExamComponent);

ExamComponent = connect(mapStateToProps)(ExamComponent);

export const Exam = withRouter(ExamComponent);
