import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";

class Editor extends Component {
  render() {
    return (
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                "heading",
                "MathType",
                "ChemType",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                // "imageUpload",
                // "mediaEmbed",
                "insertTable",
                "blockQuote",
                "undo",
                "redo",
              ],
            },
            language: "ar",
          }}
          data={this.props.editorData || ""}
          onChange={(event, editor) =>
            this.props.onChange(
              this.props.code,
              editor.getData(),
              this.props.subCodeKey,
              this.props.subCodeValue,
              this.props.stateKey
            )
          }
        />
      </div>
    );
  }
}

export default Editor;
