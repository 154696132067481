import React, { Component } from "react";
import { Table, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import { setQuestionsListActiveTab, setQuestionsListcourseId, setQuestionDetailsId } from "../../../../actions/index";
import Loader from "react-loaders";
import CustomTooltip from "./custom-tooltip/custom-tooltip";
import { connect } from "react-redux";
import { BsSearch } from "react-icons/bs";
import swal from "sweetalert";

var moment = require("moment");
moment().format();

class AskQuestionsListComponent extends Component {
  limit = 10;
  page = 1;
  page_withSearch = 1;
  unAnswerdQuestionsLimit = 10;
  unAnswerdQuestionPage = 1;
  unAnswerdQuestionPage_withSearch = 1;
  UnderDiscussionQuestionsLimit = 10;
  UnderDiscussionQuestionPage = 1;
  UnderDiscussionQuestionPage_withSearch = 1;
  endOfResults = false;
  endOfAlllQuestionsResults = false;
  endOfUnderDiscussionQuestionsResults = false;
  laodMoreAnsweredRefBtn = React.createRef()
  laodMoreUnansweredRefBtn = React.createRef()
  laodMoreUnderDiscussionRefBtn = React.createRef()
  shadyQuestionRef = React.createRef()
  state = {
    isِAddQuestionOpen: false,
    unAnswerdQuestions: [],
    answerdQuestions: [],
    anderDiscussionQuestions: [],
    // activeTab: "1",
    hideBtn: false,
    isShadyQuestionExist: -10,
    numberOfUnAnsweredQuestions: 0,
    numberOfAnsweredQuestions: 0,
    numberOfUnderDiscussionQuestions: 0,
    hideAllQuestionsBtn: false,
    hideAnderDiscussionQuestionsBtn: false,
    textSearchValue: "",
    studentSearchValue: "",
    searchType: "",
    UnAnsweredsearchedQuestionNumber: "",
    AnsweredsearchedQuestionNumber: "",
    UnderDiscussionsearchedQuestionNumber: "",
    searchedQuestionNumber: "",
    displayedSearchedQuestionNumber: "",
    term: "",
    searchedInUnasnwered: false,
    searchedInAsnwered: false,
    searchedInUnderDiscussion: false,
    disabled: false,
    isAnswerdPageLoading: false,
    isUnanswerdPageLoading: false,
    isAnderDiscussionPageLoading: false,
    //API for Answered Questions
    nextPageUrl: `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=Answered&page=${this.page}&limit=${this.limit}`,
    //API for UnAnswered Questions
    nextUnAnswerdQuestionPageUrl: `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=NotAnswered&page=${this.unAnswerdQuestionPage}&limit=${this.unAnswerdQuestionsLimit}`,
    //API for Ander-Discussion Questions
    nextAnderDiscussionQuestionPageUrl: `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=underDiscussion&page=${this.UnderDiscussionQuestionPage}&limit=${this.UnderDiscussionQuestionsLimit}`,
  };

  toggle(tab) {
    // if (this.state.activeTab !== tab) {
    //   this.setState({ activeTab: tab });
    // }
    if (this.props.activeTab !== tab) {
      this.props.setQuestionsListActiveTab(tab, this.props.match.params.id);
      this.setState({
        textSearchValue: "",
        studentSearchValue: "",
        searchType: "",
        term: "",
        searchedQuestionNumber: "",
        displayedSearchedQuestionNumber: "",
      })
      if(tab == "1" && this.state.searchedInUnasnwered){
        this.handleShowAllUnansweredWithoutSearch_Btn()
      }
      if(tab == "2" && this.state.searchedInAsnwered){
        this.handleShowAllAnsweredWithoutSearch_Btn()
      }
      if(tab == "3" && this.state.searchedInUnderDiscussion){
        this.handleShowAllUnderDiscussionWithoutSearch_Btn()
      }
    }
  }

  openAddQuestionModal = () => {
    this.setState({ isِAddQuestionOpen: true });
  };
  closeAddQuestionModal = () => {
    this.setState({ isِAddQuestionOpen: false });
  };

  toggleModal = () => {
    this.setState({ isِAddQuestionOpen: !this.state.isِAddQuestionOpen });
  };

  componentDidMount() {
    this.props.setQuestionsListcourseId(this.props.match.params.id)
    //Load Answered Questions
    this.loadMore();
    //Load Unanswered Questions
    this.loadAllQuestios();
    //Load Under-Discussion Questions
    this.loadUnderDiscussionQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.unAnswerdQuestions !== prevState.unAnswerdQuestions){
     this.scrollToShadyQuestion()
      this.setFlashBtn(this.state.unAnswerdQuestions, "1", this.laodMoreUnansweredRefBtn)
    }

    if(this.state.answerdQuestions !== prevState.answerdQuestions){
      this.scrollToShadyQuestion()
      this.setFlashBtn(this.state.answerdQuestions, "2", this.laodMoreAnsweredRefBtn)
    }

    if(this.state.anderDiscussionQuestions !== prevState.anderDiscussionQuestions){
      this.scrollToShadyQuestion()
      this.setFlashBtn(this.state.anderDiscussionQuestions, "3", this.laodMoreUnderDiscussionRefBtn)
    }
  }

  setFlashBtn(nowQuestionsList, tab, refBtn){
    if(this.props.activeTab == tab && this.props.questionDetailsId && nowQuestionsList.length>0){
      const shady_Question = nowQuestionsList.findIndex(question => question.id == this.props.questionDetailsId)
      this.setState({isShadyQuestionExist: shady_Question})
      if(shady_Question == -1 && refBtn.current){
        refBtn.current.scrollIntoView({ behavior: 'smooth' })
      }

    }
  }

  scrollToShadyQuestion(){
    if(this.shadyQuestionRef.current){
      this.shadyQuestionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  

  //Function To Get Answered Questions
  // loadMore = async () => {
  //   const courseId = this.props.match.params.id;
  //   let token = localStorage.getItem("token");
  //   let headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   this.setState({ loading: true, disabled: true, isAnswerdPageLoading: true });
  //   if (!this.endOfResults) {
  //     axios
  //       .get(this.state.nextPageUrl, { headers })
  //       .then((response) => {
  //         this.setState({ 
  //           loading: false, 
  //           disabled: false, 
  //           isAnswerdPageLoading: false,
  //           numberOfAnsweredQuestions: response.data.data.numberOfAnsweredQuestions,
  //         });
  //         const paginationAnswerdQuestions = [
  //           ...this.state.answerdQuestions,
  //           ...response.data.data.data,
  //         ];
  //         this.endOfResults = response.data.data.data.itemCount < this.limit;
  //         this.page++;
  //         const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=Answered&page=${this.page}&limit=${this.limit}`;
  //         this.setState({
  //           answerdQuestions: paginationAnswerdQuestions,
  //           nextPageUrl: nextUrl,
  //         });
  //         if (paginationAnswerdQuestions.length == response.data.data.itemCount) {
  //           this.setState({ hideBtn: true });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         this.setState({ loading: false, disabled: false, isAnswerdPageLoading: false });
  //       });
  //   }
  // };
  loadMore = async () => {
    this.page_withSearch = 1
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true, isAnswerdPageLoading: true });
    let url = this.state.nextPageUrl
    if(this.page == 1){
      this.endOfResults = false
      url = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=Answered&page=${this.page}&limit=${this.limit}`
      this.setState({answerdQuestions: [], textSearchValue: "", studentSearchValue: "",})
    }
    if (!this.endOfResults) {
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({ 
            loading: false, 
            disabled: false, 
            isAnswerdPageLoading: false,
            numberOfAnsweredQuestions: response.data.data.numberOfAnsweredQuestions,
          });
          const paginationAnswerdQuestions = [
            ...this.state.answerdQuestions,
            ...response.data.data.data,
          ];
          this.endOfResults = response.data.data.data.itemCount < this.limit;
          this.page++;
          const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=Answered&page=${this.page}&limit=${this.limit}`;
          this.setState({
            answerdQuestions: paginationAnswerdQuestions,
            nextPageUrl: nextUrl,
          });
          if (paginationAnswerdQuestions.length == response.data.data.itemCount) {
            this.setState({ hideBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isAnswerdPageLoading: false });
        });
    }
  };

  //Function To Get UnAnswered Questions
  // loadAllQuestios = async () => {
  //   console.log("this.unAnswerdQuestionPage: ", this.unAnswerdQuestionPage);
  //   this.unAnswerdQuestionPage_withSearch = 1
  //   const courseId = this.props.match.params.id;
  //   let token = localStorage.getItem("token");
  //   let headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   this.setState({ loading: true, disabled: true, isUnanswerdPageLoading: true, });
  //   if(this.unAnswerdQuestionPage == 1){
  //     this.endOfAlllQuestionsResults = false 
  //   }
  //   if (!this.endOfAlllQuestionsResults) {
  //     console.log("this.state.nextUnAnswerdQuestionPageUrl: ", this.state.nextUnAnswerdQuestionPageUrl);
  //     axios
  //       .get(this.state.nextUnAnswerdQuestionPageUrl, { headers })
  //       .then((response) => {
  //         this.setState({
  //           loading: false,
  //           disabled: false,
  //           isUnanswerdPageLoading: false,
  //           numberOfUnAnsweredQuestions:
  //             response.data.data.numberOfUnAnsweredQuestions,
  //         });
  //         const pagenationUnAnswerdQuestions = [
  //           ...this.state.unAnswerdQuestions,
  //           ...response.data.data.data,
  //         ];
  //         this.endOfAlllQuestionsResults =
  //           response.data.data.data.itemCount < this.limit;
  //         this.unAnswerdQuestionPage++;
  //         const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=NotAnswered&page=${this.unAnswerdQuestionPage}&limit=${this.unAnswerdQuestionsLimit}`;
  //         this.setState({
  //           unAnswerdQuestions: pagenationUnAnswerdQuestions,
  //           nextUnAnswerdQuestionPageUrl: nextUrl,
  //         });
  //         if (pagenationUnAnswerdQuestions.length == response.data.data.itemCount) {
  //           this.setState({ hideAllQuestionsBtn: true });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         this.setState({ loading: false, disabled: false, isUnanswerdPageLoading: false, });
  //       });
  //   }
  // };

  loadAllQuestios = async () => {
    this.unAnswerdQuestionPage_withSearch = 1
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true, isUnanswerdPageLoading: true, });
    let url = this.state.nextUnAnswerdQuestionPageUrl
    if(this.unAnswerdQuestionPage == 1){
      this.endOfAlllQuestionsResults = false
      url = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=NotAnswered&page=${this.unAnswerdQuestionPage}&limit=${this.unAnswerdQuestionsLimit}`
      this.setState({unAnswerdQuestions: [], textSearchValue: "", studentSearchValue: "",})
    }
    if (!this.endOfAlllQuestionsResults) {
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({
            loading: false,
            disabled: false,
            isUnanswerdPageLoading: false,
            numberOfUnAnsweredQuestions:
              response.data.data.numberOfUnAnsweredQuestions,
          });
          const pagenationUnAnswerdQuestions = [
            ...this.state.unAnswerdQuestions,
            ...response.data.data.data,
          ];
          this.endOfAlllQuestionsResults =
            response.data.data.data.itemCount < this.limit;
          this.unAnswerdQuestionPage++;
          const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=NotAnswered&page=${this.unAnswerdQuestionPage}&limit=${this.unAnswerdQuestionsLimit}`;
          this.setState({
            unAnswerdQuestions: pagenationUnAnswerdQuestions,
            nextUnAnswerdQuestionPageUrl: nextUrl,
          });
          if (pagenationUnAnswerdQuestions.length == response.data.data.itemCount) {
            this.setState({ hideAllQuestionsBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isUnanswerdPageLoading: false, });
        });
    }
  };
  
  //Function To Get Under-Discussion Questions
  // loadUnderDiscussionQuestions = async () => {
  //   const courseId = this.props.match.params.id;
  //   let token = localStorage.getItem("token");
  //   let headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   this.setState({ loading: true, disabled: true, isAnderDiscussionPageLoading: true, });
  //   if (!this.endOfUnderDiscussionQuestionsResults) {
  //     axios
  //       .get(this.state.nextAnderDiscussionQuestionPageUrl, { headers })
  //       .then((response) => {
  //         this.setState({
  //           loading: false,
  //           disabled: false,
  //           isAnderDiscussionPageLoading: false,
  //           numberOfUnderDiscussionQuestions:
  //             response.data.data.numberOfUnderDiscussionQuestions,
  //         });
  //         const pagenationAnderDiscussionQuestions = [
  //           ...this.state.anderDiscussionQuestions,
  //           ...response.data.data.data,
  //         ];
  //         this.endOfUnderDiscussionQuestionsResults =
  //           response.data.data.data.itemCount < this.limit;
  //         this.UnderDiscussionQuestionPage++;
  //         const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=underDiscussion&page=${this.UnderDiscussionQuestionPage}&limit=${this.UnderDiscussionQuestionsLimit}`;
  //         this.setState({
  //           anderDiscussionQuestions: pagenationAnderDiscussionQuestions,
  //           nextAnderDiscussionQuestionPageUrl: nextUrl,
  //         });
  //         if (pagenationAnderDiscussionQuestions.length == response.data.data.itemCount) {
  //           this.setState({ hideAnderDiscussionQuestionsBtn: true });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         this.setState({ loading: false, disabled: false, isAnderDiscussionPageLoading: false, });
  //       });
  //   }
  // };
  loadUnderDiscussionQuestions = async () => {
    this.UnderDiscussionQuestionPage_withSearch = 1
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true, isAnderDiscussionPageLoading: true, });
    let url = this.state.nextAnderDiscussionQuestionPageUrl
    if(this.UnderDiscussionQuestionPage == 1){
      this.endOfUnderDiscussionQuestionsResults = false
      url = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=underDiscussion&page=${this.UnderDiscussionQuestionPage}&limit=${this.UnderDiscussionQuestionsLimit}`
      this.setState({anderDiscussionQuestions: [], textSearchValue: "", studentSearchValue: "",})
    }
    if (!this.endOfUnderDiscussionQuestionsResults) {
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({
            loading: false,
            disabled: false,
            isAnderDiscussionPageLoading: false,
            numberOfUnderDiscussionQuestions:
              response.data.data.numberOfUnderDiscussionQuestions,
          });
          const pagenationAnderDiscussionQuestions = [
            ...this.state.anderDiscussionQuestions,
            ...response.data.data.data,
          ];
          this.endOfUnderDiscussionQuestionsResults =
            response.data.data.data.itemCount < this.limit;
          this.UnderDiscussionQuestionPage++;
          const nextUrl = `${apiBaseUrl}/AskQuestions?courseId=${courseId}&QuestionsStatus=underDiscussion&page=${this.UnderDiscussionQuestionPage}&limit=${this.UnderDiscussionQuestionsLimit}`;
          this.setState({
            anderDiscussionQuestions: pagenationAnderDiscussionQuestions,
            nextAnderDiscussionQuestionPageUrl: nextUrl,
          });
          if (pagenationAnderDiscussionQuestions.length == response.data.data.itemCount) {
            this.setState({ hideAnderDiscussionQuestionsBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isAnderDiscussionPageLoading: false, });
        });
    }
  };

  // ################# Start search functions ######################
  handleChangeStudentNameField = (value) =>{
    this.setState({studentSearchValue: value, textSearchValue: "", searchedQuestionNumber: "",})
  }
  handleChangeTextField = (value) =>{
    this.setState({textSearchValue: value, studentSearchValue: "", searchedQuestionNumber: "",})
  }
  handleChangeQuestionNumField = (value) =>{
    // if(this.props.activeTab == "1"){
    //   if(+value <= 0 || !Number.isInteger(+value)) {
    //     this.setState({UnAnsweredsearchedQuestionNumber: ""})
    //   }
    //   else{
    //     this.setState({UnAnsweredsearchedQuestionNumber: value})
    //   }
    // }
    // if(this.props.activeTab == "2"){
    //   if(+value <= 0 || !Number.isInteger(+value)) {
    //     this.setState({AnsweredsearchedQuestionNumber: ""})
    //   }
    //   else{
    //     this.setState({AnsweredsearchedQuestionNumber: value})
    //   }
    // }
    // if(this.props.activeTab == "3"){
    //   if(+value <= 0 || !Number.isInteger(+value)) {
    //     this.setState({UnderDiscussionsearchedQuestionNumber: ""})
    //   }
    //   else{
    //     this.setState({UnderDiscussionsearchedQuestionNumber: value})
    //   }
    // }

    if(+value <= 0 || !Number.isInteger(+value)) {
      this.setState({searchedQuestionNumber: ""})
    }
    else{
      this.setState({searchedQuestionNumber: value, textSearchValue: "", studentSearchValue: "",})
    }
  }

  loadAllQuestios_withSearch = async (searchType, term) => {
    this.unAnswerdQuestionPage = 1;
    if(this.state.searchType == "StudentName"){
      this.setState({textSearchValue: ""})
    }
    else if(this.state.searchType == "questionText"){
      this.setState({studentSearchValue: "",})
    }
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({searchType, term})
    this.setState({ loading: true, disabled: true, isUnanswerdPageLoading: true, });
    if(this.unAnswerdQuestionPage_withSearch == 1){
      this.endOfAlllQuestionsResults = false 
    }
    if (!this.endOfAlllQuestionsResults) {
      const url = `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=NotAnswered&QuestionSearchBy=${searchType}&SearchTrem=${term}&page=${this.unAnswerdQuestionPage_withSearch}&limit=${this.unAnswerdQuestionsLimit}`
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({
            loading: false,
            disabled: false,
            isUnanswerdPageLoading: false,
            numberOfUnAnsweredQuestions:
              response.data.data.numberOfUnAnsweredQuestions,
          });
          const pagenationUnAnswerdQuestions = this.unAnswerdQuestionPage_withSearch == 1? response.data.data.data :
           [ ...this.state.unAnswerdQuestions,  ...response.data.data.data, ];
           this.setState({
             unAnswerdQuestions: pagenationUnAnswerdQuestions,
           });
          this.endOfAlllQuestionsResults =
            response.data.data.data.itemCount < this.limit;
          this.unAnswerdQuestionPage_withSearch++;
          if (pagenationUnAnswerdQuestions.length == response.data.data.itemCount) {
            this.setState({ hideAllQuestionsBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isUnanswerdPageLoading: false, });
        });
    }
  };
  loadMore_withSearch = async (searchType, term) => {
    this.page = 1;
    if(this.state.searchType == "StudentName"){
      this.setState({textSearchValue: "",})
    }
    else if(this.state.searchType == "questionText"){
      this.setState({studentSearchValue: "",})
    }
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({searchType, term})
    this.setState({ loading: true, disabled: true, isAnswerdPageLoading: true, });
    if(this.page_withSearch == 1){
      this.endOfResults = false 
    }
    if (!this.endOfResults) {
      const url = `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=Answered&QuestionSearchBy=${searchType}&SearchTrem=${term}&page=${this.page_withSearch}&limit=${this.limit}`
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({
            loading: false,
            disabled: false,
            isAnswerdPageLoading: false,
            numberOfAnsweredQuestions:
              response.data.data.numberOfAnsweredQuestions,
          });
          const paginationAnswerdQuestions = this.page_withSearch == 1? response.data.data.data :
           [ ...this.state.answerdQuestions,  ...response.data.data.data, ];
           this.setState({
             answerdQuestions: paginationAnswerdQuestions,
           });
          this.endOfResults =
            response.data.data.data.itemCount < this.limit;
          this.page_withSearch++;
          if (paginationAnswerdQuestions.length == response.data.data.itemCount) {
            this.setState({ hideBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isAnswerdPageLoading: false, });
        });
    }
  };
  loadUnderDiscussionQuestions_withSearch = async (searchType, term) => {
    this.UnderDiscussionQuestionPage = 1;
    if(this.state.searchType == "StudentName"){
      this.setState({textSearchValue: "",})
    }
    else if(this.state.searchType == "questionText"){
      this.setState({studentSearchValue: "",})
    }
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({searchType, term})
    this.setState({ loading: true, disabled: true, isAnderDiscussionPageLoading: true, });
    if(this.UnderDiscussionQuestionPage_withSearch == 1){
      this.endOfUnderDiscussionQuestionsResults = false 
    }
    if (!this.endOfUnderDiscussionQuestionsResults) {
      const url = `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=underDiscussion&QuestionSearchBy=${searchType}&SearchTrem=${term}&page=${this.UnderDiscussionQuestionPage_withSearch}&limit=${this.UnderDiscussionQuestionsLimit}`
      axios
        .get(url, { headers })
        .then((response) => {
          this.setState({
            loading: false,
            disabled: false,
            isAnderDiscussionPageLoading: false,
            numberOfUnderDiscussionQuestions:
              response.data.data.numberOfUnderDiscussionQuestions,
          });
          const pagenationAnderDiscussionQuestions = this.UnderDiscussionQuestionPage_withSearch == 1? response.data.data.data :
           [ ...this.state.anderDiscussionQuestions,  ...response.data.data.data, ];
           this.setState({
             anderDiscussionQuestions: pagenationAnderDiscussionQuestions,
           });
          this.endOfUnderDiscussionQuestionsResults =
            response.data.data.data.itemCount < this.limit;
          this.UnderDiscussionQuestionPage_withSearch++;
          if (pagenationAnderDiscussionQuestions.length == response.data.data.itemCount) {
            this.setState({ hideAnderDiscussionQuestionsBtn: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false, disabled: false, isAnderDiscussionPageLoading: false, });
        });
    }
  };

  searchWithQuestionNumber = async (number) =>{
    
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    this.setState({ loading: true, disabled: true, searchType: "" });

    let type;
    if(this.props.activeTab == "1"){
      if(+number > this.state.numberOfUnAnsweredQuestions){
        swal("رقم سؤال غير موجود", {
          buttons: false,
          icon: "error",
          timer: 2000,
        });
        return;
      }
      type = 'NotAnswered'
      this.setState({isUnanswerdPageLoading : true})
    }
    else if(this.props.activeTab == "2"){
      if(+number > this.state.numberOfAnsweredQuestions){
        swal("رقم سؤال غير موجود", {
          buttons: false,
          icon: "error",
          timer: 2000,
        });
        return;
      }
      type = 'Answered'
      this.setState({page : true})
    }
    else{
      if(+number > this.state.numberOfUnderDiscussionQuestions){
        swal("رقم سؤال غير موجود", {
          buttons: false,
          icon: "error",
          timer: 2000,
        });
        return;
      }
      type = 'underDiscussion'
      this.setState({isAnderDiscussionPageLoading : true})
    }

    const url = `${apiBaseUrl}/AskQuestions?courseId=${this.props.match.params.id}&QuestionsStatus=${type}&page=${+number}&limit=${1}`
    axios
      .get(url, { headers })
      .then((response) => {
        this.setState({
          loading: false,
          disabled: false,
          displayedSearchedQuestionNumber: +number,
          searchedQuestionNumber: "",
        });
        if(this.props.activeTab == "1"){
          this.setState({
            isUnanswerdPageLoading : false, 
            unAnswerdQuestions: response.data.data.data,
            numberOfAnsweredQuestions: response.data.data.numberOfAnsweredQuestions,
            hideAllQuestionsBtn: true,
            searchedInUnasnwered: true,
            searchedInAsnwered: false,
            searchedInUnderDiscussion: false,          })
        }
        else if(this.props.activeTab == "2"){
          type = 'Answered'
          this.setState({
            page : false,
            answerdQuestions: response.data.data.data,
            numberOfAnsweredQuestions: response.data.data.numberOfAnsweredQuestions,
            hideBtn: true,
            searchedInUnasnwered: false,
            searchedInAsnwered: true,
            searchedInUnderDiscussion: false,
          })
        }
        else{
          type = 'underDiscussion'
          this.setState({
            isAnderDiscussionPageLoading : false,
            anderDiscussionQuestions: response.data.data.data,
            numberOfUnderDiscussionQuestions:
              response.data.data.numberOfUnderDiscussionQuestions,
            hideAnderDiscussionQuestionsBtn: true,
            searchedInUnasnwered: false,
            searchedInAsnwered: false,
            searchedInUnderDiscussion: true,

          })
        }
                
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, disabled: false, isUnanswerdPageLoading: false, isAnderDiscussionPageLoading : false, page: false});
      });
  }

  searchInUnansweredQuestions(searchType, term){
    this.setState({
      hideAllQuestionsBtn: false,
      searchedInUnasnwered: true,
      searchedInAsnwered: false,
      searchedInUnderDiscussion: false,
      textSearchValue: "", 
      studentSearchValue: "",
      displayedSearchedQuestionNumber: "",
    })
    this.unAnswerdQuestionPage_withSearch = 1
    this.loadAllQuestios_withSearch(searchType, term);
  }
  searchInAnsweredQuestions(searchType, term){
    this.setState({
      hideBtn: false,
      searchedInUnasnwered: false,
      searchedInAsnwered: true,
      searchedInUnderDiscussion: false,
      textSearchValue: "", 
      studentSearchValue: "",
      displayedSearchedQuestionNumber: "",
    })
    this.page_withSearch = 1
    this.loadMore_withSearch(searchType, term);
  }
  searchInUnderDiscussionQuestions(searchType, term){
    this.setState({
      hideAnderDiscussionQuestionsBtn: false,
      searchedInUnasnwered: false,
      searchedInAsnwered: false,
      searchedInUnderDiscussion: true,
      textSearchValue: "", 
      studentSearchValue: "",
      displayedSearchedQuestionNumber: "",
    })
    this.UnderDiscussionQuestionPage_withSearch = 1
    this.loadUnderDiscussionQuestions_withSearch(searchType, term);
  }

  handleShowAllUnansweredWithoutSearch_Btn(){
    this.setState({
      hideAllQuestionsBtn: false, 
      searchType: "", 
      searchedInUnasnwered: false,
      displayedSearchedQuestionNumber: "",
    })
    this.unAnswerdQuestionPage = 1
    this.loadAllQuestios()
  }
  handleShowAllAnsweredWithoutSearch_Btn(){
    this.setState({
      hideBtn: false, 
      searchType: "",
      searchedInAsnwered: false,
      displayedSearchedQuestionNumber: "",
    })
    this.page = 1
    this.loadMore()
  }
  handleShowAllUnderDiscussionWithoutSearch_Btn(){
    this.setState({
      hideAnderDiscussionQuestionsBtn: false, 
      searchType: "",
      searchedInUnderDiscussion: false,
      displayedSearchedQuestionNumber: "",
    })
    this.UnderDiscussionQuestionPage = 1
    this.loadUnderDiscussionQuestions()
  }

  // ################# End search functions ######################


  renderAnswaerdQuestions() {
    const answerdQuestions = this.state.answerdQuestions;
    if (answerdQuestions) {
      return answerdQuestions.map((answerdQuestion, index) => {
        return (
          <>
            <tr ref={answerdQuestion.id == this.props.questionDetailsId&& this.shadyQuestionRef} className={`text-center w-100 ${answerdQuestion.id == this.props.questionDetailsId? "shady_question" : ""}`}>
              <td className="silver-bg dark-silver-text small">
                <span className="TheSansArabMedium-font">
                  { this.state.displayedSearchedQuestionNumber != "" ?
                  this.state.displayedSearchedQuestionNumber :
                  index + 1
                  }
                </span>
              </td>
              <td className="w-33 TheSansArabMedium-font dark-silver-text small">
                <div className="d-flex">
                <CustomTooltip isPrivate={answerdQuestion.isPrivate} isLeft />
                  { answerdQuestion.type === "Both" ? (
                    <div className="d-flex flex-column align-items-center w-100">
                      <img
                        src={answerdQuestion.imageUrl}
                        width="100"
                        className="contain-img"
                        alt={`answerdQuestion-No:${index + 1}`}
                      />
                      <p className="TheSansArabMedium-font w-100">
                      {answerdQuestion.content.length > 30? answerdQuestion.content.slice(0,30).concat("...") : answerdQuestion.content}
                      </p>
                    </div>
                  ) : answerdQuestion.type === "Image" ? (
                    <img
                      src={answerdQuestion.imageUrl? answerdQuestion.imageUrl: answerdQuestion.content}
                      width="100"
                      className="contain-img"
                      alt={`answerdQuestion-No:${index + 1}`}
                    />
                  ) : (
                      <p className="TheSansArabMedium-font w-100">
                        {answerdQuestion.content.length > 30? answerdQuestion.content.slice(0,30).concat("...") : answerdQuestion.content}
                        </p>
                    )}
                </div>
              </td>
              <td className="w-25 dark-silver-text small d-none">
                <p className="TheSansArabMedium-font w-100">
                  {answerdQuestion.isAnswered === true
                    ? "تمت الاجابة"
                    : "قيد المراجعة"}
                </p>
              </td>
              <td className="w-33 dark-silver-text small">
                <p className="TheSansArabMedium-font">{answerdQuestion.studentName}</p>
              </td>
              <td className="w-33 dark-silver-text small">
                <p className="TheSansArabMedium-font">{moment(answerdQuestion.createdAt).format("YYYY/MM/DD HH:mm")}</p>
              </td>
              <td className="w-33 light-font-text dark-silver-text small">
                <Link
                  className="btn dark-outline-btn w-md-50"
                  to={`/courses/${this.props.match.params.id}/askQuestions/details/${answerdQuestion.id}`}
                >
                  تفاصيل
                </Link>
              </td>
            </tr>
          </>
        );
      });
    }
  }

  renderUnAnswerdQuestions() {
    const unAnswerdQuestions = this.state.unAnswerdQuestions;
    if (unAnswerdQuestions) {
      return unAnswerdQuestions.map((unAnswerdQuestion, index) => {
        return (
          <tr className={`text-center w-100 ${unAnswerdQuestion.id == this.props.questionDetailsId? "shady_question" : ""}`}>
            <td className="silver-bg dark-silver-text small">
              <span className="TheSansArabMedium-font">
              { this.state.displayedSearchedQuestionNumber != "" ?
              this.state.displayedSearchedQuestionNumber :
              index + 1
              }
              </span>
            </td>
            <td className="w-33 TheSansArabMedium-font dark-silver-text small">
            <div className="d-flex">
              <CustomTooltip isPrivate={unAnswerdQuestion.isPrivate} isLeft />
              {unAnswerdQuestion.type === "Both" ? (
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={unAnswerdQuestion.imageUrl}
                    width="100"
                    className="contain-img"
                    alt={`unAnswerdQuestion-No:${index + 1}`}
                  />
                  <p className="TheSansArabMedium-font w-100">
                  {unAnswerdQuestion.content.length > 25? unAnswerdQuestion.content.slice(0,25).concat("...") : unAnswerdQuestion.content}
                  </p>
                </div>
              ) : unAnswerdQuestion.type === "Image" ? (
                <img
                  src={unAnswerdQuestion.imageUrl? unAnswerdQuestion.imageUrl : unAnswerdQuestion.content}
                  width="100"
                  className="contain-img"
                  alt={`unAnswerdQuestion-No:${index + 1}`}
                />
              ) : (
                  <p className="TheSansArabMedium-font w-100">
                    {unAnswerdQuestion.content.length > 25? unAnswerdQuestion.content.slice(0,25).concat("...") : unAnswerdQuestion.content}
                  </p>
                )}
            </div>
            </td>
            <td className="w-25 dark-silver-text small d-none">
              <p className="TheSansArabMedium-font w-100">
                {unAnswerdQuestion.isAnswered === true
                  ? "تمت الاجابة"
                  : "قيد المراجعة"}
              </p>
            </td>
            <td className="w-33 dark-silver-text small">
              <span className="TheSansArabMedium-font">{unAnswerdQuestion.studentName}</span>
            </td>
            <td className="w-33 dark-silver-text small">
              <span className="TheSansArabMedium-font">{moment(unAnswerdQuestion.createdAt).format("YYYY/MM/DD HH:mm")}</span>
            </td>
            <td className="w-33 light-font-text dark-silver-text small">
              <Link
                className="btn dark-outline-btn w-md-50"
                to={`/courses/${this.props.match.params.id}/askQuestions/details/${unAnswerdQuestion.id}`}
              >
                تفاصيل
              </Link>
            </td>
          </tr>
        );
      });
    }
  }

  renderUnderDiscussionQuestions() {
    const anderDiscussionQuestions = this.state.anderDiscussionQuestions;
    if (anderDiscussionQuestions) {
      return anderDiscussionQuestions.map((anderDiscussionQuestion, index) => {
        return (
          <tr className={`text-center w-100 ${anderDiscussionQuestion.id == this.props.questionDetailsId? "shady_question" : ""}`}>
            <td className="silver-bg dark-silver-text small">
              <span className="TheSansArabMedium-font">
              { this.state.displayedSearchedQuestionNumber != "" ?
              this.state.displayedSearchedQuestionNumber :
              index + 1
              }
              </span>
            </td>
            <td className="w-33 TheSansArabMedium-font dark-silver-text small">
            <div className="d-flex">
            <CustomTooltip isPrivate={anderDiscussionQuestion.isPrivate} isLeft />
              {anderDiscussionQuestion.type === "Both" ? (
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={anderDiscussionQuestion.imageUrl}
                    width="100"
                    className="contain-img"
                    alt={`anderDiscussionQuestion-No:${index + 1}`}
                  />
                  <p className="TheSansArabMedium-font w-100">
                  {anderDiscussionQuestion.content.length > 25? anderDiscussionQuestion.content.slice(0,25).concat("...") : anderDiscussionQuestion.content}
                  </p>
                </div>
              ) : anderDiscussionQuestion.type === "Image" ? (
                <img
                  src={anderDiscussionQuestion.imageUrl? anderDiscussionQuestion.imageUrl : anderDiscussionQuestion.content}
                  width="100"
                  className="contain-img"
                  alt={`anderDiscussionQuestion-No:${index + 1}`}
                />
              ) : (
                  <p className="TheSansArabMedium-font w-100">
                    {anderDiscussionQuestion.content.length > 25? anderDiscussionQuestion.content.slice(0,25).concat("...") : anderDiscussionQuestion.content}
                  </p>
              )}
            </div>
            </td>
            <td className="w-25 dark-silver-text small d-none">
              <p className="TheSansArabMedium-font w-100">
                {anderDiscussionQuestion.isAnswered === true
                  ? "تمت الاجابة"
                  : "قيد المراجعة"}
              </p>
            </td>
            <td className="w-33 dark-silver-text small">
              <span className="TheSansArabMedium-font">{anderDiscussionQuestion.studentName}</span>
            </td>
            <td className="w-33 dark-silver-text small">
              <span className="TheSansArabMedium-font">{moment(anderDiscussionQuestion.createdAt).format("YYYY/MM/DD HH:mm")}</span>
            </td>
            <td className="w-33 light-font-text dark-silver-text small">
              <Link
                className="btn dark-outline-btn w-md-50"
                to={`/courses/${this.props.match.params.id}/askQuestions/details/${anderDiscussionQuestion.id}`}
              >
                تفاصيل
              </Link>
            </td>
          </tr>
        );
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="pb-5">
          <Nav tabs>
            <div className="d-flex">
              <NavItem>
                <NavLink
                  className={`${classnames({
                    active: this.props.activeTab === "1",
                  })} ${"clickable"}`}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  <h6 className="dark-text small mb-0 mt-0">
                    الأسئلة غير المجابة{" "}
                    <span className="text-danger">
                      ({this.state.numberOfUnAnsweredQuestions})
                    </span>
                  </h6>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${classnames({
                    active: this.props.activeTab === "2",
                  })} ${"clickable"}`}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  <h6 className="dark-text small mb-0 mt-0">
                    الأسئلةالمجابة {" "}
                    <span className="text-danger">
                      ({this.state.numberOfAnsweredQuestions})
                    </span>
                  </h6>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${classnames({
                    active: this.props.activeTab === "3",
                  })} ${"clickable"}`}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  <h6 className="dark-text small mb-0 mt-0">
                    الأسئلة قيد المناقشة {" "}
                    <span className="text-danger">
                      ({this.state.numberOfUnderDiscussionQuestions})
                    </span>
                  </h6>
                </NavLink>
              </NavItem>
            </div>
          </Nav>

          <div className="row mt-3 px-3 search-section">
            <div className="d-flex align-items-center border rounded col-12 col-md-5 col-lg-3 px-0 mr-1 mb-1 ">
              <input
                className="exam-search-box border-0 w-80 px-2"
                value={this.state.textSearchValue}
                onChange={(e) => this.handleChangeTextField(e.target.value)}
                placeholder={`أدخل جزء من نص سؤال`}
              />
              <button
                className="btn dark-btn br-5 w-25"
                disabled={this.state.textSearchValue.trim() == ""}
                onClick={()=>{
                  this.setState({searchedQuestionNumber: ""})
                  this.props.activeTab == "1" ?
                  this.searchInUnansweredQuestions("questionText", this.state.textSearchValue) :
                  this.props.activeTab == "2" ?
                  this.searchInAnsweredQuestions("questionText", this.state.textSearchValue) :
                  this.searchInUnderDiscussionQuestions("questionText", this.state.textSearchValue)
                }}
              >
                <BsSearch className="main-color" />
              </button>
            </div>
            <div className="d-flex align-items-center border rounded col-12 col-md-5 col-lg-3 px-0 mr-1 mb-1 ">
              <input
                className="exam-search-box border-0 w-80 px-2"
                value={this.state.studentSearchValue}
                onChange={(e) => this.handleChangeStudentNameField(e.target.value)}
                placeholder={`أدخل جزء من اسم طالب`}
              />
              <button
                className="btn dark-btn br-5 w-25"
                disabled={this.state.studentSearchValue.trim() == ""}
                onClick={()=> {
                  this.setState({searchedQuestionNumber: ""})
                  this.props.activeTab == "1" ?
                  this.searchInUnansweredQuestions("StudentName", this.state.studentSearchValue) :
                  this.props.activeTab == "2" ?
                  this.searchInAnsweredQuestions("StudentName", this.state.studentSearchValue) :
                  this.searchInUnderDiscussionQuestions("StudentName", this.state.studentSearchValue)
                }}
              >
                <BsSearch className="main-color" />
              </button>
            </div>
            <div className="d-flex align-items-center border rounded col-12 col-md-5 col-lg-2 px-0 mr-1 mb-1 ">
              <input
                className="exam-search-box border-0 w-80 px-2"
                value={this.state.searchedQuestionNumber}
                onChange={(e) => this.handleChangeQuestionNumField(e.target.value)}
                placeholder={`أدخل رقم سؤال`}
              />
              <button
                className="btn dark-btn br-5 w-25"
                disabled={+this.state.searchedQuestionNumber <= 0 || !Number.isInteger(+this.state.searchedQuestionNumber)}
                onClick={()=> {
                  this.searchWithQuestionNumber(this.state.searchedQuestionNumber)
                }}
              >
                <BsSearch className="main-color" />
              </button>
            </div>
            <button
              className="btn dark-btn br-5 col-12 col-md-10 col-lg-3"
              disabled={false}
              onClick={()=>{
                this.setState({searchedQuestionNumber: ""})
                this.props.activeTab == "1" ?
                this.handleShowAllUnansweredWithoutSearch_Btn() :
                this.props.activeTab == "2" ?
                this.handleShowAllAnsweredWithoutSearch_Btn() :
                this.handleShowAllUnderDiscussionWithoutSearch_Btn()
              }}
            >
              عرض الكل بدون بحث
            </button>
          </div>
          {
            this.state.searchType == "questionText"? (
              <div className="search-result w-100 text-center mt-3">
                <p>
                    نتائج البحث عن الأسئلة التي تحتوي كلمة...  "
                    <span>
                    {this.state.term}
                    </span>
                  "
                </p>
              </div>
            ): this.state.searchType == "StudentName"? (
              <div className="search-result w-100 text-center mt-3">
                <p>
                    نتائج البحث عن أسماء الطلاب التي تحتوي كلمة...  "
                    <span>
                    {this.state.term}
                    </span>
                  "
                </p>
              </div>
            ):null
            
          }

          <TabContent activeTab={this.props.activeTab}>
            <TabPane tabId="1">
              {this.props.activeTab == 1 ?
               this.state.isUnanswerdPageLoading ? (
                <div
                  className="silver-bg box-layout w-100 pb-0 p-3 mt-1 d-flex justify-content-center align-items-center"
                  style={{ minHeight: 350 }}
                >
                  <Loader type="ball-spin-fade-loader" className="dark-loader" />
                </div>
               ):(
                <div className="row no-gutters">
                  {this.state.unAnswerdQuestions &&
                    this.state.unAnswerdQuestions.length !== 0 ? (
                      <div className="col-12">
                        <div className="box-layout shadow-sm w-100 table-responsive">
                          <Table className="mb-0 w-100">
                            <thead className="silver-bg w-100">
                              <tr className="text-center w-100">
                                <th className="dark-silver-text small border-0">
                                  م
                                </th>
                                <th className="w-33 dark-silver-text small border-0">
                                  السؤال
                                </th>
                                  <th className="w-25 dark-silver-text small border-0 d-none">
                                    الحالة
                                </th>
                                  <th className="w-33 dark-silver-text small border-0">
                                    اسم الطالب
                                </th>
                                <th className="w-33 dark-silver-text small border-0">
                                    تاريخ السؤال
                                </th>
                                  <th className="w-33 dark-silver-text small border-0">
                                    التفاصيل
                                </th>
                              </tr>
                            </thead>
                            <tbody className="w-100">{this.renderUnAnswerdQuestions()}</tbody>
                          </Table>
                          {!this.state.hideAllQuestionsBtn && (
                            <div className="col-12 d-flex  justify-content-center">
                              <button
                                className={`btn dark-btn unset-height unset-line-height br-5 mt-3 w-25 mb-3 ${(this.props.activeTab == "1" && this.state.isShadyQuestionExist == -1) ? "flash" : ""}`}
                                // onClick={this.loadAllQuestios}
                                onClick={()=>!this.state.searchedInUnasnwered ? this.loadAllQuestios() : this.loadAllQuestios_withSearch(this.state.searchType, this.state.term)}
                                disabled={false}
                                ref={this.laodMoreUnansweredRefBtn}
                              >
                                {this.state.loading == true ? (
                                  <Loader
                                    type="ball-beat"
                                    className="dark-loader"
                                  />
                                ) : (
                                    "تحميل المزيد"
                                  )}{" "}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          {/* <div
                            className="d-flex flex-column align-items-center justify-content-center shadow-sm silver-bg"
                            style={{ height: 80, background: "#F2F2F2" }}
                          >
                            <h5 className="dark-silver-text mt-0">
                              لا يوجد أسئلة غير مجابة
                          </h5>
                          </div> */}

                          <div
                            className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center mb-3"
                            style={{ height: 300 }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/no-questions.png"
                              }
                              height="80"
                              className="contain-img mb-3"
                              alt="no-questions-img"
                            />
                            <p className="dark-silver-text mt-0 mb-0">
                            لا توجد أسئلة غير مجابة
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                </div>
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              {this.props.activeTab == 2 ?
               this.state.isAnswerdPageLoading ? (
                <div
                className="silver-bg box-layout w-100 pb-0 p-3 mt-1 d-flex justify-content-center align-items-center"
                style={{ minHeight: 350 }}
              >
                <Loader type="ball-spin-fade-loader" className="dark-loader" />
                </div>
              ) : (
                <div className="row no-gutters">
                  {this.state.answerdQuestions &&
                    this.state.answerdQuestions.length !== 0 ? (
                      <div className="col-12">
                        <div className="box-layout shadow-sm w-100 table-responsive">
                          <Table className="mb-0 w-100">
                            <thead className="silver-bg w-100">
                              <tr className="text-center w-100">
                                <th className="dark-silver-text small border-0">
                                  م
                                </th>
                                <th className="w-33 dark-silver-text small border-0">
                                  السؤال
                              </th>
                                <th className="w-25 dark-silver-text small border-0 d-none">
                                  الحالة
                              </th>
                                <th className="w-33 dark-silver-text small border-0">
                                  اسم الطالب
                              </th>
                              <th className="w-33 dark-silver-text small border-0">
                                  تاريخ السؤال
                              </th>
                                <th className="w-33 dark-silver-text small border-0">
                                  التفاصيل
                              </th>
                              </tr>
                            </thead>
                            <tbody className="w-100">{this.renderAnswaerdQuestions()}</tbody>
                          </Table>
                          {!this.state.hideBtn && (
                            <div className="col-12 d-flex  justify-content-center">
                              <button
                                // className="btn dark-btn unset-height unset-line-height br-5 mt-3 w-25 mb-3"
                                className={`btn dark-btn unset-height unset-line-height br-5 mt-3 w-25 mb-3 ${(this.props.activeTab == "2" && this.state.isShadyQuestionExist == -1) ? "flash" : ""}`}
                                // onClick={this.loadMore}
                                onClick={()=>!this.state.searchedInAsnwered ? this.loadMore() : this.loadMore_withSearch(this.state.searchType, this.state.term)}
                                disabled={false}
                                ref={this.laodMoreAnsweredRefBtn}
                              >
                                {this.state.loading == true ? (
                                  <Loader
                                    type="ball-beat"
                                    className="dark-loader"
                                  />
                                ) : (
                                    "تحميل المزيد"
                                  )}{" "}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          {/* <div
                            className="d-flex flex-column align-items-center justify-content-center shadow-sm silver-bg"
                            style={{ height: 80, background: "#F2F2F2" }}
                          >
                            <h5 className="dark-silver-text mt-0">
                            لا يوجد أسئلة مجابة
                          </h5>
                          </div> */}

                          <div
                            className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center mb-3"
                            style={{ height: 300 }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/no-questions.png"
                              }
                              height="80"
                              className="contain-img mb-3"
                              alt="no-questions-img"
                            />
                            <p className="dark-silver-text mt-0 mb-0">
                            لا توجد أسئلة مجابة
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                </div>
              ) : null}
            </TabPane>
            <TabPane tabId="3">
              {this.props.activeTab == 3 ? 
               this.state.isAnderDiscussionPageLoading ? (
                <div
                  className="silver-bg box-layout w-100 pb-0 p-3 mt-1 d-flex justify-content-center align-items-center"
                  style={{ minHeight: 350 }}
                >
                  <Loader type="ball-spin-fade-loader" className="dark-loader" />
                </div>
               ):(
                <div className="row no-gutters">
                  {this.state.anderDiscussionQuestions &&
                    this.state.anderDiscussionQuestions.length !== 0 ? (
                      <div className="col-12">
                        <div className="box-layout shadow-sm w-100 table-responsive">
                          <Table className="mb-0 w-100">
                            <thead className="silver-bg w-100">
                              <tr className="text-center w-100">
                                <th className="dark-silver-text small border-0">
                                  م
                                </th>
                                <th className="w-33 dark-silver-text small border-0">
                                  السؤال
                                </th>
                                  <th className="w-25 dark-silver-text small border-0 d-none">
                                    الحالة
                                </th>
                                  <th className="w-33 dark-silver-text small border-0">
                                    اسم الطالب
                                </th>
                                <th className="w-33 dark-silver-text small border-0">
                                    تاريخ السؤال
                                </th>
                                  <th className="w-33 dark-silver-text small border-0">
                                    التفاصيل
                                </th>
                              </tr>
                            </thead>
                            <tbody className="w-100">{this.renderUnderDiscussionQuestions()}</tbody>
                          </Table>
                          {!this.state.hideAnderDiscussionQuestionsBtn && (
                            <div className="col-12 d-flex  justify-content-center">
                              <button
                                // className="btn dark-btn unset-height unset-line-height br-5 mt-3 w-25 mb-3"
                                className={`btn dark-btn unset-height unset-line-height br-5 mt-3 w-25 mb-3 ${(this.props.activeTab == "3" && this.state.isShadyQuestionExist == -1) ? "flash" : ""}`}
                                // onClick={this.loadUnderDiscussionQuestions}
                                onClick={()=>!this.state.searchedInUnderDiscussion? this.loadUnderDiscussionQuestions() : this.loadUnderDiscussionQuestions_withSearch(this.state.searchType, this.state.term)}
                                disabled={false}
                                ref={this.laodMoreUnderDiscussionRefBtn}
                              >
                                {this.state.loading == true ? (
                                  <Loader
                                    type="ball-beat"
                                    className="dark-loader"
                                  />
                                ) : (
                                    "تحميل المزيد"
                                  )}{" "}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          {/* <div
                            className="d-flex flex-column align-items-center justify-content-center shadow-sm silver-bg"
                            style={{ height: 80, background: "#F2F2F2" }}
                          >
                            <h5 className="dark-silver-text mt-0">
                              لا يوجد أسئلة قيد المناقشة
                          </h5>
                          </div> */}

                          <div
                            className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center mb-3"
                            style={{ height: 300 }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/no-questions.png"
                              }
                              height="80"
                              className="contain-img mb-3"
                              alt="no-questions-img"
                            />
                            <p className="dark-silver-text mt-0 mb-0">
                            لا توجد أسئلة قيد المناقشة
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                </div>
              ) : null}
            </TabPane>
          </TabContent>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state){
  return{
    activeTab: state.askQuestions?.activeTab,
    questionDetailsId: state.askQuestions?.questionDetailsId,
  }
}
AskQuestionsListComponent = connect(mapStateToProps, {setQuestionsListActiveTab, setQuestionsListcourseId, setQuestionDetailsId})(AskQuestionsListComponent)
export const AskQuestionsList = withRouter(AskQuestionsListComponent);
