import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../../../shared/inputs/inputField";
import { withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../api/helpers";
import axios from "axios";
import { WithContext as ReactTags } from "react-tag-input";
import { Link } from "react-router-dom";
import { ForkQuestionComponent } from "./fork-question";
import swal from "@sweetalert/with-react";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import moment from 'moment-hijri';

import "./index.scss"
const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export class QuestionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      hijriYear:[],
      subjectDetails: [],
      topics:[],
      childrenTopics:[],
      tags: [],
      suggestions: [],
      isQuestionOpen: false,
      selectedQuestionId: null,
      searchFilter:{},
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
  }

  openQuestionModal = () => {
    this.setState({ isQuestionOpen: true });
  };
  closeQuestionModal = () => {
    this.setState({ isQuestionOpen: false });
  };



   getTopics(subject){
    const url = `${apiBaseUrl}/Content/Topics/${subject}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    return axios.get(url,{headers})
  }
  async handleChangeSubject(e) {
    const subjectId =  e.target.value;
    const res = await this.getTopics(subjectId)
    if(subjectId!==0) {
      this.setState({
      childrenTopics:[],
      topics: res?.data?.data,
      searchFilter:{
        ...this.state.searchFilter,
        childrenTopicId:0,
        topics:0,
        subjectId: subjectId
      }} , ()=>  this.searchFilterFunction());
    const selected = this.state.subjectDetails && this.state.subjectDetails.filter((topic)=>topic.id==subjectId)[0]
}};

  handleChangetopic = e => {
    const topicId =  e.target.value;
    if(topicId!==0) {
	    this.setState({
          searchFilter:{
            ...this.state.searchFilter,
            topics: topicId
          }} , ()=>  this.searchFilterFunction());

	    let token = localStorage.getItem("token");
	    let headers = {
		    Authorization: `Bearer ${token}`
	    };
	    axios
		    .get(`${apiBaseUrl}/Content/ChildrenTopics/${topicId}`, {headers})
		    .then(res => {
			    this.setState({childrenTopics: res.data.data});
		    })
		    .catch(error => {
			    console.log(error);
		    });
    }
  };
  handleChangeChildrenTopic = e =>{
    const childrenTopicId = e.target.value;
    if(childrenTopicId!==0) {

      this.setState({
        searchFilter: {
          ...this.state.searchFilter,
          childernTopics: childrenTopicId
        }
      }, () => this.searchFilterFunction());
    }  };

  getHijriYear = () =>{
    const hijri=[];
    const currentYear = String(new Date().getUTCFullYear());
    const currentYearHijri = Number(moment(currentYear, 'YYYY').endOf('iYear').format('iYYYY'));
    for (let i=1434; i <= currentYearHijri; i +=1){
      hijri.push(i);
    }
	  this.setState({hijriYear: hijri})
  };

  handleChangeYear = e =>{
	this.setState({
      searchFilter:{
        ...this.state.searchFilter,
        cf_HijriYear : e.target.value
      }},()=>  this.searchFilterFunction());
  };

  handleChangeTextOrId = e =>{
		this.setState({
          searchFilter:{
            ...this.state.searchFilter,
            textOrId : e.target.value
          }},()=>  this.searchFilterFunction());
	};

  searchFilterFunction = () => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    const {searchFilter} = this.state;
    Object.keys(searchFilter).map((row)=>{
    	if(searchFilter[row]==0) delete searchFilter[row]
    });
    let params = new URLSearchParams(searchFilter).toString();
	    axios
      .get(`${apiBaseUrl}/Questions?${params}`, { headers })
      .then(response => {
        this.setState({ questions: response.data.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let url = new URL(`${apiBaseUrl}/questions`);
    url.searchParams.append("topics", `${tag.id}`);
    let requestUrl = url.toString();

    axios
      .get(`${requestUrl}`, { headers })
      .then(response => {
        this.setState({ questions: response.data.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  componentDidMount = () => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${apiBaseUrl}/questions`, { headers })
      .then(response => {
        this.setState({ questions: response.data.data.data });
      })
      .catch(error => {
        console.log(error);
      });

    this.getSubject();
    this.getHijriYear();
  };

  getSubject = () =>{
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
        .get(`${apiBaseUrl}/Content/MineSubjects`, { headers })
        .then(response => {
          this.setState({ subjectDetails: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
  };

  forkQuestion(id) {
    this.props.closeQuestions();
    this.openQuestionModal();
    this.setState({ selectedQuestionId: id });
  }

  renderQuestions() {
    const questions = this.state.questions || [];
    return questions.map(question => (
      <React.Fragment>
        <div className="item">
          <div className="row d-flex align-items-center justify-content-between mb-2">
            <div className="col-md-6">
              <h6 className="light-text mt-0 mb-0">
                سؤال <span className="en-text">{question.id}#</span>
              </h6>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center justify-content-end">
                <div
                  className="rounded-container light-bg shadow-sm clickable mr-2"
                  onClick={() => this.forkQuestion(question.id)}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/fork.png"}
                    height="15"
                    width="15"
                    className="contain-img clickable"
                  />
                </div>
                <div
                  className="rounded-container dark-bg shadow-sm clickable"
                  onClick={() => this.addQuestion(question.id)}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/plus.png"}
                    height="10"
                    width="10"
                    className="contain-img clickable"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <div className="box-layout box-border shadow-sm p-2 ">
                <h6
                    className="dark-text mb-0 encoded-text img-auto-for-children"
                    dangerouslySetInnerHTML={{
                      __html: question.encodedStem,
                    }}
                ></h6>
              </div>
            </div>
          </div>
          {/*<div className="row mt-2">*/}
          {/*  <div className="col-md-12">*/}
          {/*    <div className="box-layout box-border shadow-sm p-2">*/}
          {/*      <img*/}
          {/*        src={question.renderedStem}*/}
          {/*        width="90%"*/}
          {/*        className="contain-img"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="row mt-3">
            <div className="col-md-7">
              {Object.keys(question.choices).map(function(key) {
                return (
                  <div
                    className={`box-layout  d-flex align-items-center pr-2 pl-2 mb-2 ${
                      key == question.correctChoice ? "green-border" : null
                    }`}
                  >
                    {/* <iframe  className="w-100" srcDoc={question.choices[key]} /> */}
                    <label
                      className={`small p-3 dark-silver-text light-font-text d-flex align-items-center mb-0 ${
                        key == question.correctChoice ? "green-text" : null
                      }`}
                    >
                      <h6
                        className="dark-text mb-0 encoded-text img-auto-for-children"
                        dangerouslySetInnerHTML={{
                          __html: question.choices[key],
                        }}
                      ></h6>
                    </label>
                  </div>
                );
              })}
            </div>
            {question.imageUrl && (
              <div className="col-5 d-flex align-items-center">
                <img src={question.imageUrl} width="100%" />
              </div>
            )}
          </div>
        </div>

        <hr />
      </React.Fragment>
    ));
  }

  addQuestion(id) {
    let examId = this.props.examId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = {
      questionId: id
    };
    this.setState({ loading: true });
    axios
      .post(`${apiBaseUrl}/Exams/${examId}/Questions`, data, { headers })
      .then(response => {
        this.setState({ loading: false });
        this.props.closeQuestions();
        window.location.reload();
      })
      .catch(error => {
        this.setState({ loading: false });
        switch (error.response.data && error.response.data.error) {
          case "ExamIsLockedError":
            swal("عفواً", "لا يمكن إضافة أسئلة لهذا الامتحان", "error", {
              button: "متابعة"
            });
            break;
          case "QuestionAlreadyAddedError":
            swal("عفواً", "السـؤال مضاف سابقاً", "error", {
              button: "متابعة"
            });
            break;
          case "ClientError":
            swal("عفواً", "حصل خطأ مت", "error", {
              button: "متابعة"
            });
            break;
          case "AccessDeniedError":
            swal("عفواً", "لا يوجد لديك صلاحية لإضافة سؤال", "error", {
              button: "متابعة"
            });
            break;
          case "NotFoundError":
            swal("عفواً", "لم يتم العثور على السؤال/الامتحان", "error", {
              button: "متابعة"
            });
            break;
          default:
            console.log("other error");
        }
      });
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: 600,
        overflowY: "scroll",
        borderWidth: 0,
        padding: 20,
        overFlowX:"hiddem"
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 2
      }
    };
    const { isQuestionsOpen, closeQuestions } = this.props;
    const { tags, suggestions ,searchFilter, hijriYear, subjectDetails,topics, childrenTopics} = this.state;

    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isQuestionsOpen}
          onRequestClose={closeQuestions}
          closeQuestions={closeQuestions}
        >
          <div className="container h-100 pt-3 pb-3 mx-auto">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <h6 className="light-text mt-0 mb-0">بنك الأسئلة</h6>
                <Link
                  to="/test-bank"
                  className="dark-text mt-0 mb-0 small clickable"
                >
                  استعراض بنك الأسئلة
                </Link>
              </div>
            </div>

            <form>
              <hr/>
              <div className="row">

	              <div className="col-md-6">
		              <label className="dark-text small mb-0 mt-0">السنة هجري</label>
		              <select
                          value={searchFilter.cf_HijriYear}
			              onChange={this.handleChangeYear}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
			              aria-label="Default select example"
		              >
                         <option value={0}>اختر السنة</option>

                        {hijriYear.map(option => (
				              <option key={option} value={option}>
					              {option}
				              </option>
			              ))}
		              </select>
	              </div>
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">رقم او نص السؤال</label>
                  <Field
                        value={searchFilter.textOrId}
                        name="title"
                        type="text"
                        component={inputField}
                        className="form-control border-left-0 pl-0"
                        placeholder=" رقم أو نص السؤال"
                        onChange={this.handleChangeTextOrId}
                      />
                </div>
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">الموضوع</label>

                  <select value={searchFilter.subjectId}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangeSubject}
                          name='subjectId'
                  >
                    <option value={0}>اختر الموضوع</option>

                    {
                      subjectDetails?.length  && subjectDetails?.map( (row) =>
                      <option value={row.id}>{row.name}</option>
                  )
                }
                  </select>
                </div>
                {/*{topics && topics.length?*/}
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">المفاهيم الاساسية</label>

                  <select value={searchFilter.topics}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangetopic}
                          name='topicId'
                  >
	                  <option value={0}>اختر مفهوم</option>
                {
                  topics && topics.map( (row) =>
                      <option value={row.id}>{row.name}</option>
                  )
                }
                  </select>
                </div>
	                {/*// :null}*/}
                {/*{childrenTopics.length?*/}
                <div className="col-md-6">
                  <label className="dark-text small mb-0 mt-0">المفاهيم الفرعية</label>

                  <select value={searchFilter.childernTopics}
                          className="form-select w-100 p-2 small dark-text border border-light-2"
                          aria-label="Default select example"
                          onChange={this.handleChangeChildrenTopic}
                          name='childrenTopicId'
                  >
                    <option value={0}>اختر المفهوم</option>

                    {
                childrenTopics && childrenTopics.map( (row) =>
                      <option value={row.id}>{row.name}</option>
                  )
                }
                  </select>
                </div>
	                {/*:null}*/}
              </div>
              <hr />
              {this.state.loading == true ? (
                <div className="row" style={{ height: 300 }}>
                  <div className="col-md-12 d-flex align-items-center justify-content-center">
                    <Loader type="ball-spin-fade-loader" />
                  </div>
                </div>
              ) : (
                <React.Fragment>{this.renderQuestions()}</React.Fragment>
              )}
            </form>
          </div>
        </Modal>

        <ForkQuestionComponent
          isQuestionOpen={this.state.isQuestionOpen}
          closeQuestion={this.closeQuestionModal}
          examId={this.props.examId}
          questionId={this.state.selectedQuestionId}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.Questions && state.form.Questions.values
  };
}

QuestionsModal = reduxForm({
  form: "Questions"
})(QuestionsModal);

QuestionsModal = connect(mapStateToProps)(QuestionsModal);

export const Questions = withRouter(QuestionsModal);
