import React, { Component } from "react";
import Modal from "react-modal";

export class SubscriberGuideModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      videoURL: "",
    };
  }

  componentDidMount (){
    let url = ""
    if(this.props.url && this.props.url.includes("youtube")){
      url = `https://www.youtube.com/embed/${this.props.url.split("v=")[1]}`
    } 
    else if(this.props.url && this.props.url.includes("drive.google")){
      url = `https://drive.google.com/file/d/${this.props.url.split("/")[5]}/preview`
    } 
    else {
      url = "https://drive.google.com/file/d/1FrC4yHwxhFxVczCWi067eWofQsroHAkl/preview"
    }
    this.setState({videoURL: url})
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "70%",
        borderWidth: 0
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 20
      }
    };
    const { isSubscriberGuideOpen, closeSubscriberGuide } = this.props;

    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isSubscriberGuideOpen}
          onRequestClose={closeSubscriberGuide}
          closeSubscriberGuide={closeSubscriberGuide}
        >
          <div className="box-layout p-3 d-flex align-items-center">
            <iframe 
              src={this.state.videoURL}
              width="100%" 
              title="subscriber-guide-video"
              allow="fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{border: "none", maxHeight: "80%", minHeight: "300px"}}
            ></iframe>

            {/* <video controls>
                <source src="https://drive.google.com/uc?export=download&id=1FrC4yHwxhFxVczCWi067eWofQsroHAkl" type='video/mp4' />
            </video> */}
          </div>

          <div className="d-flex align-items-center justify-content-center">
              <button
              className="btn light-btn unset-height w-25 mt-4"
              onClick={closeSubscriberGuide}
              >
              إغلاق
              </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
