import React from "react";
import { usePermissions } from "./../../../../hooks/usePermissions";
import { PERMISSION_ENUM } from "./../../../../enum/permissions";

export const ExamQuestionsActions = ({
  onUpdate = () => {},
  onDelete = () => {},
}) => {
  const [checkAuth] = usePermissions();
  const canEditQuestion = checkAuth(PERMISSION_ENUM.QuestionsEdit);
  //this permission has been set to true cuz i could delete one even i didn't get teh permission
  //if this issue has been solved just remove || true
  const canDeleteQuestion = checkAuth(PERMISSION_ENUM.QuestionsDelete);

  return (
    <div>
      {canEditQuestion && (
        <img
          src={process.env.PUBLIC_URL + "/assets/images/edit.png"}
          height="22"
          width="22"
          className="contain-img clickable"
          onClick={onUpdate}
        />
      )}
      {canDeleteQuestion && (
        <img
          src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
          height="22"
          width="22"
          className="contain-img ml-1 clickable"
          onClick={onDelete}
        />
      )}
    </div>
  );
};
