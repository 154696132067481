import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../../../shared/inputs/inputField";
import { selectField } from "../../../shared/inputs/selectField";
import { textareaField } from "../../../shared/inputs/textareaField";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur,accept, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
  accept={accept}
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...inputProps}
    {...props}
  />
);

const validate = values => {
  const errors = {};
  const mustEmpty ="يجب تعبئة هذه الخانة"
  if (!values.fileImage) {
    errors.fileImage = mustEmpty;
  }
  if (!values.type||values.type==='null') {
    errors.type = mustEmpty;
  }
  if (!values.title) {
    errors.title = mustEmpty;
  }
  if(!values.chapter)
  errors.chapter = mustEmpty;
  if(!values.section)
  errors.section = mustEmpty;
  return errors;
};

export class CreateSpeedUpComponent extends Component {
  state = {
    file: null,
    loading: false,
    disabled: false,
    files: [],
    sections:[],
    chapters:[],
    type:""
  };

  constructor(props) {
    super(props);
    this.onFileInputChange = this.onFileInputChange.bind(this);
  }

  componentDidMount(){
    this.getSections();
  }

  onFileInputChange(file) {
    this.setState({
      file: URL.createObjectURL(file)
    });
  }
  onTypeChange=(e)=> {
    console.log(e.target.value);
    switch (e.target.value) {
      case "pdf":
        this.setState({type:".pdf"})
        break;
        case "image":
        this.setState({type:".jpg,.png,.jpeg"})
        break;
        case "file":
        this.setState({type:""})
        break;
        case "video":
        this.setState({type:".mp4,.AVI,.WMV,.MOV"})
        break;
    
      default:
        break;
    }
  }

  getSections = ()=>{
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
        .get(`${apiBaseUrl}/content/Sections/${courseId}/me`, { headers })
        .then(response => {
          this.setState({ sections: response.data.data});
        })
        .catch(error => {
          console.log(error);
        });
  };



  handleChangeSection = (e)=>{
    const SectionId = e.target.value;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
        .get(`${apiBaseUrl}/content/Chapters/${SectionId}`, { headers })
        .then(res => {
          this.setState({ chapters: res.data.data});
        })
        .catch(error => {
          console.log(error);
        });

  };

  myFormHandler = values => {
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let fileData = new FormData();
    fileData.append("File", values.fileImage);
    fileData.append("Type", values.type);
    fileData.append("Title", values.title);
    values.chapter && fileData.append("ChapterId", values.chapter);
    values.section && fileData.append("SectionId", values.section);
    fileData.append("CourseId", courseId);
    this.setState({ loading: true, disabled: true });
    axios
      .post(`${apiBaseUrl}/content/${courseId}/speedups`, fileData, { headers })
      .then(response => {
        this.setState({ loading: false, disabled: false });
        this.props.closeCreateSpeedUp();
        this.props.onCreate()
        // window.location.reload(false);
      })
      .catch(error => {
        this.setState({ loading: false, disabled: false });
        console.log(error);
      });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "auto",
        borderWidth: 0
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 11
      }
    };
    const {
      isCreateSpeedUpOpen,
      closeCreateSpeedUp,
      handleSubmit,
      submitting
    } = this.props;
    const {sections,chapters} = this.state;
    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isCreateSpeedUpOpen}
          onRequestClose={closeCreateSpeedUp}
          closeCreateSpeedUp={closeCreateSpeedUp}
        >
          <div className="container h-100 pt-3 pb-3">
            <div className="row">
              <div className="col-12 text-center">
                <h6 className="light-text mb-4 mt-0">إضافة ملف اختصر وقتك</h6>
              </div>
            </div>

            <form onSubmit={handleSubmit(this.myFormHandler)} className="w100">
              <div className="row">
                <div className="col-md-6 col-12">
                  <Field
                    name="title"
                    type="text"
                    component={inputField}
                    className="form-control border-left-0 pl-0"
                    placeholder="اسم الملف"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <Field
                    component={selectField}
                    className="form-control"
                    name="section"
                    onChange={this.handleChangeSection}
                  >
                    <option value='' disabled>اختر الجزء </option>
                    {
                      sections && sections.map( (row) =>
                          <option value={row.id}>{row.nameAr}</option>
                      )
                    }
                  </Field>
                </div>
                {/* { chapters.length? */}
                <div className="col-md-6 col-12">
                  <Field
                    component={selectField}
                    className="form-control"
                    name="chapter"
                  >
                    <option value='' disabled>اختر الفصل</option>
                    {
                      chapters && chapters.map( (row) =>
                          <option value={row.id}
                          >
                            {row.nameAr}
                          </option>
                      )
                    }
                  </Field>
                </div> 
                {/* : null} */}
                <div className="col-md-6 col-12">
                  <Field
                    component={selectField}
                    className="form-control"
                    name="type"
                    onChange={this.onTypeChange}
                  >
                    <option selected disabled value="null">
                      نوع الملف
                    </option>
                    <option value="image">صورة</option>
                    <option value="pdf">PDF</option>
                    <option value="file">ملف</option>
                    <option value="video">فيديو</option>
                  </Field>
                </div>
                <div className="col-12">
                  <Field
                    component={FileInput}
                    name="fileImage"
                    className="d-none"
                    id="uploadImage"
                    onChange={this.onFileInputChange}
                    accept={this.state.type}
                  />
                  <label htmlFor="uploadImage" className="clickable w-100">
                    <div
                      className="silver-bg pt-3 pb-3 pl-4 pr-4 rounded align-items-center justify-content-center d-flex flex-column"
                      style={{ minHeight: 200 }}
                    >
                      {this.state.file ? (
                        <React.Fragment>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/file-outline.png"
                            }
                            className="contain-img"
                            height="40"
                          />

                          <p className="dark-silver-text light-font-text mt-3 mb-0 small">
                            {this.state.file && this.state.file.name}
                          </p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/camera.png"
                            }
                            className="contain-img"
                            height="40"
                          />

                          <p className="dark-silver-text light-font-text mt-3 mb-0 small">
                            أرفق الملف المراد رفعه
                          </p>
                        </React.Fragment>
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn light-outline-btn mt-3 w-25"
                    disabled={this.state.disabled}
                  >
                    {this.state.loading == true ? (
                      <Loader type="ball-clip-rotate" />
                    ) : (
                      "متابعة"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.CreateSpeedUp && state.form.CreateSpeedUp.values
  };
}

CreateSpeedUpComponent = reduxForm({
  form: "SpeedUp",
  validate,
  initialValues:{
    type:'null'
  }
})(CreateSpeedUpComponent);

CreateSpeedUpComponent = connect(mapStateToProps)(CreateSpeedUpComponent);

export const CreateSpeedUp = withRouter(CreateSpeedUpComponent);
