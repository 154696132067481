import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPermissions } from "../actions/login.actions";
import { apiBaseUrl } from "./../api/helpers";
import { useFetch } from "./../hooks/useFetch";
import { useStoreState } from './../hooks/useStoreState';
const permissionsUrl = `${apiBaseUrl}/Auth/MyPermissions`;
export const PermissionInterceptor = () => {
  const [getPermissions] = useFetch(permissionsUrl);
  const storedToken = localStorage.getItem('token')
  const dispatch = useDispatch();

  useEffect(() => {
    if(!storedToken) return;
    const config = {};
    getPermissions(config, ({ data: _permissions }) => {
      dispatch(setPermissions(_permissions));
    });
  }, []);

  return null;
};
