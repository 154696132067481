import React, { useState } from 'react'
import { SubscriberGuideModal } from './subscriberGuideModal'

const SubscriberGuideBtn = ({hasOverlay, fontSize, width, height, imgWidth, url}) => {
    const [isSubscriberGuideOpen, setisSubscriberGuideOpen] = useState(false)
    const closeSubscriberGuideModal = ()=>{
        setisSubscriberGuideOpen(false)
    }
    const openSubscriberGuideModal = ()=>{
        setisSubscriberGuideOpen(true)
    }
  return (
    <>
        <button 
            onClick={openSubscriberGuideModal}
            className="btn-tomato m-auto position-relative"
            style={{width, fontSize, height, lineHeight: height}}
        >
            <img 
            src={process.env.PUBLIC_URL + "/assets/images/subscriber-guide.png"} 
            width={imgWidth? imgWidth : "20"}
            alt='subscriber-guide'
            />
            <span className="ml-2">دليل المشترك</span>
        </button>

        <SubscriberGuideModal
            isSubscriberGuideOpen={isSubscriberGuideOpen}
            closeSubscriberGuide={closeSubscriberGuideModal}
            url={url}
        />
    </>
  )
}

export default SubscriberGuideBtn