import * as helpers from "./helpers";
import { AuthApiEndpoints } from "./auth.api";
import { UserApiEndpoints } from "./user.api";
import { QuesitonsApiEndpoints } from "./questions.api";

export const Api = {
  ...helpers,
  auth: AuthApiEndpoints,
  user: UserApiEndpoints,
  questions: QuesitonsApiEndpoints
};
