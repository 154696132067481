import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentChannel } from "../../actions";
import MessagesHeader from "./messages/messages-header";
import Messages from "./messages/messages";
import firebase from "../../firebase";
import Loader from "react-loaders";
import ChannelsList from "./side-panel/channels";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";
import { firebaseErrorAlert } from "../../firebase-error-alert";

class UsersChatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.props.setCurrentChannel(null);
  }

  addListeners = () => {
    if (this.props.chatChannels && this.props.chatChannels.length > 0 && this.props.forceInternalChat) {
      const channelRef = firebase.database().ref(`channels/${this.props.chatChannels[0].chatChannelSid}`);
      channelRef.once("value", (snapshot) => {
        this.props.setCurrentChannel(snapshot.val())
      }
      ).then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(error=>{
        firebaseErrorAlert(error)
      });
    }
  };

  render() {
    const { messagesRef, loading } = this.state;
    const { channel, user, forceInternalChat, chatChannels, active } = this.props;
    return (
      <React.Fragment>
        {forceInternalChat ?
          <>
            {loading ? (
              <div className="box-layout shadow-sm w-100">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <MessagesHeader />
                    <div className="chat-window">
                      <div className="chat-history d-flex justify-content-center align-items-center">
                        <Loader type="ball-spin-fade-loader" className="dark-loader" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
                <>
                  {channel && (
                    <div className="box-layout shadow-sm w-100">
                      <div className="row no-gutters">
                        <div className="chat-sidebar col-md-4 border-right">
                          <div className="p-3">
                            <input
                              className="form-control small light-font-text"
                              placeholder="ابحث هنا"
                            />
                          </div>
                          <ChannelsList chatChannels={chatChannels} />
                        </div>
                        <div className="col-md-8">
                          <MessagesHeader />
                          <div className="chat-window">
                            <Messages
                              messagesRef={messagesRef}
                              channel={channel}
                              user={user}
                              active={active}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
          </>
          :
          <div
            className="chat-title bg-white border h-55 d-flex align-items-center justify-content-center mb-4 rounded shadow-sm clickable"
            onClick={() =>
              window.open(this.props.externalChannelUrl, "_blank")
            }
          >
            <h6 className="media chat-item mb-0 d-flex align-items-center light-text small">
              رابط مجموعة التيليجرام
            </h6>
          </div>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    channel: state.channel.currentChannel,
    user: state.user,
  };
}

const mapDispatchToProps = {
  setCurrentChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersChatComponent);
