export const statusEnum = {
  Draft: "مسودة",
  Active: "تم النشر",
  Pending: "قيد المراجعة",
  Rejected: "مرفوض",
};

const disabledStatuses = ["Rejected"]

export const isDisabledStatus = (status)=> disabledStatuses.includes(status)

export const getExamStatus = (status) => {
  return statusEnum?.[status] || "اختر الحالة";
};
