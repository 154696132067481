import { combineReducers } from "redux";
import { profileReducer } from "./profile.reducer";
import { reducer as formReducer } from "redux-form";
import { authReducer } from "./auth.reducer";
import { userReducer } from "./user.reducer";
import { questionsReducer, askQuestionsReducer } from "./questions.reducer";
import { channelReducer } from "./chat.reducer";

export const hemmaReducer = combineReducers({
  form: formReducer,
  profile: profileReducer,
  auth: authReducer,
  user: userReducer,
  channel: channelReducer,
  questions: questionsReducer,
  askQuestions: askQuestionsReducer,
});
