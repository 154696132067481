import React, { Component, Fragment } from "react";

export class MathEditor extends Component {
  constructor(props) {
    super(props);
    this.id = "math-editor-" + Math.floor(Math.random() * 10000);
    this.loadedInitial = false;
  }

  componentDidMount() {
    const element = document.getElementById(this.id);

    this.editor = window.com.wiris.jsEditor.JsEditor.newInstance({
      language: "ar",
      previewZoom: "1.0",
    });
    this.editor.insertInto(element);

    this.editor.setMathML(
      (this.props.input && this.props.input.value) || '<math dir="rtl"></math>'
    );

    element.addEventListener("keypress", () => {
      const currentValue = this.editor.getMathML();
      this.props.input.onChange(currentValue);
      this.props.onChange && this.props.onChange(currentValue);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.loadedInitial && this.props.input && this.props.input.value) {
      this.editor.setMathML(this.props.input.value);
      this.loadedInitial = true;
    }
  }

  render() {
    return (
      <div dir="rtl">
        <div style={{ width: "100%", height: 300 }} id={this.id}></div>
      </div>
    );
  }
}
