import React, { Component } from "react";
import "./styles.sass";
import { NavLink, Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { connect } from "react-redux";
import { signOutAction } from "../../../actions/login.actions";
import { getUser } from "../../../actions/user.actions";
import jwtDecode from "jwt-decode";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.hideHeaderMenu = this.hideHeaderMenu.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  hideHeaderMenu(){
    this.setState({
      isOpen: false
    });
  }

  logout = () => {
    localStorage.removeItem('account');
    localStorage.removeItem('checkbox');
    this.props.signOutAction();
    this.props.history.push("/");
  };

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.getUser();
    }

    const token = localStorage.getItem("token");
    if (token && jwtDecode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      this.props.history.push("/auth/login");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.getUser();
    }
  }

  render() {
    const user = this.props.user;
    return (
      <React.Fragment>
        <div className="header">
          <Navbar
            className={
              "navbar navbar-expand-lg navbar-light bg-transparent " +
              (this.props.location.pathname.startsWith("/courses/details")
                ? "shadow-sm"
                : "null")
            }
          >
            <div className="container">
              <Link to="/" className="navbar-brand">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                  height="80"
                  onClick={this.hideHeaderMenu}
                />
                {/* <span className="d-none d-sm-block" />
                <h5 className="dark-text d-none d-sm-block">
                  سلسلة بالبيد التعليمية
                </h5> */}
              </Link>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  {!this.props.authenticated ? (
                    <React.Fragment>
                      <NavItem
                        className={
                          "nav-item rounded-nav-item pl-2 pr-2 light-border"
                        }
                        onClick={this.hideHeaderMenu}
                      >
                        <NavLink
                          className={"nav-link light-text"}
                          activeClassName="active"
                          to="/auth/login"
                        >
                          تسجيل الدخول
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <NavItem onClick={this.hideHeaderMenu}>
                        <NavLink
                          className={"nav-link mid-text"}
                          activeClassName="active"
                          to="/test-bank"
                        >
                          بنك الأسئلة
                        </NavLink>
                      </NavItem>
                      <NavItem onClick={this.hideHeaderMenu}>
                        <NavLink
                          className={"nav-link mid-text"}
                          activeClassName="active"
                          to="/courses"
                        >
                          دوراتي
                        </NavLink>
                      </NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className={"nav-link mid-text"} nav>
                          <img
                            src={"/assets/images/user-circle.png"}
                            height="18"
                            className="mr-2"
                          />
                          {user && user.name}
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <DropdownItem className="p-0">
                            <NavLink
                              className="nav-link mid-text"
                              activeClassName="active"
                              to="/account/edit"
                            >
                              تعديل الملف
                            </NavLink>
                          </DropdownItem> */}
                          <DropdownItem className="p-0" onClick={this.hideHeaderMenu}>
                            <a
                              className="nav-link clickable mid-text"
                              onClick={this.logout}
                            >
                              تسجيل الخروج
                            </a>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </React.Fragment>
                  )}
                </Nav>
              </Collapse>
            </div>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    phoneNumberConfirmed: state.auth.phoneNumberConfirmed,
    user: state.user
  };
}

HeaderComponent = connect(
  mapStateToProps,
  { signOutAction, getUser }
)(HeaderComponent);

export const Header = withRouter(HeaderComponent);
