import React, { Component } from "react";
import Modal from "react-modal";
import { inputField } from "../../../shared/inputs/inputField";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { Field, reduxForm, resetSection } from "redux-form";
import { withRouter } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import CounterInput from "react-counter-input";
import { RadioField } from "../../../shared/inputs/RadioFeild";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import { MathEditor } from "../../../shared/editor/editor";
import { verticalRadioField } from "../../../shared/inputs/verticalRadioField";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import swal from "@sweetalert/with-react";
import { getQuestion } from "../../../../actions";
import Editor from "./Editor";
import { Question } from "./add-question";
import { selectField } from "../../../shared/inputs/selectField";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...inputProps}
    {...props}
  />
);

const validate = (values) => {
  const errors = {};
  if (!values.level) {
    errors.level = "يجب تعبئة هذه الخانة";
  }
  if (!values.explanation) {
    errors.explanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.solutionExplanation) {
    errors.solutionExplanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.first) {
    errors.first = "يجب تعبئة هذه الخانة";
  }
  if (!values.second) {
    errors.second = "يجب تعبئة هذه الخانة";
  }
  if (!values.third) {
    errors.third = "يجب تعبئة هذه الخانة";
  }
  if (!values.forth) {
    errors.forth = "يجب تعبئة هذه الخانة";
  }
  if (!values.correctChoice) {
    errors.correctChoice = "يجب تعبئة هذه الخانة";
  }
  return errors;
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export class UpdateExamComponent extends Component {
  constructor(props) {
    super(props);

    let questionId = this.props.match.params.questionId;
    let examId = this.props.match.params.examId;

    this.appplyNewState = this.appplyNewState.bind(this)


    this.state = {
      activeTab: "1",
      questionId,
      examId,
      questionLoading: true,
      activeInnerTab: "4",
      activeHintTab: "6",
      file: null,
      imageUrl: null,
      solutionFile: null,
      solutionVideo: null,
      hintFile: null,
      hintVideo: null,
      count: null,
      orderQuestion: 1,
      selectedTopics: [],
      parentTopicId: "",
      tags: [],
      suggestions: [],
      topics: [],
      question: {},
      parentTopics: [],
      subject : {},
      // to overcome performance issues with redux-forms and the editor
      stemML: null,
      explanationML: null,
      solutionML: null,
      editorState: {
        stem: "",
        first: "",
        second: "",
        third: "",
        forth: "",
        explanation: "",
        solutionExplanation: "",
      },
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onSolutionInputChange = this.onSolutionInputChange.bind(this);
    this.onHintInputChange = this.onHintInputChange.bind(this);
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state._questionDAta };
    data[input.name] = input.value;
    this.appplyNewState({ data });
  };

  onSolutionInputChange(file) {
    this.appplyNewState({
      solutionFile: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.appplyNewState({
          solutionVideo: response.data.data.url,
          imageLoader: false,
            solutionExplanation: {
              type: "Video",
              value: response.data.data.url,
            },
        });
      })
      .catch((error) => {
        this.appplyNewState({ solutionFile: null, imageLoader: false });
      });
  }

  onHintInputChange(file) {
    this.appplyNewState({
      hintFile: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.appplyNewState({
          hintVideo: response.data.data.url,
          imageLoader: false,
          explanation: { type: "Video", value: response.data.data.url },
        });
      })
      .catch((error) => {
        this.appplyNewState({ hintFile: null, imageLoader: false });
      });
  }

  onFileInputChange(file) {
    this.appplyNewState({
      imageUrl: URL.createObjectURL(file),
      imageLoader: true,
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers,
      })
      .then((response) => {
        this.appplyNewState({ imageUrl: response.data.data.url, imageLoader: false });
      })
      .catch((error) => {
        this.appplyNewState({ imageUrl: null, imageLoader: false });
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps &&
      this.props.questionId !== nextProps.questionId &&
      nextProps.questionId !== null
    ) {
      this.props.getQuestion(nextProps.questionId);
      this.appplyNewState({ questionId: nextProps.questionId });
    }
    return true;
  }


  async fetchChildTopics(selectedTopic){

    const url = `${apiBaseUrl}/Content/ChildrenTopics/${selectedTopic}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const childTopics = response.data.data;
        this.appplyNewState({
          childTopics
        });
      });
  }

  handleTopicChange = ({ currentTarget: input }) => {
    const selectedTopic = input.value;
    this.fetchChildTopics(selectedTopic)
    this.appplyNewState({...this.state, selectedTopic });
  };
  getChildsTopic = (id) => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/subjects/${id}/children_lookup`, { headers })
      .then((response) => {
        const arrayObj = response.data.data;
        const suggestionsArr = arrayObj.map((item) => {
          return {
            id: item.id,
            text: item.name,
          };
        });
        this.appplyNewState({
          ...this.state,
          suggestions: suggestionsArr,
        });
      });
  };


  async fetchTopics(){
    const url = `${apiBaseUrl}/Content/Topics/${this.state.subject.id}`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const topics = response.data.data;
        this.appplyNewState({
          ...this.state,
          topics
        });
      });

  }

  handleSubjectChange({currentTarget : {value}}){
    this.setState({...this.state, subject : {id : value} },()=> this.fetchTopics());
  }



  async getSubjects(){

    const url = `${apiBaseUrl}/Content/MineSubjects`
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const subjects = response.data.data;
        this.appplyNewState({
          ...this.state,
          subjects
        });
      });
  }


  getQuestionData(){
    const {questionId,examId} = this.state
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${apiBaseUrl}/Questions/${questionId}/${examId}`, { headers })
      .then((response) => {
        this.appplyNewState({test : 'testdda'})
        const { data  } = response.data;
        const {choices : {a : choiceA,b :choiceB,c:choiceC,d:choiceD},stem,duration,subject,explanation,solutionExplanation} = data
        this.appplyNewState({
          _questionDAta : data,
          questionLoading : false,
          duration,
          subject ,
          explanation,
          solutionExplanation,
          editorState : {
            choiceA ,
            choiceB,
            choiceC,
            choiceD,
            stem
          }
        })
      }).catch((error) => {
      })
  }
  componentDidMount() {
    let questionId = this.props.match.params.questionId;
    let examId = this.props.match.params.examId;

    this.getSubjects()

    this.getQuestionData()


    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/subjects/parent_lookup`, { headers })
      .then((response) => {
        this.appplyNewState({
          parentTopics: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${apiBaseUrl}/Questions/${questionId}/${examId}`, { headers })
      .then((response) => {
        const { data } = response.data;
        this.getChildsTopic(data.parentTopic && data.parentTopic.id);
        const selectedTopics = data.topics.map((topic) => {
          return { value: topic.id, text: topic.name };
        });
        this.setState({
          parentTopicId: response.data.data.parentTopic && response.data.data.parentTopic.id,
          question: response.data.data,
          orderQuestion: response.data.data.order,
          selectedTopics,
          editorState: {
            ...this.state.editorState,
            stem: response.data.data.stem,
            first: response.data.data.encodedChoices.a,
            second: response.data.data.encodedChoices.b,
            third: response.data.data.encodedChoices.c,
            forth: response.data.data.encodedChoices.b,
            explanation:
              response.data.data.explanation.type === "Text"
                ? response.data.data.encodedExplanation
                : "",
            solutionExplanation:
              response.data.data.solutionExplanation.type === "Text"
                ? response.data.data.encodedSolutionExplanation
                : "",
          },
          activeHintTab:
            response.data.data.explanation.type === "Text" ? "6" : "7",
          activeInnerTab:
            response.data.data.solutionExplanation.type === "Text" ? "4" : "5",
          imageUrl: response.data.data.imageUrl,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getTopicsList = (id) => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${apiBaseUrl}/subjects/${id}/children_lookup`, { headers })
      .then((response) => {
        const arrayObj = response.data.data;
        const suggestionsArr = arrayObj.map((item) => {
          return {
            id: item.id,
            text: item.name,
          };
        });
        this.appplyNewState({
          suggestions: suggestionsArr,
        });
      });
  };

  appplyNewState(newState){
    this.setState({...this.state,...newState})
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.appplyNewState({
        activeTab: tab,
      });
    }
  }
  toggleInner(tab) {
    if (this.state.activeInnerTab !== tab) {
      this.appplyNewState({
        activeInnerTab: tab,
      });
    }
  }
  toggleHint(tab) {
    if (this.state.activeHintTab !== tab) {
      this.appplyNewState({
        activeHintTab: tab,
        // _questionDAta: {
        //   ...this.state._questionDAta,
        //   explanation: { type: tab === "6" ? "Text" : "Video", value: "" },
        // },
      });
    }
  }

  handleDelete(i) {
    const { selectedTopics } = this.state;
    this.appplyNewState({
      selectedTopics: selectedTopics.filter((topic, index) => index !== i),
    });
  }

  handleAddition(topic) {
    this.appplyNewState(
      {selectedTopics: [...this.state.selectedTopics, topic],}
    );
  }

  handleNestedChange(code, value,subCodekey,subCodeValue,stateKey){
    const newData= {
      [subCodekey]:subCodeValue
      ,value
    }
    const readyToMerge = {
      [code]:  {
        ...newData
      }
    }
    const stateObject = {
      [stateKey] : {
        ...this.state?.[stateKey],
       [code] : {...newData}
      }
    }
    this.appplyNewState({...readyToMerge})
  }

  handleEditorDataChange = (code, value,subCodekey,subCodeValue,stateKey) => {
    if(!value) return
    if(subCodekey){
      this.handleNestedChange(code, value,subCodekey,subCodeValue,stateKey)
      return
    }
    this.appplyNewState({
      editorState: {
        ...this.state.editorState,
        [code]: value,
      },
    });
  };

  myFormHandler = (values) => {
    const questionId = this.props.match.params.questionId;
    let examId = this.props.match.params.examId;
    let courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const {duration,editorState,subject , selectedTopic} = this.state
    const { id : subjectId}  = subject
    const {choiceA,choiceB,choiceC,choiceD,stem} = editorState
    let data = {
      questionTopics: this.state.selectedTopics.map((selectedTopic) => {
        return selectedTopic.id;
      }),
      parentTopicId: this.state.parentTopicId,
      order : this.state.orderQuestion,
      duration,
      level: values.level,
      explanationType: this.state.explanation.type,

      explanationValue: this.state.explanation.value,

      solutionExplanationType: this.state.solutionExplanation.type,

      solutionExplanationValue: this.state.solutionExplanation.value,
      stem,
      imageUrl: this.state.imageUrl,
      choiceA ,
      choiceB ,
      choiceC ,
      choiceD ,
      correctChoice: this.state._questionDAta.correctChoice,
      parentTopicId:selectedTopic,
      subjectId
    };
    axios
      .put(`${apiBaseUrl}/questions/${questionId}`, data, {
        headers,
      })
      .then((response) => {
        this.props.history.push(`/courses/${courseId}/exams/details/${examId}`);
      });
  };

  reactTagsAdapter(childTopics = []){
    return childTopics.map(({id,name}) => ({
      id, text : name
    }))
  }

  render() {
    const { tags, suggestions, selectedTopics ,childTopics,selectedTopic } = this.state;
    const { handleSubmit } = this.props;
    const { _questionDAta , questionLoading,questionData } = this.state;
    return (
      <React.Fragment>
        <div className="container">
          <form onSubmit={handleSubmit(this.myFormHandler)}>
            <div className="question-item">
              <div className="row pb-2">
                <div className="col-12 d-flex align-items-center mb-2 justify-content-between">
                  {JSON.stringify(questionData)}

                  <h6 className="light-text mt-0 mb-0">تعديل سؤال</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <h6 className="dark-text">ترتيب السؤال</h6>
              <div className="d-flex align-items-center flex-row counter-input mt-3">
                <CounterInput
                    min={1}
                    onCountChange={(order) => this.setState({ orderQuestion: order})}
                    inputStyle={{
                      fontFamily: "MontserratMedium",
                    }}
                    count={this.state.question.order}
                />
              </div>
            </div>
            <br />
            <div className="row">
              {questionLoading ? (
                <div className="col-6 mt-5">
                  <div>
                    <Loader type="ball-spin-fade-loader" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <h6 className="dark-text">السؤال</h6>
                    <Editor
                      code={"stem"}
                      editorData={_questionDAta.stem}
                      onChange={this.handleEditorDataChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <h6 className="dark-text">الإجابة الأولى</h6>
                    <div className="editor8">
                      <Editor
                        code={"choiceA"}
                        editorData={
                          _questionDAta.choices && _questionDAta.choices.a
                        }
                        onChange={this.handleEditorDataChange}
                      />
                    </div>
                  </div>
                  <div className="col-6 mt-3">
                    <h6 className="dark-text">الإجابة الثانية</h6>
                    <Editor
                      code={"choiceB"}
                      editorData={
                        _questionDAta.choices && _questionDAta.choices.b
                      }
                      onChange={this.handleEditorDataChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <h6 className="dark-text">الإجابة الثالثة</h6>
                    <Editor
                      code={"choiceC"}
                      editorData={
                        _questionDAta.choices && _questionDAta.choices.c
                      }
                      onChange={this.handleEditorDataChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <h6 className="dark-text">الإجابة الرابعة</h6>
                    <Editor
                      code={"choiceD"}
                      editorData={
                        _questionDAta.choices && _questionDAta.choices.d
                      }
                      onChange={this.handleEditorDataChange}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <h6 className="dark-text">الخيار الصحيح</h6>

                    <Field
                      component={RadioField}
                      name="correctChoice"
                      type={"radio"}
                      onClick={(value) =>
                        this.appplyNewState({
                          _questionDAta: {
                            ...this.state._questionDAta,
                            correctChoice: value,
                          },
                        })
                      }
                      value={_questionDAta.correctChoice}
                      options={[
                        { title: "الإجابة الأولى", value: "a" },
                        { title: "الإجابة الثانية", value: "b" },
                        { title: "الإجابة الثالثة", value: "c" },
                        { title: "الإجابة الرابعة", value: "d" },
                      ]}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <h6 className="dark-text">صورة توضيحية</h6>
                    <label htmlFor="uploadImage" className="clickable w-100">
                      <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mb-3">
                        {this.state.imageUrl ? (
                          <React.Fragment>
                            {!this.state.imageLoader && (
                              <img
                                src={
                                  this.state.imageUrl ||
                                  process.env.PUBLIC_URL +
                                    "/assets/images/upload.png"
                                }
                                className="contain-img"
                                width="100%"
                              />
                            )}
                            {this.state.imageLoader && (
                              <Loader type="ball-clip-rotate" />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/upload.png"
                              }
                              height="30"
                              width="30"
                              className="contain-img clickable"
                            />
                            <p className="dark-silver-text small mb-0">
                              أرفق صورة
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    </label>
                    <Field
                      component={FileInput}
                      name="imageUrl"
                      className="d-none"
                      id="uploadImage"
                      value={_questionDAta.imageUrl}
                      onChange={this.onFileInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <h6 className="dark-text">الموضوع</h6>
                    <Input
                      type="select"
                      className="form-control"
                      name="parentTopicId"
                      id="parentTopicId"
                      onChange={(e) => {
                        this.handleSubjectChange(e);
                      }}
                      value={this.state?.subject?.id}
                    >
                      <option value="" disabled>
                        الرجاء الاختيار
                      </option>
                      {this?.state?.subjects?.length && this?.state?.subjects?.map((topic) => (
                        <option key={topic.id} value={topic.id}>
                          {topic.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-md-6">
                    <h6 className="dark-text">المفهوم الأساسي</h6>
                    <Input
                      type="select"
                      className="form-control"
                      name="parentTopicId"
                      id="parentTopicId"
                      onChange={(e) => {
                        this.handleTopicChange(e);
                        // this.getTopicsList(e.target.value);
                      }}
                      // value={this.state.parentTopicId}
                    >
                      <option value="" disabled>
                        الرجاء الاختيار
                      </option>
                      {this.state?.topics?.map((topic) => (
                        <option key={topic.id} value={topic.id}>
                          {topic.name}
                        </option>
                      ))}
                    </Input>
                  </div>

                  {!!this.state.childTopics?.length && (
                    <div className="col-md-6">
                      <h6 className="dark-text">المفاهيم الفرعية</h6>
                      <ReactTags
                        tags={this.state.selectedTopics || []}
                        suggestions={this.reactTagsAdapter(
                          this.state.childTopics
                        )}
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        delimiters={delimiters}
                        inputFieldPosition="top"
                        placeholder="اختر المفهوم.."
                      />
                    </div>
                  )}
                  <div className="col-md-6 mt-3">
                    <h6 className="dark-text">درجة الصعوبة</h6>

                    <Field
                      component={RadioField}
                      type={"radio"}
                      name="level"
                      onClick={(value) =>
                        this.appplyNewState({
                          _questionDAta: {
                            ...this.state._questionDAta,
                            level: value,
                          },
                        })
                      }
                      options={[
                        { title: "سهل", value: "Easy" },
                        { title: "متوسط", value: "Medium" },
                        { title: "صعب", value: "Hard" },
                      ]}
                      value={_questionDAta.level}
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <h6 className="dark-text">الوقت</h6>

                    <div className="d-flex align-items-center flex-row counter-input mt-3">
                      <CounterInput
                        min={30}
                        onCountChange={(count) =>
                          this.appplyNewState({ count: count })
                        }
                        inputStyle={{
                          fontFamily: "MontserratMedium",
                        }}
                        count={_questionDAta.duration}
                      />
                      <h6 className="dark-text small mb-0 ml-3">
                        ثانية لحل السؤال
                      </h6>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <h6 className="dark-text">مساعدة</h6>
                    <TabPane tabId="3">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "6",
                            })}
                            onClick={() => {
                              this.toggleHint("6");
                            }}
                          >
                            كتابة طريقة المساعدة للسؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "7",
                            })}
                            onClick={() => {
                              this.toggleHint("7");
                            }}
                          >
                            إضافة المساعدة بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeHintTab}>
                        <TabPane tabId="6">
                          <div
                            className="box-layout dashed-border
                       align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                          >
                            <Editor
                              code={"explanation"}
                              editorData={
                                this.state.explanation?.type === "Text" && this.state.explanation.value
                              }
                              subCodeKey="type"
                              subCodeValue="Text"
                              stateKey="_questionDAta"
                              onChange={this.handleEditorDataChange}
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <Col sm="12">
                            <label
                              htmlFor="hintVideo"
                              className="clickable w-100"
                            >
                              <div
                                className="d-flex box-layout dashed-border
                             align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                              >
                                {this.state.explanation?.type === 'Video' ? (
                                  <>
                                    <video
                                      src={this.state.explanation.value}
                                      className="contain-img"
                                      width="100%"
                                      controls
                                    />
                                  </>
                                ) : (
                                  <>
                                    <>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                        }
                                        height="30"
                                        width="30"
                                        className="contain-img clickable"
                                      />
                                      <p className="dark-silver-text small mb-0">
                                        اضف طريقة المساعدة عبر فيديو
                                      </p>
                                    </>
                                  </>
                                )}
                              </div>
                            </label>
                            <Field
                              component={FileInput}
                              name="hintVideo"
                              className="d-none"
                              id="hintVideo"
                              onChange={this.onHintInputChange}
                            />
                          </Col>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </div>
                  <div className="col-12 mt-3">
                    <h6 className="dark-text">طريقة الحل</h6>
                    <TabPane tabId="4">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "4",
                            })}
                            onClick={() => {
                              this.toggleInner("4");
                            }}
                          >
                            كتابة طريقة حل السؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "5",
                            })}
                            onClick={() => {
                              this.toggleInner("5");
                            }}
                          >
                            إضافة الحل بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeInnerTab}>
                        <TabPane tabId="4">
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className=" box-layout dashed-border
                          align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                              >
                                <Editor
                                  code={"solutionExplanation"}
                                  subCodeKey="type"
                                  subCodeValue="Text"
                                  stateKey="solutionExplanation"
                                  editorData={ this.state.solutionExplanation?.type === "Text" && this.state.solutionExplanation.value}
                                  onChange={this.handleEditorDataChange}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <Col sm="12">
                            <label
                              htmlFor="solutionVideo"
                              className="clickable w-100"
                            >
                              <div
                                className="d-flex box-layout dashed-border
                             align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                              >
                                {this.state.solutionExplanation?.value !=
                                  "" &&
                                  this.state.solutionExplanation?.type ===
                                  "Video" ? (
                                  <React.Fragment>
                                    {!this.state.imageLoader && (
                                      <video
                                        src={
                                          this.state.solutionExplanation.solutionExplanation
                                            ?.type === "Video"
                                            ? this.state.solutionExplanation.solutionExplanation
                                                .value
                                            : process.env.PUBLIC_URL +
                                              "/assets/images/upload.png"
                                        }
                                        className="contain-img"
                                        width="100%"
                                        controls
                                      />
                                    )}
                                    {this.state.imageLoader && (
                                      <Loader type="ball-clip-rotate" />
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/upload.png"
                                      }
                                      height="30"
                                      width="30"
                                      className="contain-img clickable"
                                    />
                                    <p className="dark-silver-text small mb-0">
                                      اضف طريقة الحل عبر فيديو
                                    </p>
                                  </React.Fragment>
                                )}
                              </div>
                            </label>
                            <Field
                              component={FileInput}
                              name="solutionVideo"
                              className="d-none"
                              id="solutionVideo"
                              onChange={this.onSolutionInputChange}
                            />
                          </Col>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </div>
                  <div className="col-md-12 d-flex align-items-center justify-content-center">
                    <button className="btn light-btn w-100">حفظ</button>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.questions,
    entireState: state,
  };
}

UpdateExamComponent = reduxForm({
  form: "EditQuestion",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(UpdateExamComponent);

UpdateExamComponent = connect(mapStateToProps, { getQuestion })(
  UpdateExamComponent
);

export const UpdateExam = withRouter(UpdateExamComponent);
