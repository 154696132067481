import React from 'react'
import "./styles.sass";

const CustomTooltip = ({ isPrivate, isLeft, isImgClickable, isComment, isReply }) => {
  const PRIVATE_QUESTION_TEXT = "لا يمكن لجميع الطلاب استعراض هذا السؤال بتعليقاته"
  const NOT_PRIVATE_QUESTION_TEXT = "يمكن لجميع الطلاب استعراض هذا السؤال بتعليقاته"
  const PRIVATE_COMMENT_TEXT = "لا يمكن لجميع الطلاب استعراض هذا التعليق والردود التي عليه"
  const NOT_PRIVATE_COMMENT_TEXT = "يمكن لجميع الطلاب استعراض هذا التعليق والردود التي عليه"
 
  const PRIVATE_REPLY_TEXT = "لا يمكن لجميع الطلاب استعراض هذا الرد"
  const NOT_PRIVATE_REPLY_TEXT = "يمكن لجميع الطلاب استعراض هذا الرد"
 
  return (
    <div className="privacy-tooltip-container">
      <img
        src={process.env.PUBLIC_URL + `/assets/images/${isPrivate ? "dark-lock": "users"}.png`}
        width="20"
        className={`${isImgClickable? "clickable mr-2" : "mr-2 align-self-start"} contain-img `}
        alt=""
      />
      <span className={isLeft? "privacy-tooltip-text-left":"privacy-tooltip-text"}>
        {
          isComment? 
            isPrivate? PRIVATE_COMMENT_TEXT : NOT_PRIVATE_COMMENT_TEXT
            :
          isReply? 
            isPrivate? PRIVATE_REPLY_TEXT : NOT_PRIVATE_REPLY_TEXT
            :
            isPrivate? PRIVATE_QUESTION_TEXT : NOT_PRIVATE_QUESTION_TEXT
        }
        {/* {isPrivate? 
        "لا يمكن لجميع الطلاب استعراض هذا السؤال بتعليقاته":
        "يمكن لجميع الطلاب استعراض هذا السؤال بتعليقاته"
        } */}
      </span>
  </div>
  )
}

export default CustomTooltip