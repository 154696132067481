import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../../../shared/inputs/inputField";
import { withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../api/helpers";
import axios from "axios";
import { dateTimeField } from "../../../shared/inputs/dateTimeField";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";
export class DiscussionComponent extends Component {
  state = { disabled: false, loading: false };
  myFormHandler = values => {
    this.setState({ disabled: true, loading: true });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = {
      courseId: this.props.courseId,
      title: values.title,
      startsAt: values.startsAt,
      endsAt: values.endsAt
    };
    axios
      .post(`${apiBaseUrl}/discussions`, data, { headers })
      .then(response => {
        this.setState({ disabled: false, loading: false });
        this.props.closeDiscussion();
        window.location.reload(false);
      })
      .catch(error => {
        this.setState({ disabled: false, loading: true });
        console.log(error);
      });
  };

  render() {
    const customStyles = {
      content: {
        top: "125px",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translateX(-50%)",
        width: window.innerWidth < 768? "80%" : "30%",
        height: "auto",
        borderWidth: 0,
        padding: 20,
        overflow: "none"
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 2
      }
    };
    const {
      isDiscussionOpen,
      closeDiscussion,
      handleSubmit,
      submitting
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isDiscussionOpen}
          onRequestClose={closeDiscussion}
          closeDiscussion={closeDiscussion}
        >
          <div className="container h-100 pt-3 pb-3 mx-auto">
            <div className="row">
              <div className="col-12">
                <h6 className="light-text mb-4 mt-0">إنشاء نقاش جديد</h6>
              </div>
            </div>

            <form onSubmit={handleSubmit(this.myFormHandler)}>
              <div className="row">
                <div className="col-12">
                  <Field
                    name="title"
                    type="text"
                    component={inputField}
                    className="form-control border-left-0 pl-0"
                    placeholder="العنوان"
                  />
                </div>

                <div className="col-md-6 col-12">
                  <label className="dark-text small"> وقت البداية</label>
                  <Field
                    name="startsAt"
                    component={dateTimeField}
                    className="form-control border-left-0 pl-0 ltr-input"
                  />
                </div>

                <div className="col-md-6 col-12">
                  <label className="dark-text small">وقت النهاية</label>

                  <Field
                    name="endsAt"
                    component={dateTimeField}
                    className="form-control border-left-0 pl-0 ltr-input"
                  />
                </div>

                <div className="col-12 text-center">
                  <button
                    className="btn dark-outline-btn mt-3 w-100"
                    type="submit"
                    disabled={this.state.disabled}
                  >
                    {this.state.loading == true ? (
                      <Loader type="ball-clip-rotate" />
                    ) : (
                      "إنشاء"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.Discussion && state.form.Discussion.values
  };
}

DiscussionComponent = reduxForm({
  form: "Discussion"
})(DiscussionComponent);

DiscussionComponent = connect(mapStateToProps)(DiscussionComponent);

export const Discussion = withRouter(DiscussionComponent);
