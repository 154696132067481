import React, { Component } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { ExamComponent } from "./create-exam";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import swal from "@sweetalert/with-react";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-spin-fade-loader.scss";
import ReactPlayer from "react-player";
import { PERMISSION_ENUM } from './../../../../enum/permissions';
import { ExamDelete } from "./exam-delete";
import { withRouter } from "react-router-dom";
import { ExamAddBtn } from './add-exam-btn';
import { BsSearch } from "react-icons/bs";

import './index.scss'

class _ExamsList extends Component {
  constructor(props) {
    super(props);
    const {
      type = "Exam",
      title = "الاختبارات الإلكترونية",
      newText = "اختبار",
      examCreatePermission = PERMISSION_ENUM.ExamsCreate,
      examDeletionPermission = PERMISSION_ENUM.ExamsDelete,
    } = props;
    const courseId = this.props.match.params.id;
    const reqParams = {
      courseId,
      type,
      newText
    }
    this.state = {
      isExamOpen: false,
      modal: false,
      exams: [],
      examId: null,
      isPageLoading: false,
      reqParams,
      title,
      newText,
      type,
      searchValue: "",
      searchedExams: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getQuestion = this.getQuestion.bind(this);
  }
  openExamModal = () => {
    this.setState({ isExamOpen: true });
  };
  closeExamModal = () => {
    this.setState({ isExamOpen: false });
  };

  toggle(id) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      examId: id
    }));
  }

  getQuestion(){
    this.setState({ isPageLoading: true });
    const courseId = this.props.match.params.id;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${apiBaseUrl}/Exams`, { headers,  params:this.state.reqParams })
      .then(response => {
        this.setState({ 
          exams: response.data.data, 
          isPageLoading: false 
        });
      })
      .catch(error => {
        this.setState({ isPageLoading: false });
        console.log(error);
      });
  }

  componentDidMount = () => {
    this.getQuestion()
  };

  deleteExam(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .delete(`${apiBaseUrl}/exams/${id}`, {
        headers
      })
      .then(response => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        let oldExams = this.state.exams.slice();
        const examIndex = oldExams.findIndex(e => e.id === id);
        oldExams.splice(examIndex, 1);
        this.setState({
          exams: oldExams
        });
      })
      .catch(error => {
        console.log(error);
        switch (error.response.data && error.response.data.error) {
          case "AccessDeniedError":
            swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
              button: "متابعة"
            });
          case "AppError":
            swal("عفواً", "لا يمكن حذف الامتحان ", "error", {
              button: "متابعة"
            });

            break;
          default:
            console.log("other error");
        }
      });
  }

  handleSearch(value){
    this.setState({searchValue: value})
    const exams = this.state.exams
    const searchedExams = exams.filter(exam => exam.title.toLowerCase().includes(value.toLowerCase()))
    this.setState({searchedExams})
  }

  renderExams() {
    const exams = this.state.searchValue? this.state.searchedExams : this.state.exams || [];
    const courseId = this.props.match.params.id;

    return exams.map(exam => (
      <React.Fragment>
        <div className="item-view d-flex align-items-center">
          <div className="media w-100 d-flex align-items-center justify-content-between">
            <div className="media-body">
              <h6 className="dark-text">{exam.title}</h6>
              <div className="d-flex align-items-center">
                <h6 className="dark-silver-text smaller mb-0 mr-3">
                  {exam.description}
                </h6>
              </div>
            </div>
            <div>
            <Link to={`/courses/${courseId}/exams/details/${exam.id}`} className="">
                <i
                  class="far fa-eye bg-prima text-white d-flex justify-content-center align-items-center"
                  style={{ borderRadius: "50%",backgroundColor:'#2bc3cc',width:"22px",height:'22px',fontSize:'11px' }}
                ></i>
              </Link>
              <ExamDelete  onClick={() => this.toggle(exam.id)} />
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  }

  render() {
    const courseId = this.props.match.params.id;

    return (
      <>
      {this.state.isPageLoading ? (
        <div
        className="silver-bg box-layout w-100 pb-0 p-3 mt-4 d-flex justify-content-center align-items-center"
        style={{ minHeight: 350 }}
      >
        <Loader type="ball-spin-fade-loader" className="dark-loader" />
      </div>
      ) : (
      <React.Fragment>
        {/* <ReactPlayer
                        width="100%"
                        height="240"
                        url={process.env.PUBLIC_URL + "/assets/video/Ins1.mp4"}
                        controls="true"
                        playing="true"
                      /> */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center responsive-col">
              <h6 className="dark-text small mb-0 mt-0">
                {this.state.title}
              </h6>
              <ExamAddBtn title={`${this.state.newText} جديد`} onClick={this.openExamModal} />
              <ExamComponent
                match={this.props.match}
                newText={this.state.newText}
                isExamOpen={this.state.isExamOpen}
                closeExam={this.closeExamModal}
                courseId={courseId}
                onCreate={this.getQuestion}
                type={this.state.type}
              />
            </div>
          </div>
          {
             this.state.exams.length > 0  ? (
            <div className="col-10 col-md-8 col-lg-6">
              <div className="d-flex align-items-center border rounded mt-2">
                <input
                  className="exam-search-box border-0 w-80 px-2"
                  value={this.state.searchValue}
                  onChange={(e) => this.handleSearch(e.target.value)}
                  placeholder={`ابحث عن عنوان ${this.state.newText}`}
                />
                <div className="d-flex align-items-center justify-content-center w-20">
                <BsSearch className="main-color" />

                </div>
              </div>
            </div>
            ) : null
          }
        </div>

        <div className="row no-gutters">
          <div className="col-12">
            {this.state.exams == undefined || this.state.exams.length == 0 ? (
              <React.Fragment>
                <div
                  className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center mb-3"
                  style={{ height: 300 }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/empty-exams.png"
                    }
                    height="80"
                    className="contain-img mb-3"
                  />
                  <p className="dark-silver-text mt-0 mb-0">
                    لا يوجد اختبارات متاحة
                  </p>
                </div>
              </React.Fragment>
            ) :
            (this.state.searchValue && this.state.searchedExams.length == 0) ? (
              <React.Fragment>
                <div
                  className="box-layout shadow-sm d-flex flex-column w-100 rounded p-4 justify-content-center align-items-center mb-3"
                  style={{ height: 300 }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/empty-exams.png"
                    }
                    height="80"
                    className="contain-img mb-3"
                  />
                  <p className="dark-silver-text mt-0 mb-0">
                    لا يوجد اختبارات متاحة تحتوي على نص البحث
                  </p>
                </div>
              </React.Fragment>
              ) : (
                <div className="box-layout shadow-sm">{this.renderExams()}</div>
              )}
          </div>
        </div>


        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggle}
            className="d-flex align-items-center"
          >
            <h6 className="mb-0 dark-text">حذف اختبار</h6>
          </ModalHeader>
          <ModalBody className="d-flex align-items-center">
            <p className="dark-text small mb-0">
              هل أنت متأكد من حذف الاختبار؟
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              className="light-btn"
              onClick={() => this.deleteExam(this.state.examId)}
            >
              تأكيد
            </Button>{" "}
            <Button size="sm" className="red-btn" onClick={this.toggle}>
              إغلاق
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
       )}
       </>
    );
  }
}

export const ExamsList = withRouter(_ExamsList)