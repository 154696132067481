import React, { Component } from "react";
import Modal from "react-modal";
import { inputField } from "../../../shared/inputs/inputField";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import CounterInput from "react-counter-input";
import { RadioField } from "../../../shared/inputs/RadioFeild";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import { MathEditor } from "../../../shared/editor/editor";
import { verticalRadioField } from "../../../shared/inputs/verticalRadioField";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import swal from "@sweetalert/with-react";
import { getQuestion } from "../../../../actions";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...inputProps}
    {...props}
  />
);

const validate = values => {
  const errors = {};
  if (!values.level) {
    errors.level = "يجب تعبئة هذه الخانة";
  }
  if (!values.explanation) {
    errors.explanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.solutionExplanation) {
    errors.solutionExplanation = "يجب تعبئة هذه الخانة";
  }
  if (!values.first) {
    errors.first = "يجب تعبئة هذه الخانة";
  }
  if (!values.second) {
    errors.second = "يجب تعبئة هذه الخانة";
  }
  if (!values.third) {
    errors.third = "يجب تعبئة هذه الخانة";
  }
  if (!values.forth) {
    errors.forth = "يجب تعبئة هذه الخانة";
  }
  if (!values.correctChoice) {
    errors.correctChoice = "يجب تعبئة هذه الخانة";
  }
  return errors;
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export class EditQuestionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      activeInnerTab: "4",
      activeHintTab: "6",
      file: null,
      imageUrl: null,
      solutionFile: null,
      solutionVideo: null,
      hintFile: null,
      hintVideo: null,
      count: null,
      tags: [],
      suggestions: [],
      topics: [],
      questionId: null,

      // to overcome performance issues with redux-forms and the editor
      stemML: null,
      explanationML: null,
      solutionML: null
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onSolutionInputChange = this.onSolutionInputChange.bind(this);
    this.onHintInputChange = this.onHintInputChange.bind(this);
  }

  onSolutionInputChange(file) {
    this.setState({
      solutionFile: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({
          solutionVideo: response.data.data.url,
          imageLoader: false
        });
      })
      .catch(error => {
        this.setState({ solutionFile: null, imageLoader: false });
      });
  }

  onHintInputChange(file) {
    this.setState({
      hintFile: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({
          hintVideo: response.data.data.url,
          imageLoader: false
        });
      })
      .catch(error => {
        this.setState({ hintFile: null, imageLoader: false });
      });
  }

  onFileInputChange(file) {
    this.setState({
      file: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({ imageUrl: response.data.data.url, imageLoader: false });
      })
      .catch(error => {
        this.setState({ file: null, imageLoader: false });
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps &&
      this.props.questionId !== nextProps.questionId &&
      nextProps.questionId !== null
    ) {
      this.props.getQuestion(nextProps.questionId);
      this.setState({ questionId: nextProps.questionId });
    }
    return true;
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${apiBaseUrl}/subjects/assigned`, { headers })
      .then(response => {
        const arrayObj = response.data.data.topics;
        const suggestionsArr = arrayObj.map(item => {
          return {
            id: item.id,
            text: item.name
          };
        });
        this.setState({
          subjectDetails: response.data.data,
          suggestions: suggestionsArr
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleInner(tab) {
    if (this.state.activeInnerTab !== tab) {
      this.setState({
        activeInnerTab: tab
      });
    }
  }
  toggleHint(tab) {
    if (this.state.activeHintTab !== tab) {
      this.setState({
        activeHintTab: tab
      });
    }
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
    this.setState(state => ({ topics: [...state.topics, tag.id] }));
  }

  myFormHandler = values => {
    let examId = this.props.examId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = {
      topics: this.state.topics,
      duration: this.state.count,
      level: values.level,
      explanation: {
        type: values.explanation == undefined ? null : "Text",
        value: values.explanation
      },
      solutionExplanation: {
        type: this.state.solutionFile
          ? "Video"
          : values.solutionExplanation
          ? "Text"
          : null,
        value: values.solutionExplanation
      },
      stem: values.stem,
      imageUrl: this.state.imageUrl,
      choices: {
        A: values.first,
        B: values.second,
        C: values.third,
        D: values.forth
      },
      correctChoice: values.correctChoice
    };
    axios
      .put(`${apiBaseUrl}/questions/${this.state.questionId}`, data, {
        headers
      })
      .then(response => {
        let questionData = {
          questionId: response.data.data.id
        };
        axios
          .post(`${apiBaseUrl}/Exams/${examId}/Questions`, questionData, {
            headers
          })
          .then(() => {
            this.props.closeQuestion();
            window.location.reload();
          })
          .catch(error => {
            switch (error.response.data && error.response.data.error) {
              case "ExamIsLockedError":
                swal("عفواً", "لا يمكن إضافة أسئلة لهذا الامتحان", "error", {
                  button: "متابعة"
                });
                break;
              case "QuestionAlreadyAddedError":
                swal("عفواً", "السـؤال مضاف سابقاً", "error", {
                  button: "متابعة"
                });
                break;
              case "ClientError":
                swal("عفواً", "حصل خطأ مت", "error", {
                  button: "متابعة"
                });
                break;
              case "AccessDeniedError":
                swal("عفواً", "لا يوجد لديك صلاحية لإضافة سؤال", "error", {
                  button: "متابعة"
                });
                break;
              case "NotFoundError":
                swal("عفواً", "لم يتم العثور على السؤال/الامتحان", "error", {
                  button: "متابعة"
                });
                break;
              default:
                console.log("other error");
            }
          });
      })
      .catch(error => {
        switch (error.response.data && error.response.data.error) {
          case "AccessDeniedError":
            swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
              button: "متابعة"
            });
            break;
          default:
            console.log("other error");
        }
      });
  };

  render() {
    const { tags, suggestions } = this.state;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: "auto",
        borderWidth: 0
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 2,
        zIndex: 20
      }
    };
    const { isEditQuestionOpen, closeQuestion, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isEditQuestionOpen}
          onRequestClose={closeQuestion}
          closeQuestion={closeQuestion}
        >
          <div className="container">
            <form onSubmit={handleSubmit(this.myFormHandler)}>
              <div className="row">
                <div className="col-md-3 col-12">
                  <Nav vertical className="question-tabs">
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "1" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        بيانات السؤال
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "2" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        بيانات إضافية
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "3" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        مساعدة
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "4" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        طريقة الحل
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div
                    className="row mt-3 position-absolute w-100"
                    style={{ bottom: 0 }}
                  >
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                      <button className="btn light-btn w-100">حفظ</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-md-12">
                          <Field name="stem" component={MathEditor} />
                          {/* <MathEditor
                            onChange={val => this.setState({ stemML: val })}
                            value={this.state.stemML}
                          /> */}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <Field
                            component={verticalRadioField}
                            name="correctChoice"
                            options={[
                              { title: "", value: "A" },
                              { title: "", value: "B" },
                              { title: "", value: "C" },
                              { title: "", value: "D" }
                            ]}
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name="first"
                            type="text"
                            component={inputField}
                            className="form-control border-left-0 pl-1"
                            placeholder="الإجابة الأولى"
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name="second"
                            type="text"
                            component={inputField}
                            className="form-control border-left-0 pl-1"
                            placeholder="الإجابة الثانية"
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name="third"
                            type="text"
                            component={inputField}
                            className="form-control border-left-0 pl-1"
                            placeholder="الإجابة الثالثة"
                          />
                        </div>
                        <div className="col-12">
                          <Field
                            name="forth"
                            type="text"
                            component={inputField}
                            className="form-control border-left-0 pl-1"
                            placeholder="الإجابة الرابعة"
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-12">
                          <h6 className="dark-text">صورة توضيحية</h6>
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="uploadImage"
                            className="clickable w-100"
                          >
                            <div
                              className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mb-3"
                              style={{ height: 200 }}
                            >
                              {this.state.file ? (
                                <React.Fragment>
                                  {!this.state.imageLoader && (
                                    <img
                                      src={
                                        this.state.file ||
                                        process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                      }
                                      className="contain-img"
                                      width="100%"
                                    />
                                  )}
                                  {this.state.imageLoader && (
                                    <Loader type="ball-clip-rotate" />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/upload.png"
                                    }
                                    height="30"
                                    width="30"
                                    className="contain-img clickable"
                                  />
                                  <p className="dark-silver-text small mb-0">
                                    أرفق صورة
                                  </p>
                                </React.Fragment>
                              )}
                            </div>
                          </label>
                          <Field
                            component={FileInput}
                            name="imageUrl"
                            className="d-none"
                            id="uploadImage"
                            onChange={this.onFileInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h6 className="dark-text">التصنيفات</h6>
                        </div>
                        <div className="col-md-12">
                          <ReactTags
                            tags={tags}
                            suggestions={suggestions}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            delimiters={delimiters}
                            inputFieldPosition="top"
                            placeholder="اختر التصنيف.."
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <h6 className="dark-text">درجة الصعوبة</h6>

                          <Field
                            component={RadioField}
                            name="level"
                            options={[
                              { title: "سهل", value: "Easy" },
                              { title: "متوسط", value: "Medium" },
                              { title: "صعب", value: "Hard" }
                            ]}
                          />
                        </div>
                        <div className="col-md-6">
                          <h6 className="dark-text">الوقت</h6>

                          <div className="d-flex align-items-center flex-row counter-input mt-3">
                            <CounterInput
                              min={30}
                              onCountChange={count =>
                                this.setState({ count: count })
                              }
                              inputStyle={{
                                fontFamily: "MontserratMedium"
                              }}
                              count={this.props.initialValues.duration}
                            />
                            <h6 className="dark-text small mb-0 ml-3">
                              ثانية لحل السؤال
                            </h6>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "6"
                            })}
                            onClick={() => {
                              this.toggleHint("6");
                            }}
                          >
                            كتابة طريقة المساعدة للسؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "7"
                            })}
                            onClick={() => {
                              this.toggleHint("7");
                            }}
                          >
                            إضافة المساعدة بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeHintTab}>
                        <TabPane tabId="6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3">
                                <Field
                                  name="explanation"
                                  component={MathEditor}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <Row>
                            <Col sm="12">
                              <label
                                htmlFor="hintVideo"
                                className="clickable w-100"
                              >
                                <div
                                  className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                                  style={{ height: 300 }}
                                >
                                  {this.state.hintFile ? (
                                    <React.Fragment>
                                      {!this.state.imageLoader && (
                                        <img
                                          src={
                                            this.state.hintFile ||
                                            process.env.PUBLIC_URL +
                                              "/assets/images/upload.png"
                                          }
                                          className="contain-img"
                                          width="100%"
                                        />
                                      )}
                                      {this.state.imageLoader && (
                                        <Loader type="ball-clip-rotate" />
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                        }
                                        height="30"
                                        width="30"
                                        className="contain-img clickable"
                                      />
                                      <p className="dark-silver-text small mb-0">
                                        اضف طريقة المساعدة عبر فيديو
                                      </p>
                                    </React.Fragment>
                                  )}
                                </div>
                              </label>

                              <Field
                                component={FileInput}
                                name="hintVideo"
                                className="d-none"
                                id="hintVideo"
                                onChange={this.onHintInputChange}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="4">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "4"
                            })}
                            onClick={() => {
                              this.toggleInner("4");
                            }}
                          >
                            كتابة طريقة حل السؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "5"
                            })}
                            onClick={() => {
                              this.toggleInner("5");
                            }}
                          >
                            إضافة الحل بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeInnerTab}>
                        <TabPane tabId="4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3">
                                <Field
                                  name="solutionExplanation"
                                  component={MathEditor}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <Col sm="12">
                              <label
                                htmlFor="solutionVideo"
                                className="clickable w-100"
                              >
                                <div
                                  className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                                  style={{ height: 300 }}
                                >
                                  {this.state.solutionFile ? (
                                    <React.Fragment>
                                      {!this.state.imageLoader && (
                                        <img
                                          src={
                                            this.state.solutionFile ||
                                            process.env.PUBLIC_URL +
                                              "/assets/images/upload.png"
                                          }
                                          className="contain-img"
                                          width="100%"
                                        />
                                      )}
                                      {this.state.imageLoader && (
                                        <Loader type="ball-clip-rotate" />
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                        }
                                        height="30"
                                        width="30"
                                        className="contain-img clickable"
                                      />
                                      <p className="dark-silver-text small mb-0">
                                        اضف طريقة الحل عبر فيديو
                                      </p>
                                    </React.Fragment>
                                  )}
                                </div>
                              </label>
                              <Field
                                component={FileInput}
                                name="solutionVideo"
                                className="d-none"
                                id="solutionVideo"
                                onChange={this.onSolutionInputChange}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.questions,
    entireState: state
  };
}

EditQuestionComponent = reduxForm({
  form: "EditQuestion",
  enableReinitialize: true,
  destroyOnUnmount: false
})(EditQuestionComponent);

EditQuestionComponent = connect(
  mapStateToProps,
  { getQuestion }
)(EditQuestionComponent);

export const EditQuestion = withRouter(EditQuestionComponent);
