import React, { Component } from "react";
import { apiBaseUrl } from "../../../../../api/helpers";
import axios from "axios";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import swal from "@sweetalert/with-react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CustomTooltip from "../custom-tooltip/custom-tooltip";
import SendingModal from "../../sharedComponents/SendingModal";

var moment = require("moment");
moment().format("");
export class Reply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditReplyForm: false,
      replyValue: props.reply.value,
      replyType: props.reply.type,
      disabled: false,
      isPrivate: false,
      isSending: false,
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.confirmSettingReplyPrivacy = this.confirmSettingReplyPrivacy.bind(this);
  }

  componentDidMount() {
    this.setState({ 
      isPrivate: this.props.reply.isPrivate 
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.rap){
      if(prevProps.playedId !== this.props.playedId){
        if(this.props.playedId !== this.props.reply.id){
          this.rap.audioEl.current.pause()
        }
      }
    } 

    if(prevProps.commentIsPrivate !== this.props.commentIsPrivate){
      this.setState({isPrivate: this.props.commentIsPrivate})
     }
      
  }

  handlePlay(id) {
    this.props.setPlayedId(id)
  }

  toggleEditReplyForm() {
    this.setState({
      showEditReplyForm: !this.state.showEditReplyForm,
      replyValue: this.props.reply.value,
      replyType: this.props.reply.type,
    });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.replyInput.value !== "") {
      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      let data = {
        type: this.state.replyType,
        value: this.state.replyValue,
      };
      this.setState({ 
        disabled: true,    
        isSending: true,
        message: "جاري تعديل الرد", 
      });
      axios
        .put(
          `${apiBaseUrl}/QuickQuestionComments/Replies/${this.props.reply.id}`,
          data,
          {
            headers,
          }
        )
        .then((response) => {
          const reply = response.data.data;
          this.props.reply.value = reply.value;
          this.props.reply.type = reply.type;
          this.setState({
            showEditReplyForm: false,
            replyValue: reply.value,
            replyType: reply.type,
            disabled: false,
            isSending: false,
            message: "",
          });
          swal("تم تعديل الرد بنجاح ", {
            button: "متابعة",
            icon: "success",
          });
        })
        .catch((error) => {
          this.setState({ 
            disabled: false, 
            isSending: false,
            message: "", 
          });
          swal("عفواً", "لم يتم تعديل الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
            button: "متابعة",
          });
          console.log(error);
        });
    }
  }

  confirmSettingReplyPrivacy(id, status) {
    if(this.state.isPrivate === status) return null;

    swal({
      title: status? "هل أنت متأكد من إلغاء نشر الرد" : "هل أنت متأكد من نشر الرد؟",
      text: status? "سيتم حجب الرد لباقي طلاب الدورة" : "سيتم ظهور الرد لطلاب الدورة",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
    })
    .then((value) => {
      switch (value) {
        case "ok":
          this.setReplyPrivacy(id)
          break;
        default:
          return;
      }
    });
  }

  setReplyPrivacy(id) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
    .post(`${apiBaseUrl}/AskQuestionComments/UpdateReplyIsPrivate?id=${id}`,
    {data: ""}, 
    {headers})
    .then(response=>{
      this.setState({isPrivate: !this.state.isPrivate})
      swal("تم تغيير الخصوصية بنجاح!", {
        buttons: false,
        icon: "info",
        timer: 1000,
      });
    })
    .catch(error=>{
      swal("عفواً", "لم يتم تغيير الخصوصية برجاء إعادة المحاولة ", "error", {
        button: "متابعة",
      });
      console.log(error);
    })

  }

  render() {
    const reply = this.props.reply;
    let myIdentity = this.props.user && this.props.user.id;
    const commentIsPrivate = this.props.commentIsPrivate

    //Reply date
    let hijriDate = moment(reply.createdAt).format("YYYY/M/D HH:mm");
    //Commenter id
    const commenterId = reply.user && reply.user.id;
    return (
      <React.Fragment>
        <div className="silver-bg py-3 pr-3 pl-5">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/blue-user.png"}
                width="25"
                className="contain-img mr-2"
              />
              <div>
                <h6 className="light-text small mb-1 mr-3">
                  {reply.user && reply.user.name}
                </h6>
                <h6 className="dark-silver-text smaller en-text mb-0">
                  {hijriDate}
                </h6>
              </div>
            </div>
            <div className="d-flex flex-column-reverse align-items-start flex-sm-row align-items-sm-center justify-content-end">
              {
                 commentIsPrivate? (
                  <CustomTooltip isPrivate={this.state.isPrivate} isReply /> 
                ) : (
                  <UncontrolledDropdown>
                    <DropdownToggle>
                      <div className="bg-white">
                        <i className="fas fa-chevron-down font-13 align-self-center light-text"></i>
                        <CustomTooltip isPrivate={this.state.isPrivate} isImgClickable isReply />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <div 
                          onClick={()=>this.confirmSettingReplyPrivacy(reply?.id, true)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/dark-lock.png"
                              }
                              width="20"
                              alt=""
                              className="contain-img clickable mr-2"
                            />
                            <span className="light-text">خاص</span>
                          </div>
                          {
                            this.state.isPrivate ? (
                            <div className="active-dote ml-2" />
                            ) : null
                          }
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <div 
                          onClick={()=>this.confirmSettingReplyPrivacy(reply?.id, false)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/users.png"
                              }
                              width="20"
                              alt=""
                              className="contain-img clickable mr-2"
                            />
                            <span className="light-text">عام</span>
                          </div>
                          {
                            !this.state.isPrivate ? (
                            <div className="active-dote ml-2" />
                            ) : null
                          }
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              <div>

                {reply.type === "Text" && (
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/edit.png"}
                    width="20"
                    className="contain-img mr-2 clickable"
                    onClick={() => this.toggleEditReplyForm()}
                    alt="Edit-Icon"
                  />
                )}

                <img
                  src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                  width="20"
                  className="contain-img clickable"
                  onClick={() => this.props.onDelete()}
                  alt="remove-icon"
                />
              </div>
            </div>
          </div>
          {this.state.showEditReplyForm ? (
            <div style={{ minHeight: 140 }}>
              <form onSubmit={this.handleSubmit}>
                <textarea
                  type="text"
                  name="replyValue"
                  onChange={this.handleChange}
                  value={this.state.replyValue}
                  rows="4"
                  className="form-control small dark-text shadow-sm mb-3"
                  id="replyInput"
                  ref={(ref) => (this.replyInput = ref)}
                />
                <button
                  type="submit"
                  className="btn light-outline-btn btn-sm unset-height pl-5 pr-5 float-right"
                  disabled={this.state.disabled}
                >
                  ارسال
                </button>
              </form>
            </div>
          ) : (
            <React.Fragment>
            {reply.type === "Mixed" ? (
              <div>
                <p className="dark-text smaller mb-2">
                {reply.value}
                </p>
                <img
                  src={reply.imageUrl}
                  height="200"
                  className="contain-img w-100 max-width-300px"
                  alt="CommentImage"
                />
              </div>
            ) : reply.type === "Text" ? (
              <p className="dark-text smaller mb-2">
                {reply.value}
              </p>
            ) : reply.type === "Voice" ? (
              <ReactAudioPlayer 
                onPlay={()=>this.handlePlay(reply.id)}  
                ref={(element) => { this.rap = element }} 
                src={reply.voiceUrl? reply.voiceUrl : reply.value} 
                controls 
                className="w-100"
                type="audio/mpeg"
              />
            ) : reply.type === "Image" ? (
              <img
                src={reply.imageUrl? reply.imageUrl : reply.value}
                height="200"
                className="contain-img w-100 max-width-300px"
                alt="replyImage"
              />
            ) : (
              // <video
              //   src={reply.videoUrl? reply.videoUrl : reply.value}
              //   height="200"
              //   className="contain-img w-100 max-width-300px"
              //   controls
              // >
              //   <source
              //     type="video/mp4"
              //     data-reactid=".0.1.0.0.0"
              //     src={reply.videoUrl? reply.videoUrl : reply.value}
              //   />
              // </video>

              <div className='player-wrapper'>
                <ReactPlayer 
                  className='react-player'
                  controls
                  playIcon	
                  width="100%"
                  height="100%"
                  url={reply.videoUrl?reply.videoUrl:reply.value} 
                />
              </div>
            )}
            </React.Fragment>
            )}
        </div>
        <hr className="mt-0 mb-0" />

        <SendingModal isOpen={this.state.isSending} message={this.state.message} />

      </React.Fragment>
    );
  }
}
