import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiBaseUrl } from "../../api/helpers";
import "./styles.sass";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { categories: [] };
  }
  componentDidMount() {
    axios
      .get(`${apiBaseUrl}/categories`)
      .then((response) => {
        this.setState({ categories: response.data.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  renderCategories() {
    const cats = this.state.categories;
    return (
      <React.Fragment>
        <div className="row w-75 mx-auto d-flex justify-content-center align-items-center">
          {cats.map((cat, i) => {
            return (
              <div className="col-lg-3 col-6" key={cat.id}>
                <Link
                  to={{
                    pathname: `/categories/details/${cat.slug}`,
                    state: {
                      catId: cat.id,
                    },
                  }}
                  key={cat.id}
                >
                  <div
                    key={cat.id}
                    className="shadow-box custom-height d-flex flex-column align-items-center justify-content-center clickable"
                  >
                    <img
                      key={cat.id}
                      src={cat.icon}
                      height="32"
                      className="mb-2"
                    />
                    <h6 className="dark-text small text-center mb-0">
                      {cat.nameAr}
                    </h6>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
  render() {
    return (
      <React.Fragment>
        <section className="hero-section">
          <div className="container">
            <div className="row h-100 d-flex align-items-center">
              <div className="col-md-4">
                <h3 className="dark-text">سلسلة بالبيد التعليمية</h3>
                <h3 className="dark-text">٢٥ عاماً في خدمة</h3>
                <h3 className="light-text"> الطلاب و الطالبات</h3>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/home-artwork.png"
                  }
                  width="100%"
                  className="contain-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="categories-section mt-4">
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                <h4 className="dark-text mb-1">منصاتنا</h4>
                <p className="dark-text">
                  تعرف على ابرز منصاتنا التعليمية القيمة{" "}
                </p>
              </div>
            </div>
            {this.renderCategories()}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
