import React, { Component } from "react";

export class RadioField extends Component {
  render() {
    const { input, meta, options } = this.props;
    const hasError = meta.touched && meta.error;
    return (
      <React.Fragment>
        <div className="form-check form-check-inline">
          {options.map((o) => (
            <label
              className="form-check-label dark-silver-text light-font-text small mr-3"
              key={o.value}
            >
              <input
                type="radio"
                {...input}
                value={o.value}
                onClick={() => this.props.onClick(o.value)}
                checked={o.value === input.value}
                className="mr-2"
              />
              {o.title}
            </label>
          ))}
        </div>
        <div>
          {hasError && <small className="w-100 smaller">{meta.error}</small>}
        </div>
      </React.Fragment>
    );
  }
}
