import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentChannel } from "../../../actions";

class ChannelsList extends Component {
  render() {
    const { chatChannels } = this.props;
    if (chatChannels) {
      return chatChannels.map(channel => (
        <div
          key={channel.id}
          className={`media chat-item d-flex align-items-center clickable h-55 ${this.props.channel.id === channel.chatChannelSid ? 'bg-light' : ''}`}
          onClick={() => { this.props.setCurrentChannel({ id: channel.chatChannelSid }) }}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/user-circle.png"}
            alt="Chat img"
            height="27"
            className="contain-img mr-2"
          />
          <div className="media-body">
            <h6 className="small mid-text mb-0">{channel.group.name}</h6>
          </div>
        </div>
      ));
    }
  }
}

function mapStateToProps(state) {
  return {
    channel: state.channel.currentChannel,
  };
}

const mapDispatchToProps = {
  setCurrentChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsList);
