import React from 'react'
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";

import {
    Modal,
    ModalBody,
  } from 'reactstrap';

export const SendingModal = ({message, isOpen}) => {
  return (
    <Modal returnFocusAfterClose={true} isOpen={isOpen} fullscreen centered>
        <ModalBody>
          <div
          className=" w-100 p-3 d-flex justify-content-center align-items-center
          "
          >
            <p className="mt-10 dark-text mr-2">{message}</p>
            <Loader
              type="ball-beat"
              className="dark-loader"
            />
          </div>
        </ModalBody>
      </Modal>
  )
}

export default SendingModal