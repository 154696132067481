import React, { Component } from "react";
import Modal from "react-modal";
import { inputField } from "../../../shared/inputs/inputField";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import CounterInput from "react-counter-input";
import { RadioField } from "../../../shared/inputs/RadioFeild";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import { MathEditor } from "../../../shared/editor/editor";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import swal from "@sweetalert/with-react";
import { getQuestion } from "../../../../actions";

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...inputProps}
    {...props}
  />
);

export class ForkQuestionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      activeInnerTab: "4",
      activeHintTab: "6",
      file: null,
      imageUrl: null,
      solutionFile: null,
      solutionVideo: null,
      hintFile: null,
      hintVideo: null,
      count: null,
      topics: []
    };
    this.toggle = this.toggle.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onSolutionInputChange = this.onSolutionInputChange.bind(this);
    this.onHintInputChange = this.onHintInputChange.bind(this);
  }

  onSolutionInputChange(file) {
    this.setState({
      solutionFile: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({
          solutionVideo: response.data.data.url,
          imageLoader: false
        });
      })
      .catch(error => {
        this.setState({ solutionFile: null, imageLoader: false });
      });
  }

  onHintInputChange(file) {
    this.setState({
      hintFile: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({
          hintVideo: response.data.data.url,
          imageLoader: false
        });
      })
      .catch(error => {
        this.setState({ hintFile: null, imageLoader: false });
      });
  }

  onFileInputChange(file) {
    this.setState({
      file: URL.createObjectURL(file),
      imageLoader: true
    });
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("File", file);
    axios
      .post(`${apiBaseUrl}/Questions/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({ imageUrl: response.data.data.url, imageLoader: false });
      })
      .catch(error => {
        this.setState({ file: null, imageLoader: false });
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps &&
      this.props.questionId !== nextProps.questionId &&
      nextProps.questionId !== null
    ) {
      this.props.getQuestion(nextProps.questionId);
    }
    return true;
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleInner(tab) {
    if (this.state.activeInnerTab !== tab) {
      this.setState({
        activeInnerTab: tab
      });
    }
  }
  toggleHint(tab) {
    if (this.state.activeHintTab !== tab) {
      this.setState({
        activeHintTab: tab
      });
    }
  }

  myFormHandler = () => {
    let examId = this.props.examId;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = this.props.initialValues;

    axios
      .post(`${apiBaseUrl}/questions`, data, { headers })
      .then(response => {
        let questionData = {
          questionId: response.data.data.id
        };
        axios
          .post(`${apiBaseUrl}/Exams/${examId}/Questions`, questionData, {
            headers
          })
          .then(response => {
            this.props.closeQuestion();
            window.location.reload();
          })
          .catch(error => {
            switch (error.response.data && error.response.data.error) {
              case "ExamIsLockedError":
                swal("عفواً", "لا يمكن إضافة أسئلة لهذا الامتحان", "error", {
                  button: "متابعة"
                });
                break;
              case "QuestionAlreadyAddedError":
                swal("عفواً", "السـؤال مضاف سابقاً", "error", {
                  button: "متابعة"
                });
                break;
              case "ClientError":
                swal("عفواً", "حصل خطأ مت", "error", {
                  button: "متابعة"
                });
                break;
              case "AccessDeniedError":
                swal("عفواً", "لا يوجد لديك صلاحية لإضافة سؤال", "error", {
                  button: "متابعة"
                });
                break;
              case "NotFoundError":
                swal("عفواً", "لم يتم العثور على السؤال/الامتحان", "error", {
                  button: "متابعة"
                });
                break;
              default:
                console.log("other error");
            }
          });
      })
      .catch(error => {
        switch (error.response.data && error.response.data.error) {
          case "AccessDeniedError":
            swal("عفواً", "لا تملك الصلاحية المطلوبة", "error", {
              button: "متابعة"
            });
            break;
          default:
            console.log("other error");
        }
      });
  };

  render() {
    const choices =
      this.props &&
      this.props.initialValues &&
      this.props.initialValues.choices;
    const correctChoice =
      this.props &&
      this.props.initialValues &&
      this.props.initialValues.correctChoice;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: "auto",
        borderWidth: 0,
        overflowX:'hidden'
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 2,
        zIndex: 20
      }
    };
    const { isQuestionOpen, closeQuestion, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isQuestionOpen}
          onRequestClose={closeQuestion}
          closeQuestion={closeQuestion}
        >
          <div className="container" style={{overflowX:'hidden'}}>
            <form onSubmit={handleSubmit(this.myFormHandler)}>
              <div className="row">
                <div className="col-md-3 col-12">
                  <Nav vertical className="question-tabs">
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "1" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        بيانات السؤال
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "2" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        بيانات إضافية
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "3" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        مساعدة
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`small dark-text clickable ${
                          this.state.activeTab === "4" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        طريقة الحل
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div
                    className="row mt-3 position-absolute w-100"
                    style={{ bottom: 0 }}
                  >
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                      <button className="btn light-btn w-100">حفظ</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-md-12">
                          <Field name="stem" component={MathEditor} />
                        </div>
                      </div>
                      <div className="row mt-3">
                        {choices && (
                          <React.Fragment>
                            <div className="col-12">
                              {Object.keys(choices).map(function(key) {
                                return (
                                  <div
                                    className={`box-layout  d-flex align-items-center pr-2 pl-2 mb-2 ${
                                      key == correctChoice
                                        ? "green-border"
                                        : null
                                    }`}
                                  >
                                    <h6
                                      className={`small dark-silver-text light-font-text d-flex align-items-center mb-0 img-auto-for-children ${
                                        key == correctChoice
                                          ? "green-text"
                                          : null
                                      }`}
                                      dangerouslySetInnerHTML={{
                                        __html: choices[key],
                                      }}
                                    >
                                    </h6>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-12">
                          <h6 className="dark-text">صورة توضيحية</h6>
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="uploadImage"
                            className="clickable w-100"
                          >
                            <div
                              className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mb-3"
                              style={{ height: 200 }}
                            >
                              <img
                                src={this.props.initialValues.imageUrl}
                                className="contain-img"
                                width="100%"
                                height="180"
                              />
                            </div>
                          </label>
                          <Field
                            component={FileInput}
                            name="imageUrl"
                            className="d-none"
                            id="uploadImage"
                            onChange={this.onFileInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h6 className="dark-text">التصنيفات</h6>
                        </div>
                        <div className="col-md-12">
                          {this.props.initialValues.topics && (
                            <React.Fragment>
                              {this.props.initialValues.topics.map(topic => (
                                <p className="badge light-bg text-white mb-0 mr-2">
                                  {topic.name}
                                </p>
                              ))}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <h6 className="dark-text">درجة الصعوبة</h6>

                          <Field
                            component={RadioField}
                            name="level"
                            options={[
                              { title: "سهل", value: "Easy" },
                              { title: "متوسط", value: "Medium" },
                              { title: "صعب", value: "Hard" }
                            ]}
                            disabled={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <h6 className="dark-text">الوقت</h6>

                          <div className="d-flex align-items-center flex-row counter-input mt-3">
                            <CounterInput
                              min={30}
                              onCountChange={count =>
                                this.setState({ count: count })
                              }
                              inputStyle={{
                                fontFamily: "MontserratMedium"
                              }}
                              count={this.props.initialValues.duration}
                            />
                            <h6 className="dark-text small mb-0 ml-3">
                              ثانية لحل السؤال
                            </h6>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "6"
                            })}
                            onClick={() => {
                              this.toggleHint("6");
                            }}
                          >
                            كتابة طريقة المساعدة للسؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeHintTab === "7"
                            })}
                            onClick={() => {
                              this.toggleHint("7");
                            }}
                          >
                            إضافة المساعدة بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeHintTab}>
                        <TabPane tabId="6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3">
                                <Field
                                  name="explanation"
                                  component={MathEditor}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <Row>
                            <Col sm="12">
                              <label
                                htmlFor="hintVideo"
                                className="clickable w-100"
                              >
                                <div
                                  className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                                  style={{ height: 300 }}
                                >
                                  {this.state.hintFile ? (
                                    <React.Fragment>
                                      {!this.state.imageLoader && (
                                        <img
                                          src={
                                            this.state.hintFile ||
                                            process.env.PUBLIC_URL +
                                              "/assets/images/upload.png"
                                          }
                                          className="contain-img"
                                          width="100%"
                                        />
                                      )}
                                      {this.state.imageLoader && (
                                        <Loader type="ball-clip-rotate" />
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                        }
                                        height="30"
                                        width="30"
                                        className="contain-img clickable"
                                      />
                                      <p className="dark-silver-text small mb-0">
                                        اضف طريقة المساعدة عبر فيديو
                                      </p>
                                    </React.Fragment>
                                  )}
                                </div>
                              </label>

                              <Field
                                component={FileInput}
                                name="hintVideo"
                                className="d-none"
                                id="hintVideo"
                                onChange={this.onHintInputChange}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="4">
                      <Nav tabs className="answer-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "4"
                            })}
                            onClick={() => {
                              this.toggleInner("4");
                            }}
                          >
                            كتابة طريقة حل السؤال
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeInnerTab === "5"
                            })}
                            onClick={() => {
                              this.toggleInner("5");
                            }}
                          >
                            إضافة الحل بفيديو
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeInnerTab}>
                        <TabPane tabId="4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3">
                                <Field
                                  name="solutionExplanation"
                                  component={MathEditor}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <Col sm="12">
                              <label
                                htmlFor="solutionVideo"
                                className="clickable w-100"
                              >
                                <div
                                  className="d-flex box-layout dashed-border align-items-center justify-content-center flex-column w-100 mt-4 mb-3"
                                  style={{ height: 300 }}
                                >
                                  {this.state.solutionFile ? (
                                    <React.Fragment>
                                      {!this.state.imageLoader && (
                                        <img
                                          src={
                                            this.state.solutionFile ||
                                            process.env.PUBLIC_URL +
                                              "/assets/images/upload.png"
                                          }
                                          className="contain-img"
                                          width="100%"
                                        />
                                      )}
                                      {this.state.imageLoader && (
                                        <Loader type="ball-clip-rotate" />
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/upload.png"
                                        }
                                        height="30"
                                        width="30"
                                        className="contain-img clickable"
                                      />
                                      <p className="dark-silver-text small mb-0">
                                        اضف طريقة الحل عبر فيديو
                                      </p>
                                    </React.Fragment>
                                  )}
                                </div>
                              </label>
                              <Field
                                component={FileInput}
                                name="solutionVideo"
                                className="d-none"
                                id="solutionVideo"
                                onChange={this.onSolutionInputChange}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.questions,
    entireState: state
  };
}

ForkQuestionComponent = reduxForm({
  form: "ForkQuestion",
  enableReinitialize: true,
  destroyOnUnmount: false
})(ForkQuestionComponent);

ForkQuestionComponent = connect(
  mapStateToProps,
  { getQuestion }
)(ForkQuestionComponent);

export const ForkQuestion = withRouter(ForkQuestionComponent);
