import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "../../../../actions/user.actions";
import axios from "axios";
import { apiBaseUrl } from "../../../../api/helpers";
import "./styles.sass";
import SubscriberGuideBtn from "../subscriber-guide/subscriberGuideBtn";

export class SidebarComponent extends Component {
 
  constructor(props) {
    super(props);

    this.state = {
      sections:[]
    };
  }
  componentDidMount() {
    this.getSections();
    if (this.props.authenticated) {
      this.props.getUser();
     
    }
  }
  getSections = ()=>{
    const courseId = this.props.match.params.id;

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
        .get(`${apiBaseUrl}/content/Sections/${courseId}/me`, { headers })
        .then(response => {
          this.setState({ sections: response.data.data});
        })
        .catch(error => {
          console.log(error);
        });
  };
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.getUser();
    }
  }
  render() {
    const user = this.props.user;
    const courseId = this.props.match.params.id;
    return (
      <React.Fragment>
        <div className="sidebar mb-4">
          <div className="header">
            <div className="d-inline-flex flex-md-column align-items-center">
              {this.props.user && this.props.user.gender == "Male" ? (
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/male-avatar.png"
                  }
                  height="70"
                  className="mr-3 contain-img"
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/female-avatar.png"
                  }
                  height="70"
                  className="mr-3 contain-img"
                />
              )}
              <div className="d-flex flex-column align-items-center">
                <h6 className="dark-text mb-0"> {user && user.name}</h6>
                {/* <div className="d-inline-flex">
                  <ul className="list-inline list-unstyled mt-2 mb-0 bordered-list">
                    <li className="list-inline-item">
                      <span className="en-text mid-text smaller">20</span>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/coins.png"
                        }
                        height="11"
                        className="ml-1 contain-img"
                      />
                    </li>
                    <li className="list-inline-item">
                      <span className="en-text mid-text smaller">99</span>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/trophy.png"
                        }
                        height="11"
                        className="ml-1 contain-img"
                      />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {
              this.props.subscriberGuideVideoURL? (
                <SubscriberGuideBtn url={this.props.subscriberGuideVideoURL} />
              ) : null
            }
          </div>
          <div className="sidebar-list">
            <ul className="list-unstyled mb-0">
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/schedule`}
                  activeClassName="active"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/course-schedule.png"
                    }
                    height="18"
                    width="18"
                    className="mr-2 contain-img"
                  />
                  جدول الدورة
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/recorded-lectures`}
                  activeClassName="active"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/course-recorded.png"
                    }
                    height="22"
                    width="22"
                    className="mr-2 contain-img"
                  />
                  المحاضرات المسجلة
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/Live-lectures`}
                  activeClassName="active"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/liveIcon.png"
                    }
                    height="22"
                    width="22"
                    className="mr-2 contain-img"
                    alt=""
                  />
                  المحاضرات المباشرة
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/courses/${courseId}/chat`}
                  activeClassName="active"
                  className="dark-text small"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/course-chat.png"
                    }
                    height="20"
                    width="20"
                    className="mr-2 contain-img"
                  />
                  الدردشة
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/discussions`}
                  activeClassName="active"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/course-discussions.png"
                    }
                    height="24"
                    width="24"
                    className="mr-2 contain-img"
                  />
                  المناقشات
                </NavLink>
              </li>
              { this.state.sections.length>0?(<li>
                <NavLink
                  to={`/courses/${courseId}/speed-up`}
                  activeClassName="active"
                  className="dark-text small"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/flash.png"}
                    height="20"
                    width="20"
                    className="mr-2 contain-img"
                  />
                  مرفقات الدورة
                </NavLink>
              </li>):null}
              
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/training/list`}
                  activeClassName="active"
                  className="dark-text small"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/training.png"
                    }
                    height="20"
                    width="20"
                    className="mr-2 contain-img"
                  />
                  التدريبات الإلكترونية
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/exams/list`}
                  activeClassName="active"
                  className="dark-text small"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/course-exam.png"
                    }
                    height="20"
                    width="20"
                    className="mr-2 contain-img"
                  />
                  الاختبارات الإلكترونية
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dark-text small"
                  to={`/courses/${courseId}/askQuestions`}
                  activeClassName="active"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/question.png"}
                    height="20"
                    width="20"
                    className="mr-2 contain-img"
                  />
                  اسأل مدرب
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

SidebarComponent = connect(mapStateToProps, { getUser })(SidebarComponent);

export const Sidebar = withRouter(SidebarComponent);
