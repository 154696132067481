import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import swal from "@sweetalert/with-react";

import { apiBaseUrl } from "../../../../api/helpers";

let token = localStorage.getItem("token");
let headers = {
  Authorization: `Bearer ${token}`,
};
const statuses = [
  {
    id: 1,
    name: "select-status",
    nameAr: "اختر الحالة",
  },
  {
    id: 2,
    name: "Draft",
    nameAr: "مسودة",
  },
  {
    id: 3,
    name: "Active",
    nameAr: "فعالة",
  },
  {
    id: 4,
    name: "Pending",
    nameAr: "ينتظر",
  },
  {
    id: 5,
    name: "Reject",
    nameAr: "مرفوض",
  },
];
export const SpeedUpDetails = (props) => {
  const speedupId = /[^/]*$/.exec(props?.location?.pathname)[0];
  const [fileData, setFileData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [status, setStatus] = useState("select-status");
  const [userData, setUserData] = useState({});
  const history = props?.history;

  const toggleDelete = () => {
    setDeleteModal((prevValue) => !prevValue);
  };
  const toggleEdit = async () => {
    setEditModal((prevValue) => !prevValue);
  };
  const toggleRefreshContent = async () => {
    setRefreshPage((prevValue) => !prevValue);
  };
  const deleteFile = async () => {
    setDeleteModal((prevValue) => !prevValue);

    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios
      .delete(`${apiBaseUrl}/content/speedups/${speedupId}`, {
        headers,
      })
      .then((response) => {
        history.goBack();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const editFile = () => {
    const request = axios({
      method: "put",
      url: `${apiBaseUrl}/Content/${speedupId}/Status?Status=${status}`,
      headers,
    });
    request
      .then((res) => {
        toggleEdit();
        toggleRefreshContent();
        swal({
          title: "تم بنجاح",
          text: "تم تعديل الملف بنجاح",
          icon: "success",
          button: "متابعة",
        });
      })
      .catch((error) => console.log({ error }));
  };

  useEffect(() => {
    (async () => {
      const url = `${apiBaseUrl}/content/speedups/${speedupId}`;
      axios
        .get(url, { headers })
        .then((response) => {
          setFileData(response.data?.data);
        })
        .catch((error) => {
          console.log({ error });
        });
      const profileUrl = `${apiBaseUrl}/Users/me`;
      axios
        .get(profileUrl, { headers })
        .then((response) => {
          setUserData(response.data?.data);
        })
        .catch((error) => {
          console.log({ error });
        });
    })();
  }, [speedupId, refreshPage]);
  const currentStatus =
    fileData?.status === "Active"
      ? "فعالة"
      : fileData?.status === "Draft"
      ? "مسودة"
      : fileData?.status === "Pending"
      ? "ينتظر"
      : fileData?.status === "Reject" && "مرفوض";
  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-sm d-flex align-items-center justify-content-center text-white red-bg px-3 ml-2 my-2"
          onClick={() => toggleDelete(fileData?.id)}
        >
          حذف
        </button>
        {userData?.noNeedReview && (
          <button
            type="button"
            className="btn light-btn btn-sm d-flex align-items-center justify-content-center px-3 ml-2 my-2"
            onClick={() => toggleEdit()}
          >
            تعديل
          </button>
        )}
      </div>
      <p>الحالة: {currentStatus}</p>
      {fileData.type == "Image" ? (
        <img src={fileData.url} alt="File" width="100%" height="auto" />
      ) : fileData.type == "Pdf" ? (
        <React.Fragment>
          <div className="d-flex align-items-center flex-column pt-5 pb-5">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/pdf.png"}
              alt="File"
              className="contain-img custom-img mx-auto"
              height="80"
            />
            <h6 className="light-text mt-3">{fileData.title}</h6>
            <a
              className="btn light-btn btn-sm pl-4 pr-4"
              href={fileData.url}
              target="_blank"
            >
              تحميل
            </a>
          </div>
        </React.Fragment>
      ) : fileData.type == "Video" ? (
        <ReactPlayer
          url={fileData.url}
          playing={true}
          controls={true}
          width="100%"
          height="100%"
        />
      ) : (
        <React.Fragment>
          <div className="d-flex align-items-center flex-column pt-5 pb-5">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/file-outline.png"}
              alt="File"
              className="contain-img custom-img mx-auto"
              height="80"
            />
            <h6 className="light-text mt-3">{fileData.title}</h6>
            <a
              className="btn light-btn btn-sm pl-4 pr-4"
              href={fileData.url}
              target="_blank"
            >
              تحميل
            </a>
          </div>
        </React.Fragment>
      )}
      <Modal isOpen={deleteModal} toggle={() => toggleDelete()}>
        <ModalHeader
          toggle={() => toggleDelete()}
          className="d-flex align-items-center"
        >
          <h6 className="mb-0 dark-text">حذف ملف</h6>
        </ModalHeader>
        <ModalBody className="d-flex align-items-center">
          <p className="dark-text small mb-0">هل أنت متأكد من حذف الملف؟</p>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" className="light-btn" onClick={() => deleteFile()}>
            تأكيد
          </Button>{" "}
          <Button size="sm" className="red-btn" onClick={() => toggleDelete()}>
            إغلاق
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editModal} toggle={() => toggleEdit()}>
        <ModalHeader
          toggle={() => toggleEdit()}
          className="d-flex align-items-center"
        >
          <h6 className="mb-0 dark-text">تعديل الملف</h6>
        </ModalHeader>
        <ModalBody className="d-flex align-items-center">
          <div className="col-md-6 col-12">
            <p className="dark-text small mb-0">اختر الحالة</p>
            <select
              defaultValue={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {statuses?.map((item) => {
                return (
                  <>
                    <option disabled={item?.id === 1} value={item.name}>
                      {item.nameAr}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" className="light-btn" onClick={() => editFile()}>
            تأكيد
          </Button>{" "}
          <Button size="sm" className="red-btn" onClick={() => toggleEdit()}>
            إغلاق
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
