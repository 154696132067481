import React from 'react';
import axios from 'axios'

export class Healthy extends React.Component {
    async componentDidMount() {
        try {
            const apiHealthBaseUrl = process.env["REACT_APP_API_ENDPOINT_Health"]
            await axios.get(`${apiHealthBaseUrl}ready`);
        } catch (error) {
            console.error(error);
        }
    }
    render() {
        return null;
    }
}