import React, { Component } from "react";
import { apiBaseUrl } from "../../../../../api/helpers";
import axios from "axios";
import { Reply } from "./reply";
import { ReactMic } from "react-mic";
import ProgressBar from "react-animated-progress-bar";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import swal from "@sweetalert/with-react";
import { SendingRequest } from "../../sharedComponents/sendingRequest";
import SendingModal from "../../sharedComponents/SendingModal";


var moment = require("moment-hijri");
moment().format("iYYYY/iM/iD");
export class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullReplies: false,
      showAddReplyForm: false,
      showEditCommentForm: false,
      replies: [],
      reply: "",
      file: "",
      replyType: "",
      commentValue: props.comment.value,
      commentType: props.comment.type,
      commentCorrect: props.comment.correct,
      blobObject: null,
      isRecording: false,
      isPaused: false,
      soundFile: "",
      videoFile: "",
      disabled: false,
      isSending: false,
      message: "",
      fileError: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleEditCommentSubmit = this.handleEditCommentSubmit.bind(this);
    this.onStop = this.onStop.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
  }

  handlePlay(id) {
    this.props.setPlayedId(id)
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.rap){
      if(prevProps.playedId !== this.props.playedId){
        if(this.props.playedId !== this.props.comment.id){
          this.rap.audioEl.current.pause()
        }
      }
    } 

  }
  startOrPauseRecording = () => {
    const { isPaused, isRecording } = this.state;

    if (isPaused) {
      this.setState({ isPaused: false });
    } else if (isRecording) {
      this.setState({ isPaused: true });
    } else {
      this.setState({ isRecording: true });
    }
  };

  stopRecording = () => {
    this.setState({ isRecording: false });
  };

  onStop(blobObject) {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("FileType", "Voice");
    data.append("File", blobObject.blob);
    data.append("Type", "Replies");
    this.setState({ isSending: true, message: "جاري التحميل", videoFile: "", file: "", });
    axios
      .post(`${apiBaseUrl}/RecordedLectureComments/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({
          soundFile: response.data.data.url,
          commentType: "Voice",
          disabled: false,
          isSending: false, 
          message: "",
        });
        // if (this.state.soundFile) {
        //   const lectureId = this.props.lectureId;
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`
        //   };
        //   let data = {
        //     type: "Voice",
        //     voiceUrl: this.state.soundFile
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers
        //       }
        //     )
        //     .then(response => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState(prevState => {
        //         return {
        //           replies: prevState.replies.concat(reply)
        //         };
        //       });
        //       this.setState({
        //         soundFile: "",
        //         disabled: false,
        //         isSending: false
        //       });
        //       swal("تم اضافة الرد بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch(error => {
        //       this.setState({ disabled: false, isSending: false });
        //       swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       });     
        //       console.log(error);
        //     });
        // }
      })
      .catch(error => {
        this.setState({disabled: false, isSending: false, message: "", })
        swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });  
        console.log(error);
      });
  }

  toggleEditCommentForm() {
    this.setState({
      showEditCommentForm: !this.state.showEditCommentForm,
      commentValue: this.props.comment.value,
      commentType: this.props.comment.type
    });
  }

  validateImage = (target) => {
    this.setState({ fileError: "" });

    // Start Image Validation
    const notAllowedImage = [
      "عفواً امتداد الصورة يجب أن يكون أحد الأنواع png, jpg, jpeg, svg",
      "مساحة الصورة يجب ألا تتجاوز 1 ميجا بايت",
    ];
    const endFile = target.value.slice(target.value.lastIndexOf(".") + 1);
    const isImage =
      endFile === "png" ||
      endFile === "jpeg" ||
      endFile === "svg" ||
      endFile === "jpg";

    if (!isImage) {
      this.setState({ file: "", fileError: notAllowedImage[0] });
      target.value = "";
      return;
    }

    let size = parseFloat(target.files[0].size / (1024 * 1024)).toFixed(2);
    if (size > 1) {
      this.setState({ file: "", fileError: notAllowedImage[1] });
      target.value = "";
      return;
    }

    const validatedFile = target.files[0];
    return validatedFile;

    // End Image Validation
  };

  handleFileChange = event => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("FileType", "Image");
    data.append("File", event.target.files[0]);
    data.append("Type", "Replies");
    this.setState({ disabled: true, isSending: true, message: "جاري التحميل", soundFile: "", videoFile: "", });
    axios
      .post(`${apiBaseUrl}/RecordedLectureComments/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({ file: response.data.data.url, commentType: "Image", disabled: false, isSending: false, message: "",});
        // if (this.state.file) {
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`
        //   };
        //   let data = {
        //     type: "Image",
        //     imageUrl: this.state.file
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers
        //       }
        //     )
        //     .then(response => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState(prevState => {
        //         return {
        //           replies: prevState.replies.concat(reply)
        //         };
        //       });
        //       this.setState({ file: "", disabled: false, isSending: false });
        //       swal("تم اضافة الرد بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch(error => {
        //       this.setState({ disabled: false, isSending: false });
        //       swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       }); 
        //       console.log(error);
        //     });
        // }
      })
      .catch(error => {
        this.setState({ disabled: false, isSending: false, message: "" });
        swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        }); 
        console.log(error);
      });
  };

  handleVideoChange = event => {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = new FormData();
    data.append("FileType", "Video");
    data.append("File", event.target.files[0]);
    data.append("Type", "Replies");
    this.setState({ isSending: true,  message: "جاري التحميل", soundFile: "", file: "", });
    axios
      .post(`${apiBaseUrl}/RecordedLectureComments/Uploads`, data, {
        headers
      })
      .then(response => {
        this.setState({ videoFile: response.data.data.url, commentType: "Video", isSending: false, message: "", });
        // if (this.state.videoFile) {
        //   let token = localStorage.getItem("token");
        //   let headers = {
        //     Authorization: `Bearer ${token}`
        //   };
        //   let data = {
        //     type: "Video",
        //     videoUrl: this.state.videoFile
        //   };
        //   this.setState({ disabled: true });
        //   axios
        //     .post(
        //       `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}/Replies`,
        //       data,
        //       {
        //         headers
        //       }
        //     )
        //     .then(response => {
        //       this.setState({
        //         showAddReplyForm: !this.state.showAddReplyForm
        //       });
        //       const reply = response.data.data;
        //       this.props.comment.latestReply = reply;
        //       this.setState(prevState => {
        //         return {
        //           replies: prevState.replies.concat(reply)
        //         };
        //       });
        //       this.setState({
        //         videoFile: "",
        //         disabled: false,
        //         isSending: false
        //       });
        //       swal("تم اضافة الرد بنجاح ", {
        //         button: "متابعة",
        //         icon: "success",
        //       });
        //     })
        //     .catch(error => {
        //       this.setState({ disabled: false, isSending: false });
        //       swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
        //         button: "متابعة",
        //       }); 
        //       console.log(error);
        //     });
        // }
      })
      .catch(error => {
        this.setState({isSending: false, message: "", })
        swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        }); 
        console.log(error);
      });
  };

  getReplies = () => {
    const latest = this.props.comment.latestReply;
    const all = this.state.replies;

    if (this.state.showFullReplies) {
      return all;
    }

    return latest ? [latest] : [];
  };
  confirmDeleteReply(id) {
    swal({
      // title: "تأكيد الحذف !",
      text: "هل تريد حذف هذا الرد على التعليق بشكل نهائي؟",
      icon: "warning",
      buttons: {
        ok: "تأكيد",
        cancel: "إلغاء",
      },
      dangerMode: true,
    }).then((value) => {
      switch (value) {
        case "ok":
          this.deleteReply(id);
          break;

        case "cancel":
          swal("لم يتم حذف الرد", {
            icon: "info",
            button: "متابعة",
          });
          break;

        default:
          swal("لم يتم حذف الرد", {
            icon: "info",
            button: "متابعة",
          });
      }
    });
  }
  deleteReply(id) {
    swal({
      content: <SendingRequest message="جاري الحذف" />,
      button: {
        visible: false,
        closeModal: false,
      },
    });
    const comment = this.props.comment;
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .delete(`${apiBaseUrl}/RecordedLectureComments/Replies/${id}`, {
        headers
      })
      .then(response => {
        let oldReplies = this.state.replies;
        const replyIndex = oldReplies.findIndex(r => r.id === id);
        oldReplies.splice(replyIndex, 1);

        // Check if the delete reply is the latest one
        if (comment.latestReply && comment.latestReply.id === id) {
          comment.latestReply = null;
        }

        this.setState({ replies: oldReplies });
        swal("تم حذف الرد بنجاح !", {
          icon: "success",
          button: "متابعة",
        });
      })
      .catch(error => {
        swal("عفواً", "لم يتم حذف الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }

  toggleFullReplies() {
    const showFullReplies = this.state.showFullReplies;

    if (!showFullReplies) {
      const commentId = this.props.comment.id;
      let token = localStorage.getItem("token");
      let headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${apiBaseUrl}/RecordedLectureComments/${commentId}/Replies`, {
          headers
        })
        .then(response => {
          this.setState({ replies: response.data.data, showFullReplies: true });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.setState({ showFullReplies: false });
    }
  }

  toggleRepliesForm() {
    this.setState({ showAddReplyForm: !this.state.showAddReplyForm });
  }

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
    this.setState({ [name]: value, fileError: "", soundFile: "", videoFile: "",});
  };

  handleEditCommentSubmit(event) {
    event.preventDefault();
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = {
      type: this.state.commentType,
      value: this.state.commentValue
    };
    axios
      .put(
        `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}`,
        data,
        {
          headers
        }
      )
      .then(response => {
        const comment = response.data.data;
        this.props.comment.value = comment.value;
        this.props.comment.type = comment.type;
        this.setState({
          showEditCommentForm: false,
          commentValue: comment.value,
          commentValue: comment.type
        });
        swal("تم تعديل التعليق بنجاح ", {
          button: "متابعة",
          icon: "success",
        });
      })
      .catch(error => {
        swal("عفواً", "لم يتم تعديل التعليق برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
          button: "متابعة",
        });
        console.log(error);
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };

    let data;
    if (this.state.file && this.commentInput.value !== "") {
      data = {
        type: "Mixed",
        value: this.state.reply,
        imageUrl: this.state.file,
      };
    } else if (this.state.file) {
      data = {
        type: "Image",
        imageUrl: this.state.file,
      };
    } else if (this.commentInput.value !== "") {
      data = {
        type: "Text",
        value: this.state.reply,
      };
    } else if(this.state.videoFile){
      data = {
        type: "Video",
        videoUrl: this.state.videoFile,
      };
    } else if(this.state.soundFile){
      data = {
        type: "Voice",
        voiceUrl: this.state.soundFile,
      };
    }
      // let data = {
      //   type: "Text",
      //   value: this.state.reply
      // };
      this.setState({ disabled: true, isSending: true, message: "جاري الإرسال" });
      axios
        .post(
          `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}/Replies`,
          data,
          {
            headers
          }
        )
        .then(response => {
          this.setState({
            showAddReplyForm: !this.state.showAddReplyForm
          });
          const reply = response.data.data;
          this.props.comment.latestReply = reply;
          this.setState(prevState => {
            return {
              replies: prevState.replies.concat(reply)
            };
          });
          this.setState({ disabled: false, file: "", videoFile: "", soundFile: "", isSending: false, message: "" });
          swal("تم اضافة الرد بنجاح ", {
            button: "متابعة",
            icon: "success",
          });
        })
        .catch(error => {
          this.setState({ disabled: false, isSending: false, message: "" });
          swal("عفواً", "لم يتم إضافة الرد برجاء إعادة المحاولة أو التواصل مع خدمة العملاء", "error", {
            button: "متابعة",
          }); 
          console.log(error);
        });
  }

  renderReplies() {
    const replies = this.getReplies();

    return replies.map(reply => {
      return (
        <Reply
          reply={reply}
          user={this.props.user}
          setPlayedId={this.props.setPlayedId}
          playedId={this.props.playedId}
          onDelete={() => this.confirmDeleteReply(reply.id)}
        />
      );
    });
  }

  toggleCorrect() {
    let token = localStorage.getItem("token");
    let headers = {
      Authorization: `Bearer ${token}`
    };
    let data = {
      correct: !this.state.commentCorrect
    };
    axios
      .put(
        `${apiBaseUrl}/RecordedLectureComments/${this.props.comment.id}/Correct`,
        data,
        {
          headers
        }
      )
      .then(response => {
        const comment = response.data.data;
        this.props.comment.correct = comment.correct;
        this.setState({
          commentCorrect: comment.correct
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { isRecording, isPaused } = this.state;
    const comment = this.props.comment;
    let myIdentity = this.props.user && this.props.user.id;

    // Comment date
    const createdAt = new Date(comment.createdAt);
    let day = createdAt.getDate();
    let month = createdAt.getMonth() + 1;
    let year = createdAt.getFullYear();
    // let fullDate = year + "-" + month + "-" + day;
    let fullDate = year + "," + month + "," + day;
    let hijriDate = moment(createdAt).format("iYYYY/iM/iD");

    const commenterId = comment.user && comment.user.id;

    return (
      <React.Fragment>
        <div className="box-layout shadow-sm bg-white mt-3">
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/blue-user.png"}
                  width="25"
                  className="contain-img mr-2"
                  alt="userIcon"
                />
                <div>
                  <h6 className="light-text small mb-1 mr-3">
                    {comment.user && comment.user.name}
                  </h6>
                  <h6 className="dark-silver-text smaller en-text mb-0">
                    {hijriDate}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div className="clickable" onClick={() => this.toggleCorrect()}>
                  {comment.correct ? (
                    <React.Fragment>
                      <h6 className="light-text smaller mb-0 mr-3">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/check-mark.png"
                          }
                          width="12"
                          className="contain-img mr-1"
                          alt="checkMarkIcon"
                        />
                        إجابة معتمدة
                      </h6>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h6 className="silver-text smaller mb-0 mr-3">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/silver-check.png"
                          }
                          width="12"
                          className="contain-img mr-1"
                          alt="silverCheckIcon"
                        />
                        اعتمد الإجابة
                      </h6>
                    </React.Fragment>
                  )}
                </div>
                <div>
                  {myIdentity == commenterId && comment.type == "Text" && (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/edit.png"}
                      width="20"
                      className="contain-img mr-2 clickable"
                      alt="editIcon"
                      onClick={() => this.toggleEditCommentForm()}
                    />
                  )}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                    width="20"
                    className="contain-img clickable"
                    alt="removeIcon"
                    onClick={() => this.props.onDelete()}
                  />
                </div>
              </div>
            </div>

            {this.state.showEditCommentForm ? (
              <div style={{ minHeight: 140 }}>
                <form onSubmit={this.handleEditCommentSubmit}>
                  <textarea
                    type="text"
                    name="commentValue"
                    onChange={this.handleChange}
                    value={this.state.commentValue}
                    rows="4"
                    className="form-control small dark-text shadow-sm mb-3"
                  />
                  <button
                    type="submit"
                    className="btn light-outline-btn btn-sm unset-height pl-5 pr-5 float-right"
                  >
                    ارسال
                  </button>
                </form>
              </div>
            ) : (
              <React.Fragment>
                {comment.type === "Mixed" ? (
                  <div>
                    <p className="dark-text smaller mb-2">
                    {comment.value}
                    </p>
                    <img
                      src={comment.imageUrl}
                      height="200"
                      className="contain-img w-100 max-width-300px"
                      alt="CommentImage"
                    />
                  </div>
                ): comment.type == "Text" ? (
                  <p className="dark-text smaller mb-2">
                    {comment.value}
                  </p>
                ) : comment.type == "Voice" ? (
                  <ReactAudioPlayer 
                    onPlay={()=>this.handlePlay(comment.id)}  
                    ref={(element) => { this.rap = element }}
                    src={comment.voiceUrl? comment.voiceUrl:comment.value}
                    controls 
                    className="w-100" 
                    type="audio/mpeg"
                  />
                ) : comment.type == "Image" ? (
                  <img
                    src={comment.imageUrl?comment.imageUrl:comment.value}
                    height="200"
                    width="400"
                    className="contain-img w-100 max-width-300px"
                    alt="imageUrl"
                  />
                ) : (
                  // <video
                  //   height="200"
                  //   width="400"
                  //   className="video-container video-container-overlay w-100 max-width-300px"
                  //   autoPlay=""
                  //   controls
                  // >
                  //   <source
                  //     type="video/mp4"
                  //     data-reactid=".0.1.0.0.0"
                  //     src={comment.videoUrl?comment.videoUrl:comment.value}
                  //   />
                  // </video>
                  <div className='player-wrapper'>
                    <ReactPlayer 
                      className='react-player'
                      controls
                      playIcon	
                      width="100%"
                      height="100%"
                      url={comment.videoUrl?comment.videoUrl:comment.value} 
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            <div className="d-flex justify-content-end align-items-center">
              <h6
                className="badge dark-bg text-white smaller mb-0 mr-3 clickable"
                onClick={() => this.toggleFullReplies()}
              >
                <span className="en-text">{comment.replyCount}</span> ردود
              </h6>

              <h6
                className="light-text smaller d-flex align-items-center mb-0 clickable"
                onClick={() => this.toggleRepliesForm()}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/reply.png"}
                  width="15"
                  alt="replyIcon"
                  className="contain-img mr-2"
                />
                أضف رد على التعليق
              </h6>
            </div>
          </div>

          {this.renderReplies()}

          {this.state.showAddReplyForm && (
            <div className="silver-bg py-3 px-3">
              <form onSubmit={this.handleSubmit}>
                <div className="position-relative">
                  <textarea
                    id="commentInput"
                    type="text"
                    name="reply"
                    onChange={this.handleChange}
                    placeholder="اكتب رد على التعليق"
                    rows="6"
                    className="form-control small dark-text shadow-sm mb-3"
                    ref={ref => (this.commentInput = ref)}
                  />
                  <div className="textarea-icon d-flex align-items-center">
                    {isRecording ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/blue-mute.png"
                        }
                        alt="Record"
                        height="28"
                        className="contain-img"
                        disabled={!isRecording}
                        onClick={() => {
                          this.stopRecording(); 
                        }}
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/dark-mic.png"
                        }
                        alt="Record"
                        height="28"
                        className="contain-img"
                        onClick={() => {
                          this.startOrPauseRecording(); 
                        }}
                      />
                    )}
                    <ReactMic
                      record={isRecording}
                      pause={isPaused}
                      onStop={this.onStop}
                      mimeType="audio/mp3"
                    />
                    <label htmlFor="uploadReplyImage" className="mb-0 mr-1">
                      <input
                        className="d-none"
                        id="uploadReplyImage"
                        type="file"
                        accept="image/*"
                        onChange={this.handleFileChange}
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/picture.png"
                        }
                        height="28"
                        width="28"
                        alt="uploadReplyImage"
                        className="contain-img clickable"
                      />
                    </label>
                    <label htmlFor="uploadReplyVideo" className="mb-0">
                      <input
                        className="d-none"
                        id="uploadReplyVideo"
                        type="file"
                        accept="video/*"
                        onChange={this.handleVideoChange}
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/attach-video.png"
                        }
                        height="28"
                        width="28"
                        alt="attach-video"
                        className="contain-img clickable"
                      />
                    </label>
                  </div>
                </div>

                {this.state.file ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({file: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    <div className="">
                      <img
                        src={this.state.file}
                        className="contain-img clickable w-100"
                        height="130px"
                        alt="comment"
                        id="picture-icon"
                      />
                    </div>
                  </div>
                ): null}

                {this.state.videoFile ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({videoFile: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    <div className='player-wrapper'>
                      <ReactPlayer 
                        className='react-player'
                        controls
                        playIcon	
                        width="100%"
                        height="100%"
                        url={this.state.videoFile} 
                      />
                    </div>
                  </div>
                ): null}

                {this.state.soundFile ? (
                  <div className="dashed-border border-sub-color mb-2 p-2 position-relative">
                    <div className="top-left" onClick={()=>this.setState({soundFile: ""})}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/remove.png"}
                        width="25"
                        className="contain-img clickable"
                        alt="remove-icon"
                      />
                    </div>
                    <div className="">
                    <ReactAudioPlayer src={this.state.soundFile} controls className="w-100"
                    />
                    </div>
                  </div>
                ): null}

                
              {this.state.fileError ? (
              <>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/not-verified.png"
                  }
                  height="20"
                  width="20"
                  className="contain-img clickable mr-1"
                  alt="error"
                />
                <small className="ar-text">{this.state.fileError}</small>
              </>
              ) : null}
                
                <button
                  type="submit"
                  className="btn light-outline-btn btn-sm unset-height pl-5 pr-5 float-right"
                >
                  ارسال
                </button>
              </form>
              {/* {this.state.isSending ? (
                <ProgressBar
                  width="100%"
                  height="10px"
                  rect
                  percentage="100"
                  rectPadding="1px"
                  rectBorderRadius="20px"
                  trackPathColor="transparent"
                  trackBorderColor="#ced4da"
                />
              ) : (
                <form onSubmit={this.handleSubmit}>
                  <div className="position-relative">
                    <textarea
                      id="commentInput"
                      type="text"
                      name="reply"
                      onChange={this.handleChange}
                      placeholder="اكتب رد على التعليق"
                      rows="6"
                      className="form-control small dark-text shadow-sm mb-3"
                      ref={ref => (this.commentInput = ref)}
                    />
                    <div className="textarea-icon d-flex align-items-center">
                      {isRecording ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/blue-mute.png"
                          }
                          alt="Record"
                          height="28"
                          className="contain-img"
                          disabled={!isRecording}
                          onClick={this.stopRecording}
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/dark-mic.png"
                          }
                          alt="Record"
                          height="28"
                          className="contain-img"
                          onClick={this.startOrPauseRecording}
                        />
                      )}
                      <ReactMic
                        record={isRecording}
                        pause={isPaused}
                        onStop={this.onStop}
                        mimeType="audio/mp3"
                      />
                      <label htmlFor="uploadReplyImage" className="mb-0 mr-1">
                        <input
                          className="d-none"
                          id="uploadReplyImage"
                          type="file"
                          accept="image/*"
                          onChange={this.handleFileChange}
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/picture.png"
                          }
                          height="28"
                          width="28"
                          alt="uploadReplyImage"
                          className="contain-img clickable"
                        />
                      </label>
                      <label htmlFor="uploadReplyVideo" className="mb-0">
                        <input
                          className="d-none"
                          id="uploadReplyVideo"
                          type="file"
                          accept="video/*"
                          onChange={this.handleVideoChange}
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/attach-video.png"
                          }
                          height="28"
                          width="28"
                          alt="attach-video"
                          className="contain-img clickable"
                        />
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn light-outline-btn btn-sm unset-height pl-5 pr-5 float-right"
                  >
                    ارسال
                  </button>
                </form>
              )} */}
              <div className="clearfix" />
            </div>
          )}
        </div>

        <SendingModal isOpen={this.state.isSending} message={this.state.message} />

      </React.Fragment>
      
    );
  }
}
